<!--
  This View contains a Form, to let Users submit their own Challenges
  Submitted Challenges are disabled by default and need to be enabled by a Moderator or Admin
-->

<template>
  <div>
      <md-dialog-confirm
        :md-title="$t('global.error.title')"
        :md-active.sync="showErrorDialog"
        :md-content="lastError"
        :md-confirm-text="$t('global.error.confirm')"
        :md-cancel-text="$t('global.error.otherConfirm')"/>


      <NewCategory :showDialog="showDialog" @newCategory="newCategoryCreated" />
      <md-dialog :md-active.sync="showSuccessDialog">
        <md-dialog-title>{{ $t('submit.success.title') }}</md-dialog-title>

        <md-dialog-content>{{ $t('submit.success.description') }}</md-dialog-content>

        <md-dialog-actions>
          <md-button class="md-primary" @click="onConfirm">{{ $t('submit.success.button') }}</md-button>
        </md-dialog-actions>
      </md-dialog>

      <form novalidate class="md-layout">
        <md-card class="md-layout-item md-size-50 md-small-size-100 centering">
          <md-card-header>
            <div class="md-title">{{ $t('submit.title') }}</div>
          </md-card-header>

          <md-card-content>
            <md-field :class="getValidationClass('name')">
              <label for="name">{{ $t('submit.nameLabel') }}</label>
              <md-input
                type="text"
                name="name"
                id="name"
                v-model="form.name"
                :disabled="sending"
              />
              <span class="md-error" v-if="!$v.form.name.required">{{ $t('submit.nameRequired') }}</span>
            </md-field>

            <md-field>
              <label for="description">{{ $t('submit.descriptionLabel') }}</label>
              <md-input
                type="text"
                name="description"
                id="description"
                v-model="form.description"
                :disabled="sending"
              />
            </md-field>

            <md-chips
              v-model="form.tags"
              :md-placeholder="$t('submit.tagPlaceholder')"
            ></md-chips>

            <md-autocomplete
              :class="getValidationClass('category')"
              v-model="form.category"
              :md-options="categoryNames"
            >
              <label>{{ $t('submit.categoryLabel') }}</label>

              <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                <md-highlight-text :md-term="term">{{
                  item
                }}</md-highlight-text>
              </template>

              <template slot="md-autocomplete-empty" slot-scope="{ term }">
                {{ $t('submit.categoryAutocomplete', {term: term}) }}
                <a v-if="hasRole('mod')" @click="showDialog = true">{{ $t('submit.newCategory') }}</a>
              </template>
              <span class="md-error" v-if="!$v.form.category.required">{{ $t('submit.categoryRequired') }}</span>
            </md-autocomplete>
          </md-card-content>

          <md-progress-bar md-mode="indeterminate" v-if="sending" />

          <md-card-actions>
            <md-button
              class="md-primary"
              v-on:click="validateChallenge()"
              :disabled="sending"
              >{{ $t('submit.submitBtn') }}
            </md-button>
          </md-card-actions>
        </md-card>
      </form>
  </div>
</template>

<script>
// import configuration with API url; @ refers to the src directory
import config from "@/config";
// import library for HTTP requests
import axios from "axios";

import NewCategory from "@/dialogs/NewCategory.vue";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import ApiService from "../services/api.service";
import AuthService from "../services/auth.service";

export default {
  name: "NewChallenge",
  components: { NewCategory },
  mixins: [validationMixin],
  data() {
    return {
      categories: [],
      categoryNames: [],
      form: {
        name: "",
        description: "",
        tags: [],
        category: null,
      },
      sending: false,
      showDialog: false,
      showSuccessDialog: false,
      newChallengeId: -1,
      showErrorDialog: false,
      lastError: "",
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      category: {
        required,
      },
    },
  },
  methods: {
    /**
     * Returns true if the User is logged in
     */
    loggedIn: function() {
      return AuthService.isLoggedIn();
    },

    /**
     * Checks whether the logged in User has a role
     */
    hasRole: function (role) {
      return ApiService.getUserRoles().indexOf(role) != -1;
    },

    /**
     * Opens the Challenge Editor if the User wants to after creating a Challenge
     */
    onConfirm() {
      this.$router.push("/editor/" + this.newChallengeId);
    },

    /**
     * Validation for the Submit Form
     */
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },

    /**
     * Clears the Form
     */
    clearForm() {
      this.$v.form.$reset();
      this.form.name = "";
      this.form.description = "";
      this.form.tags = [];
      this.form.category = "";
    },

    /**
     * Validates User Inputs before sending them to the API
     */
    validateChallenge() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        console.log("submitting");
        this.createNewChallenge();
      }
    },

    /**
     * Sends the new Challenge Information to the API and open a Dialog on Error/Success
     */
    createNewChallenge: function () {
      this.sending = true;
      var tmp = Object.assign({}, this.form);
      var tags = [];
      this.form.tags.forEach((tag) => {
        tags.push({ name: tag });
      });
      tmp.tags = tags;
      tmp.category = {
        id: this.categories.find((cat) => cat.name === tmp.category).id,
      };

      console.log(tmp);

      axios.post(`${config.apiBaseUrl}/challenges`, tmp).then((response) => {
        console.log(response);
        this.sending = false;
        this.newChallengeId = response.data.id;
        this.clearForm();
        this.showSuccessDialog = true;
      }).catch((err) => {
        this.sending = false;

        if (err.response.status == 403) {
          this.lastError = "You don't have rights to create new Challenges" 
        } else {
          this.lastError = "Unknown Error";
        }

        this.showErrorDialog = true;
      });
    },

    /**
     * Listens to the creation of a new Category with the New Category Dialog
     */
    newCategoryCreated: function (value) {
      this.showDialog = false;

      if (value == undefined) return;

      this.categories.push(value);
      this.categoryNames.push(value.name);
      this.form.category = value.name;
    },

    /**
     * Retrieves all Categories from the API
     */
    getCategories: function () {
      axios.get(`${config.apiBaseUrl}/categories/all`).then((response) => {
        console.log(response.data);
        this.categories = response.data;
        this.categoryNames = [];
        this.categories.forEach((cat) => {
          this.categoryNames.push(cat.name);
        });
      });
    },

    /**
     * Listens for Authentication Changes
     */
    authCallback: function() {
      this.$forceUpdate();
    },
  },
  // executed after the component has been started
  created: function () {
    this.getCategories();
    AuthService.registerCallback(this.authCallback);
  },

  destroyed: function() {
    AuthService.unregisterCallback(this.authCallback); 
  },
};
</script>

<style lang="scss" scoped>
.challengeBox {
  padding: 10px;
  margin: 3px;
  border: 1px solid #42b983;
  float: left;
  min-width: 250px;
  text-align: center;
}

.challengeBox button {
  margin: 5px;
}

.centering{
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}


@media only screen and (max-width: 960px) {
  .md-layout-item.md-small-size-100 {
/*     min-width: 96% !important;
    max-width: 96% !important; */
  }
}
</style>
