<!--
  Admin Page for Categories and Tags
-->

<template>
  <div style="height: 100%">
    <NewCategory
      :showDialog="showCategoryDialog"
      @newCategory="getCategories"
    />

    <md-toolbar
      id="filter-toolbar"
      class="md-primary"
      md-elevation="1"
      @keydown.enter="applyFilter"
    >

      <!--Filter for Name-->
      <md-button
        class="md-icon-button"
        @click="menuClicked"
        v-responsive="['hidden-all', 'xs', 'sm', 'md']"
        id="adminmenuS"
      >
        <md-icon>menu</md-icon>
      </md-button>
      <md-autocomplete
        id="autoName"
        class="filter-field"
        style="max-width: 500px"
        v-model="filterText"
        :md-options="cattagNames()"
        md-layout="box"
        :md-fuzzy-search="false"
        :md-open-on-focus="false"
        data-lpignore
        @input="filter"
      >
        <label>{{ $t('admin.categories.search') }}</label>

        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
          <md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
        </template>

        <template slot="md-autocomplete-empty" slot-scope="{ term }">
          {{ $t('admin.categories.searchAutocomplete', {term: term}) }}
        </template>
      </md-autocomplete>
      <div class="md-toolbar-section-end">
        <md-button v-responsive="['hidden-all', 'lg', 'xl']" class="admintoolbutton" @click="showCategoryDialog = true"
          >{{ $t('admin.categories.newCategoryBtn') }}</md-button
        >
        <md-button v-responsive="['hidden-all', 'xs', 'sm', 'md']" @click="showCategoryDialog = true" class="md-icon-button">
          <md-icon>create_new_folder</md-icon>
        </md-button>
      </div>
    </md-toolbar>

    <div id="page-cattag-wrapper">
      <md-table
        id="table-categories"
        v-model="categoriesSearched"
        md-sort="id"
        md-sort-order="asc"
        md-fixed-header
      >
        <md-table-toolbar>
          <div class="md-toolbar-section-start">
            <h1 class="md-title">{{ $t('admin.categories.tableCategoriesTitle') }}</h1>
          </div>
        </md-table-toolbar>

        <md-table-empty-state
          :md-label="$t('admin.categories.emptyCategory.label')"
          :md-description="$t(!filterText == ''? 'admin.categories.emptyCategory.description' : 'admin.categories.emptyCategory.description2')">
          <md-button v-if="!filterText == ''" class="md-primary md-raised" @click="clearFilter()">
            {{ $t('admin.categories.emptyCategory.clearBtn') }}
          </md-button>

          <md-button v-if="filterText == ''" class="md-primary md-raised" @click="showCategoryDialog = true">
            {{ $t('admin.categories.emptyCategory.createBtn') }}
          </md-button>
        </md-table-empty-state>

        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell :md-label="$t('admin.table.id')" md-sort-by="id" md-numeric>{{
            item.id
          }}</md-table-cell>
          <md-table-cell :md-label="$t('admin.table.name')" md-sort-by="name">
            <md-highlight-text :md-fuzzy-search="false" :md-term="filterText">{{
              item.name
            }}</md-highlight-text>
          </md-table-cell>
          <md-table-cell :md-label="$t('admin.table.description')" md-sort-by="description">
            <md-highlight-text :md-fuzzy-search="false" :md-term="filterText">{{
              item.description
            }}</md-highlight-text>
          </md-table-cell>
          <md-table-cell :md-label="$t('global.edit')">
            <md-button
              class="md-icon-button md-accent"
              @click="editCategory(item.id)"
            >
              <md-icon>edit</md-icon>
            </md-button>
          </md-table-cell>

          <md-table-cell :md-label="$t('global.delete')">
            <md-button
              class="md-icon-button md-accent"
              @click="openCatDeleteDialog(item)"
            >
              <md-icon>delete</md-icon>
            </md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div class="vertical-divider"></div>

      <md-table
        id="table-tags"
        v-model="tagsSearched"
        md-sort="id"
        md-sort-order="asc"
        md-fixed-header
      >
        <md-table-toolbar>
          <div class="md-toolbar-section-start">
            <h1 class="md-title">{{ $t('admin.categories.tableTagsTitle') }}</h1>
          </div>
        </md-table-toolbar>

        <md-table-empty-state
          :md-label="$t('admin.categories.emptyTag.label')"
          :md-description="$t(!filterText == ''? 'admin.categories.emptyTag.description' : 'admin.categories.emptyTag.description2')">
          <md-button v-if="filterText != ''" class="md-primary md-raised" @click="clearFilter()">
            {{ $t('admin.categories.emptyTag.clearBtn') }}
          </md-button>
        </md-table-empty-state>

        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell :md-label="$t('admin.table.id')" md-sort-by="id" md-numeric>{{
            item.id
          }}</md-table-cell>
          <md-table-cell :md-label="$t('admin.table.name')" md-sort-by="name">
            <md-highlight-text :md-fuzzy-search="false" :md-term="filterText">{{
              item.name
            }}</md-highlight-text>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>

    <!-- Edit Category Dialog -->
    <md-dialog id="category-dialog" :md-active.sync="showEditCatDialog">
      <md-dialog-title> {{ $t('admin.categories.editCategory.title', {name: form.name}) }}</md-dialog-title>

      <form novalidate class="md-layout" @keydown.enter="validateEditCat" style="padding: 16px">
        <md-field :class="getValidationClass('name')">
          <label for="name">{{ $t('admin.categories.editCategory.nameLabel') }}</label>
          <md-input type="text" name="name" id="name" v-model="form.name" :disabled="sending" />
          <span class="md-error" v-if="!$v.form.name.required">{{ $t('admin.categories.editCategory.nameRequired') }}</span>
        </md-field>

        <md-field>
          <label for="description">{{ $t('admin.categories.editCategory.descriptionLabel') }}</label>
          <md-input type="text" name="description" id="description" v-model="form.description" :disabled="sending" />
        </md-field>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />
      </form>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showEditCatDialog = false">{{ $t('global.cancel') }}</md-button>
        <md-button type="submit" class="md-primary" @click="validateEditCat" :disabled="sending">{{ $t('global.save') }}</md-button>
      </md-dialog-actions>
    </md-dialog>

    <!-- Delete Category Dialog -->
    <md-dialog :md-active.sync="showDeleteCatDialog">
      <md-dialog-title>
        {{ $t('global.deleteDialog.title', {type: "Category: " + form.name}) }}
      </md-dialog-title>

      <md-dialog-actions>
        <md-button class="md-primary md-accent" @click="deleteCat()">{{ $t('global.delete') }}</md-button>
        <md-button
          class="md-primary"
          @click="
            showDeleteCatDialog = false;
            form.name = '';
            form.id = -1;
          ">
          {{ $t('global.cancel') }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
// import configuration with API url; @ refers to the src directory
import config from "@/config";
// import library for HTTP requests
import axios from "axios";

import NewCategory from "@/dialogs/NewCategory.vue";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AdminUsers",
  components: { NewCategory },
  mixins: [validationMixin],
  data() {
    return {
      filterText: "",
      showCategoryDialog: false,

      // Category Data
      categories: [],
      categoriesSearched: [],
      categoriesFilter: "",

      //Category Edit
      showEditCatDialog: false,
      showDeleteCatDialog: false,
      sending: false,
      form: {
        id: -1,
        name: "",
        description: ""
      },

      // Tag Data
      tags: [],
      tagsSearched: [],
      tagsFilter: "",
    };
  },
  props: [],
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  methods: {
    /**
     * Validation for Input Fields
     */
    getValidationClass (fieldName) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    /**
     * Filters Categories and Tags
     */
    filter: function () {
      this.filterCategories();
      this.filterTags();
    },
    /**
     * Returns an Array of all Category and Tags
     * Used to Auto Fill the Name Search Box
     */
    cattagNames: function () {
      var n = [];
      if (Array.isArray(this.categories))
        this.categories.forEach((ch) => {
          n.push(ch.name);
        });

      if (Array.isArray(this.tags))
        this.tags.forEach((ch) => {
          n.push(ch.name);
        });
      return n;
    },
    /**
     * Retrieves Categories from API
     */
    getCategories: function () {
      this.showCategoryDialog = false;
      axios.get(`${config.apiBaseUrl}/categories`).then((response) => {
        console.log(response.data);
        this.categories = response.data;
        this.filterCategories();
      });
    },

    /**
     * Filters Categories
     */
    filterCategories: function () {
      if (this.filterText == "") {
        this.categoriesSearched = this.categories;
      } else {
        this.categoriesSearched = this.categories.filter(
          (c) =>
            c.name.indexOf(this.filterText) != -1 ||
            c.description.indexOf(this.filterText) != -1
        );
      }
    },

    /**
     * Opens Edit Category Dialog for the given Category
     */
    editCategory: function(itemid) {
      var editCat = this.categories.find(c => c.id === itemid);

      this.form.id = editCat.id;
      this.form.name = editCat.name;
      this.form.description = editCat.description;

      console.log(this.form);
      this.showEditCatDialog = true;
    },

    /**
     * Opens Delete Confirmation Dialog for the given Category
     */
    openCatDeleteDialog: function(item) {
      this.form.id = item.id;
      this.form.name = item.name;

      this.showDeleteCatDialog = true;
    },

    /**
     * Deletes the given Category
     */
    deleteCat: function() {
      axios.delete(`${config.apiBaseUrl}/categories/` + this.form.id).then((response) => {
        console.log(response);

        this.$v.form.$reset()
        this.form.id = ""
        this.form.name = ""
        this.form.description = ""

        this.showDeleteCatDialog = false;

        this.getCategories();
      });
    },
    /**
     * Validates the Inputs and then continues sending the Request to the API
     */
    validateEditCat() {
      this.$v.form.$touch()

      console.log(this.$v);

      if (!this.$v.form.$invalid) {
        console.log("submitting");
        this.saveCategory()
      }
    },
    /**
     * Sends the edit Category Request to the API
     */
    saveCategory: function() {
      this.sending = true;

      axios.put(`${config.apiBaseUrl}/categories/` + this.form.id, this.form).then((response) => {
        console.log(response);
        this.sending = false;

        this.$v.form.$reset()
        this.form.id = ""
        this.form.name = ""
        this.form.description = ""

        this.showEditCatDialog = false;

        this.getCategories();
      });
    },

    /**
     * Retrieves Tags from the API
     */
    getTags: function () {
      axios.get(`${config.apiBaseUrl}/tags`).then((response) => {
        console.log(response.data);
        this.tags = response.data;
        this.filterTags();
      });
    },

    /**
     * Filters Tags
     */
    filterTags: function () {
      if (this.filterText == "") {
        this.tagsSearched = this.tags;
      } else {
        this.tagsSearched = this.tags.filter(
          (t) => t.name.indexOf(this.filterText) != -1
        );
      }
    },

    /**
     * Gets called when the User clicks the Menu Icon
     */
    menuClicked: function () {
      this.$emit("menuclick");
    },
  },
  mounted: function () {
    this.getCategories();
    this.getTags();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .md-table {

  .md-table-content {
    height: fit-content !important;
    max-height: 100% !important;
  }
}


.table-categories {
  height: calc(100% - 64px);
}


.table-tags {
  height: calc(100% - 64px);
}

::v-deep .md-highlight-text-match {
  color: $md-theme-default-primary-on-background;
}

#page-cattag-wrapper {
  display: grid;
  grid-template-columns: 50% 1px calc(50% - 1px);
  grid-template-rows: auto;
  height: calc(100% - 64px);
}

.vertical-divider {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.3);
  /*    margin-left: 8px;
  margin-right: 8px; */
}

@media only screen and (max-width: 680px) {
  #page-cattag-wrapper {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 50% 1px calc(50% - 1px);
  }
}
</style>
