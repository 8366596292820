var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('md-dialog',{attrs:{"id":"itemimport-dialog","md-active":_vm.showDialog},on:{"update:mdActive":function($event){_vm.showDialog=$event},"update:md-active":function($event){_vm.showDialog=$event},"md-closed":_vm.closeDialog}},[_c('md-toolbar',{staticClass:"md-primary",attrs:{"id":"filter-toolbar","md-elevation":"1"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.applyFilter($event)}}},[_c('h3',{staticClass:"md-title",staticStyle:{"flex":"1"},attrs:{"id":"ch-title"}},[_vm._v(_vm._s(_vm.$t('importItemDialog.title')))]),_c('md-autocomplete',{staticClass:"filter-field",attrs:{"id":"autoName","md-options":_vm.itemNames,"md-layout":"box","md-fuzzy-search":false,"data-lpignore":""},on:{"md-changed":_vm.filterItems},scopedSlots:_vm._u([{key:"md-autocomplete-item",fn:function(ref){
var item = ref.item;
var term = ref.term;
return [_c('md-highlight-text',{attrs:{"md-term":term}},[_vm._v(_vm._s(item))])]}},{key:"md-autocomplete-empty",fn:function(ref){
var term = ref.term;
return [_vm._v(" "+_vm._s(_vm.$t('importItemDialog.textAutocomplete', {term: term}))+" ")]}}]),model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}},[_c('label',[_vm._v(_vm._s(_vm.$t('importItemDialog.textLabel')))])]),_c('md-autocomplete',{staticClass:"filter-field",attrs:{"id":"autoTag","md-options":_vm.tagNames,"md-layout":"box","md-fuzzy-search":false,"data-lpignore":"","md-open-on-focus":false},on:{"md-changed":_vm.filterItems},scopedSlots:_vm._u([{key:"md-autocomplete-item",fn:function(ref){
var item = ref.item;
var term = ref.term;
return [_c('md-highlight-text',{attrs:{"md-term":term}},[_vm._v(_vm._s(item))])]}},{key:"md-autocomplete-empty",fn:function(ref){
var term = ref.term;
return [_vm._v(" "+_vm._s(_vm.$t('importItemDialog.tagsAutocomplete', {term: term}))+" ")]}}]),model:{value:(_vm.filter.tags),callback:function ($$v) {_vm.$set(_vm.filter, "tags", $$v)},expression:"filter.tags"}},[_c('label',[_vm._v(_vm._s(_vm.$t('importItemDialog.tags')))])])],1),_c('div',{attrs:{"id":"item-wrapper"}},[(_vm.itemsFiltered != null && _vm.itemsFiltered.length == 0)?_c('md-empty-state',{attrs:{"md-icon":"list_alt","md-label":_vm.$t('importItemDialog.empty.title'),"md-description":_vm.$t('importItemDialog.empty.description')}},[_c('md-button',{staticClass:"md-primary md-raised",on:{"click":function($event){return _vm.clearSearch()}}},[_vm._v(_vm._s(_vm.$t('importItemDialog.empty.clearBtn')))])],1):_vm._e(),_vm._l((_vm.itemsFiltered),function(item){return _c('md-card',{key:item.id,staticClass:"md-card ",class:(_vm.itemsSelected.indexOf(item.id) != -1? 'selected' : '') + ' ' + (_vm.itemsExisting.findIndex(function (i) { return i.item.id === item.id; }) != -1? 'notSelectable' : 'md-with-hover'),nativeOn:{"click":function($event){return _vm.selectItem(item.id)}}},[_c('md-card-header',[_c('div',{staticClass:"md-title"},[_c('vue-simple-markdown',{staticStyle:{"font-size":"inherit"},attrs:{"source":item.question}})],1),_c('div',{staticClass:"md-subhead"},[_c('md-icon',[_vm._v(_vm._s(item != undefined? _vm.typeToIcon(_vm.stringToType(item.type)) : ""))]),_vm._v(" "+_vm._s(_vm.$t(_vm.typeToLangKey(_vm.stringToType(item.type))))+" ")],1)]),_c('md-card-content',[_c('md-highlight-text',{attrs:{"md-fuzzy-search":false,"md-term":_vm.filter.name}},[_vm._v(" "+_vm._s(item.question)+" ")]),_c('div',{staticStyle:{"margin-top":"16px"}},_vm._l((item.tags),function(tag){return _c('md-chip',{key:tag.id,class:_vm.filter.tags == tag.name ? 'md-primary' : '',staticStyle:{"z-index":"9999"},on:{"click":function($event){$event.stopPropagation();_vm.filter.tags = tag.name;
                  _vm.filterItems();}}},[_vm._v(" "+_vm._s(_vm._f("maxlength")(tag.name,10))+" ")])}),1)],1)],1)})],2),_c('md-dialog-actions',[_c('md-button',{staticClass:"md-primary",on:{"click":_vm.cancelDialog}},[_vm._v(_vm._s(_vm.$t('global.cancel')))]),_c('md-button',{staticClass:"md-primary",on:{"click":_vm.closeDialog}},[_vm._v(_vm._s(_vm.$t('importItemDialog.importBtn'))+" "+_vm._s((_vm.itemsSelected.length == 0)? "" : "(" + _vm.itemsSelected.length + ")"))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }