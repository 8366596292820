<!--
  Admin Page for Items
-->

<template>
  <div style="height: 100%">
    <EditItem ref="editDialog" @doneEditing="doneEditing" />

    <!--Filter Toolbar-->
    <md-toolbar
      id="filter-toolbar-challenges"
      class="md-primary"
      md-elevation="1"
      @keydown.enter="applyFilter"
    >
      <md-button
        class="md-icon-button"
        @click="menuClicked"
        v-responsive="['hidden-all', 'xs', 'sm', 'md']"
        id="adminmenuS"
      >
        <md-icon>menu</md-icon>
      </md-button>
      <!--Filter for Name-->
      <md-autocomplete
        id="autoName"
        class="filter-field"
        v-model="filter.name"
        :md-options="itemNames()"
        md-layout="box"
        :md-fuzzy-search="false"
        data-lpignore
      >
        <label>{{ $t('admin.items.question') }}</label>

        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
          <md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
        </template>

        <template slot="md-autocomplete-empty" slot-scope="{ term }">
          {{ $t('admin.items.questionAutocomplete', {term: term}) }}
        </template>
      </md-autocomplete>

      <!--Filter for Tags-->
      <md-autocomplete
        id="autoTag"
        class="filter-field"
        v-model="filter.tags"
        :md-options="tagNames"
        md-layout="box"
        :md-fuzzy-search="false"
      >
        <label>{{ $t('admin.items.tags') }}</label>

        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
          <md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
        </template>

        <template slot="md-autocomplete-empty" slot-scope="{ term }">
          {{ $t('admin.items.tagsAutocomplete', {term: term}) }}
        </template>
      </md-autocomplete>

      <!--Filter Button-->
      <md-button
        id="btnFilter"
        class="md-raised"
        @click="applyFilter()"
        :disabled="this.filter.name == '' && this.filter.tags == ''"
        >{{ $t('global.filter') }}</md-button
      >
      <md-button class="md-raised" :disabled="!filtered" @click="clearFilter()" style="margin-right: 0"
        >{{ $t('global.clear') }}</md-button
      >

      <div class="md-toolbar-section-end">
        <md-button v-responsive="['hidden-all', 'lg', 'xl']" @click="newItem()"
          >{{ $t('admin.items.newItemBtn') }}</md-button
        >
        <md-button v-responsive="['hidden-all', 'xs', 'sm', 'md']" @click="newItem()" class="md-icon-button" style="margin-right: 0; min-width: 60px">
          <md-icon>add</md-icon>
        </md-button>

        <md-button class="md-icon-button" @click="getAllItems()" style="min-width: 60px">
          <md-icon>refresh</md-icon>
        </md-button>
      </div>
    </md-toolbar>

    <md-table
      class="itemTable"
      v-model="items"
      md-sort="id"
      md-sort-order="asc"
      md-fixed-header
    >
      <md-table-empty-state
        :md-label="$t('admin.items.empty.label')"
        :md-description="$t(!filtered? 'admin.items.empty.description2' : 'admin.items.empty.description')">
        <md-button v-if="filtered" class="md-primary md-raised" @click="clearFilter()">
          {{ $t('admin.items.empty.clearBtn') }}
        </md-button>

        <md-button v-if="!filtered" class="md-primary md-raised" @click="newItem()">
          {{ $t('admin.items.empty.createBtn') }}
        </md-button>
      </md-table-empty-state>

      <md-table-row
        class="itemTable"
        slot="md-table-row"
        slot-scope="{ item }"
        md-auto-select
      >
        <md-table-cell :md-label="$t('admin.table.id')" md-sort-by="id" md-numeric>
          {{ item.id }}
        </md-table-cell>

        <md-table-cell :md-label="$t('admin.table.type')" md-sort-by="type" md-numeric>
          <md-icon>{{item != undefined? typeToIcon(stringToType(item.type)) : ""}}</md-icon> {{$t(typeToLangKey(stringToType(item.type)))}}
        </md-table-cell>

        <md-table-cell :md-label="$t('admin.table.question')" md-sort-by="question" md-numeric>
          <md-highlight-text :md-fuzzy-search="false" :md-term="filter.name">
            {{ item.question }}
          </md-highlight-text>
        </md-table-cell>

        <md-table-cell :md-label="$t('admin.table.options')" md-sort-by="options" md-numeric>
          {{ "[" + item.options.map((o) => (o.type == "Image")? "Image" : o.data).join(", ") + "]" }}
        </md-table-cell>

        <md-table-cell :md-label="$t('admin.table.tags')" md-sort-by="tags" md-numeric>
          <md-highlight-text :md-fuzzy-search="false" :md-term="filter.tags">
             {{ item.tags.map(t => t.name).join(", ") }}
          </md-highlight-text>
        </md-table-cell>

        <md-table-cell :md-label="$t('global.edit')">
          <md-button class="md-icon-button md-accent" @click="editItem(item)">
            <md-icon>edit</md-icon>
          </md-button>
        </md-table-cell>

        <md-table-cell :md-label="$t('global.delete')">
          <md-button
            class="md-icon-button md-accent"
            @click="openDeleteDialog(item)"
          >
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-toolbar id="browse-pageination" md-elevation="1">
      <div class="md-toolbar-section-end">
        <md-field>
          <md-select
            v-model="pageSize"
            name="pageSize"
            id="browse-pagination-pageSize"
            @md-selected="switchTablePage(currentPage)"
          >
            <md-option value="5">5</md-option>
            <md-option value="10">10</md-option>
            <md-option value="25">25</md-option>
            <md-option value="50">50</md-option>
          </md-select>
        </md-field>
        <span>{{ currentPage + 1 + " of " + totalPages }}</span>
        <md-button
          class="md-icon-button"
          :disabled="currentPage == 0"
          @click="switchTablePage(currentPage - 1)"
        >
          <md-icon>navigate_before</md-icon>
        </md-button>
        <md-button
          class="md-icon-button"
          :disabled="currentPage + 1 >= totalPages"
          @click="switchTablePage(currentPage + 1)"
        >
          <md-icon>navigate_next</md-icon>
        </md-button>
      </div>
    </md-toolbar>

    <!-- Item delete dialog -->
    <md-dialog :md-active.sync="showDialogDeleteItem">
      <md-dialog-title>
        {{ $t('global.deleteDialog.title', {type: "Item " + (deletedItem != undefined ? deletedItem.question : "")}) }}
      </md-dialog-title>

      <md-dialog-actions>
        <md-button class="md-primary md-accent" @click="deleteItem()"
          >{{ $t('global.delete') }}</md-button
        >
        <md-button
          class="md-primary"
          @click="
            showDialogDeleteItem = false;
            deletedItem = undefined;
          "
          >{{ $t('global.cancel') }}</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
// import configuration with API url; @ refers to the src directory
import config from "@/config";
// import library for HTTP requests
import axios from "axios";

import EditItem from '@/dialogs/EditItem.vue'
import {alltypes, stringToType, typeToLangKey, typeToIcon} from "../models/itemtype";

export default {
  name: "AdminItems",
  components: {EditItem},
  data() {
    return {
      tags: [],
      tagNames: [],

      items: [],

      filtered: false,
      filter: {
        question: "",
        tags: "",
      },

      deletedItem: undefined,
      showDialogDeleteItem: false,

      totalPages: 0,
      currentPage: 0,
      pageSize: 10,
    };
  },
  props: [],
  methods: {
    /**
     * Methods from Item Type
     */
    stringToType,
    typeToLangKey,
    typeToIcon,
    /**
     * Switches the Page and retrieves new Results
     */
    switchTablePage: function (selectedPage) {
      console.log(selectedPage);
      this.currentPage = selectedPage;
      this.getAllItems();
    },
    /**
     * Applies the Filters set by the User and gets the filtered Results from the API
     */
    applyFilter: function () {
      console.log(this.filter);
      this.filtered = true;
      this.currentPage = 0;

      this.getAllItems();
    },
    /**
     * Clears the Filters and retrieves Results from the API
     */
    clearFilter: function () {
      this.items = [];
      this.filtered = false;
      this.currentPage = 0;

      this.getAllItems();
    },
    /**
     * Returns an Array of all Challenge Names in the current Results
     * Used to Auto Fill the Name Search Box
     */
    itemNames: function () {
      var n = [];
      if (Array.isArray(this.items))
        this.items.forEach((ch) => {
          n.push(ch.question);
        });
      return n;
    },

    /**
     * Retrieves Challenges from the API according to Filters and Pagination
     */
    getAllItems: function () {
      if (!this.filtered) {
        axios
          .get(`${config.apiBaseUrl}/items/filter`, {
            params: {
              page: this.currentPage,
              size: this.pageSize,
            },
          })
          .then((response) => {
            console.log(response.data);
            this.items = response.data.content;
            this.totalPages = response.data.totalPages;
          });
      } else {
        var tagId = "";
        if (this.filter.tags != "")
          tagId = this.tags.find((t) => t.name === this.filter.tags).id;

        axios
          .get(`${config.apiBaseUrl}/items/filter`, {
            params: {
              page: this.currentPage,
              size: this.pageSize,
              fn: this.filter.name,
              ft: tagId,
            },
          })
          .then((response) => {
            console.log(response.data);
            this.items = response.data.content;
            this.totalPages = response.data.totalPages;
          });
      }
    },
    /**
     * Opens the Item Editor for the given item, if the User can edit it
     */
    editItem: function (item) {
      this.$refs.editDialog.openDialog(Object.assign({}, item));
    },
    /**
     * Gets called when the Item Editor closes
     */
    doneEditing: function(item) {
      console.log(item);
      if (item == undefined) {
        this.getAllItems();
      } else {
        axios.post(`${config.apiBaseUrl}/items`, item)
          .then(() => {
            this.getAllItems();
          });
      }
    },
    /**
     * Opens the Delete Confirmation Dialog for the given Item
     */
    openDeleteDialog: function (item) {
      this.deletedItem = item;
      this.showDialogDeleteItem = true;
    },
    /**
     * Deletes the given Item
     */
    deleteItem: function () {
      axios
        .delete(`${config.apiBaseUrl}/items/` + this.deletedItem.id)
        .then(() => {
          this.deletedItem = undefined;
          this.showDialogDeleteItem = false;
          this.getAllItems();
        }).catch((err) => {
          this.deletedItem = undefined;
          this.showDialogDeleteItem = false;

          console.log(err);

          var status = err.status;
          var message = err.message;

          if (status == 404) {
            alert("This Item does not exist");
          } else if (status == 403) {
            alert(message);
          } else if (status == 417) {
            alert("This Item is still being used by a Challenge!");
          }
        });
    },
    /**
     * Opens the Item Editor for a new Item
     */
    newItem: function () {
      var newId = Math.floor(Math.random() * -1000000);

      var item = {id: newId, type: "New", question: "", questionImageUrl: "", tags: [], options: [], moreInformation: []};

      this.$refs.editDialog.openDialog(Object.assign({}, item));
    },
    /**
     * Retrieves all Tags from the API
     * Used to filter for Tags
     */
    getTags: function () {
      axios.get(`${config.apiBaseUrl}/tags`).then((response) => {
        console.log(response.data);
        this.tags = response.data;
        this.tagNames = [];
        this.tags.forEach((tag) => {
          this.tagNames.push(tag.name);
        });
      });
    },
    /**
     * Gets called when the User clicks on the Menu button
     */
    menuClicked: function () {
      this.$emit("menuclick");
    },
  },
  mounted: function () {
    this.getAllItems();
    this.getTags();
  },
};
</script>

<style lang="scss" scoped>

::v-deep .md-highlight-text-match {
  color: $md-theme-default-primary-on-background;
}

.itemTable {
  height: calc(100% - 128px);
}

.filter-field {
  width: 20% !important;
  max-width: 20% !important;
  margin-right: 16px !important;
}

::v-deep .md-table {

  .md-table-content {
    height: fit-content !important;
    max-height: 100% !important;
  }
}

#browse-pageination {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 36px;

  z-index: 9;

  box-shadow: 0 -2px 1px -1px rgba(0, 0, 0, 0.2),
    0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 -1px 3px 0 rgba(0, 0, 0, 0.12);

  button {
    margin-left: 16px;
  }

  .md-field {
    width: 100px;
    margin-right: 32px;
  }
}

.sortByLabel {
  margin: 12px;
  font-size: 16px;
}
.sortLabelText {
  font-size: 16px;
  background-color: lightgray;
  width: 80px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 680px) {
 .filter-field {
    width: calc(50% - 39px) !important;
    max-width: calc(50% - 39px) !important;
    margin-right: 0 !important;
    margin-top: 8px !important;
  }

  .md-button {
    margin-left: 0;
    min-width: 70px;
  }

  .sortByLabel {
    margin-left: 0;
  }

  #adminmenuS {
    margin-right: 0;
    margin-top: 8px;
  }

  #btnFilter {
    margin-left: 70px;
  }

  #autoName {
    margin-right: 8px !important;
  }
}
</style>
