/**
 * Authentication Service used to save/delete/get Username and Token from localStorage of the Client
 */

const USERNAME_KEY = 'username'
const TOKEN_KEY = 'token'

var token = null;
var username = null;

var authcallbacks = [];

const AuthService = {
    /**
     * Registeres a new Listener for Authentication changes
     * @param {function} listener 
     */
    registerCallback(cb) {
        authcallbacks.push(cb);
    },

    /**
     * Unregisteres a Listener from Authentication Changes
     * @param {function} listener
     */
    unregisterCallback(cb) {
        console.log("lol");
        authcallbacks.splice(authcallbacks.indexOf(cb), 1);
    },

    /**
     * Calls all registered Listeners
     */
    updateCB() {
        authcallbacks.forEach(cb => {
            if (cb && typeof cb === 'function') {
                cb();
            }
        });
    },

    /**
     * Returns if the Client has Login Information stored
     */
    isLoggedIn() {
        return token != null;
    },

    /**
     * Returns the Username of the logged in User
     */
    getUsername() {
        return username;
    },

    /**
     * Sets the Username of the logged in User
     * @param {String} username
     */
    setUsername(uname) {
        username = uname;
        authcallbacks.forEach(cb => {
            if (cb && typeof cb === 'function') {
                cb();
            }
        });
        return localStorage.setItem(USERNAME_KEY, uname);
    },

    /**
     * Removes the Username from Storage
     */
    delUsername() {
        username = null;
        authcallbacks.forEach(cb => {
            if (cb && typeof cb === 'function') {
                cb();
            }
        });
        return localStorage.removeItem(USERNAME_KEY);
    },

    /**
     * Returns the Token of the logged in User
     */
    getToken() {
        return token;
    },

    /**
     * Stores a new Token in Local Storage
     * @param {String} accessToken 
     */
    setToken(accessToken) {
        token = accessToken;
        authcallbacks.forEach(cb => {
            if (cb && typeof cb === 'function') {
                cb();
            }
        });
        localStorage.setItem(TOKEN_KEY, accessToken);
    },

    /**
     * Removes the stored Token from Storage
     */
    delToken() {
        token = null;
        authcallbacks.forEach(cb => {
            if (cb && typeof cb === 'function') {
                cb();
            }
        });
        localStorage.removeItem(TOKEN_KEY);
    },

    /**
     * Reads Username and Token from Local Storage and stores it
     * Calls Event.
     */
    readData() {
        if ( window.location === window.parent.location ) {
            token = localStorage.getItem(TOKEN_KEY);
            username = localStorage.getItem(USERNAME_KEY);
            authcallbacks.forEach(cb => {
                if (cb && typeof cb === 'function') {
                    cb();
                }
            });
        }
    }
}

export default AuthService