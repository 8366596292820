<!--
  Dialog with Items to select one for Import
-->

<template>
  <md-dialog id="itemimport-dialog" :md-active.sync="showDialog" @md-closed="closeDialog">
    <md-toolbar
      id="filter-toolbar"
      class="md-primary"
      md-elevation="1"
      @keydown.enter="applyFilter"
    >
      <h3 id="ch-title" class="md-title" style="flex: 1">{{ $t('importItemDialog.title') }}</h3>

      <!--Filter for Name-->
      <md-autocomplete
        id="autoName"
        class="filter-field"
        v-model="filter.name"
        :md-options="itemNames"
        md-layout="box"
        :md-fuzzy-search="false"
        data-lpignore
        @md-changed="filterItems"
      >
        <label>{{ $t('importItemDialog.textLabel') }}</label>

        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
          <md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
        </template>

        <template slot="md-autocomplete-empty" slot-scope="{ term }">
          {{ $t('importItemDialog.textAutocomplete', {term: term}) }}
        </template>
      </md-autocomplete>

      <!--Filter for Tags-->
      <md-autocomplete
        id="autoTag"
        class="filter-field"
        v-model="filter.tags"
        :md-options="tagNames"
        md-layout="box"
        :md-fuzzy-search="false"
        data-lpignore
        @md-changed="filterItems"
        :md-open-on-focus="false"
      >
        <label>{{ $t('importItemDialog.tags') }}</label>

        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
          <md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
        </template>

        <template slot="md-autocomplete-empty" slot-scope="{ term }">
          {{ $t('importItemDialog.tagsAutocomplete', {term: term}) }}
        </template>
      </md-autocomplete>
    </md-toolbar>

    <div id="item-wrapper">
      <md-empty-state
        v-if="itemsFiltered != null && itemsFiltered.length == 0"
        md-icon="list_alt"
        :md-label="$t('importItemDialog.empty.title')"
        :md-description="$t('importItemDialog.empty.description')">

        <md-button class="md-primary md-raised" @click="clearSearch()">{{ $t('importItemDialog.empty.clearBtn') }}</md-button>
      </md-empty-state>

      <!--Item Cards-->
      <md-card
        class="md-card "
        
        v-for="item in itemsFiltered"
        v-bind:key="item.id"
        v-on:click.native="selectItem(item.id)"
        :class="(itemsSelected.indexOf(item.id) != -1? 'selected' : '') + ' ' + (itemsExisting.findIndex(i => i.item.id === item.id) != -1? 'notSelectable' : 'md-with-hover')"
      >
        <md-card-header>
          <div class="md-title">
<!--             <md-highlight-text
              :md-fuzzy-search="false"
              :md-term="filter.name"> -->
              <vue-simple-markdown style="font-size: inherit;" :source="item.question"></vue-simple-markdown>
<!--               {{ item.question }}
            </md-highlight-text> -->
          </div>
          <div class="md-subhead">
            <md-icon>{{item != undefined? typeToIcon(stringToType(item.type)) : ""}}</md-icon> {{$t(typeToLangKey(stringToType(item.type)))}}
          </div>
        </md-card-header>

        <md-card-content>
          <md-highlight-text :md-fuzzy-search="false" :md-term="filter.name">
            {{item.question}}
          </md-highlight-text>
 
          <div style="margin-top: 16px">
            <md-chip
                v-for="tag in item.tags"
                v-bind:key="tag.id"
                :class="filter.tags == tag.name ? 'md-primary' : ''"
                @click.stop="
                  filter.tags = tag.name;
                  filterItems();
                "
                style="z-index: 9999">
                  {{ tag.name | maxlength(10)}}
            </md-chip>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <md-dialog-actions>
      <md-button class="md-primary" @click="cancelDialog">{{ $t('global.cancel') }}</md-button>
      <md-button class="md-primary" @click="closeDialog">{{ $t('importItemDialog.importBtn') }} {{(itemsSelected.length == 0)? "" : "(" + itemsSelected.length + ")"}}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
// import configuration with API url; @ refers to the src directory
import config from "@/config";
// import library for HTTP requests
import axios from "axios";

import {alltypes, stringToType, typeToLangKey, typeToIcon} from "../models/itemtype";

export default {
  name: "ImportItem",
  data() {
    return {
      filter: {
        name: "",
        tags: ""
      },
      sending: false,
      tags: [],
      tagNames: [],
      items: [],
      itemNames: [],
      itemsFiltered: [],
      itemsSelected: []
    };
  },
  props: ["showDialog", "itemsExisting"],
  methods: {
    /**
     * Methods from Item Type
     */
    stringToType,
    typeToLangKey,
    typeToIcon,
    /**
     * Clears the Form to be reused
     */
    clearSearch () {
      this.filter.name = "";
      this.filter.tags = "";
      this.filterItems();
    },
    /**
     * Filters Items
     */
    filterItems () {
      if (this.filter.name == "" && this.filter.tags == "") {
        this.itemsFiltered = this.items;
      } else {
        this.itemsFiltered = this.items.filter(
          (c) =>
            c.question.indexOf(this.filter.name) != -1 && (
              this.filter.tags == "" ||
              c.tags.map(t => t.name).indexOf(this.filter.tags) != -1)
        );
      }
    },
    /**
     * Marks an Item as selected or deselects it if it was already selected
     */
    selectItem: function(itemid) {
      if (this.itemsExisting.findIndex(i => i.item.id === itemid) != -1) return;

      if (this.itemsSelected.indexOf(itemid) != -1) {
        this.itemsSelected.splice(this.itemsSelected.findIndex(id => id === itemid), 1);
      } else {
        this.itemsSelected.push(itemid);
      }

      console.log(this.itemsSelected);
    },
    /**
     * Retrieves all Items from API
     */
    getAllItems: function() {
      axios.get(`${config.apiBaseUrl}/items`).then((response) => {
        console.log(response.data);
        this.items = response.data;
        this.itemNames = [];
        this.items.forEach((item) => {
          this.itemNames.push(item.question);
        });
        console.log(this.itemNames);
        this.filterItems();
      });
    },
    /**
     * Retrieves all Tags from the API
     * Used to filter for Tags
     */
    getTags: function () {
      axios.get(`${config.apiBaseUrl}/tags`).then((response) => {
        console.log(response.data);
        this.tags = response.data;
        this.tagNames = [];
        this.tags.forEach((tag) => {
          this.tagNames.push(tag.name);
        });
      });
    },
    /**
     * Closes the Dialog without returning any Items
     */
    cancelDialog: function() {
      this.clearSearch();
      this.$emit("itemImported", []);
      this.itemsSelected = [];
    },
    /**
     * Closes the Dialog
     */
    closeDialog: function() {
      this.clearSearch();

      var tmp = [];
      this.itemsSelected.forEach(i => {
        tmp.push(this.items.find(item => item.id == i));
      });

      this.$emit("itemImported", tmp);
      this.itemsSelected = [];
    }
  },
  mounted: function() {
    this.getAllItems();
  }
};
</script>

<style lang="scss">
.md-card .md-highlight-text-match {
  color: $md-theme-default-primary-on-background;
}

#itemimport-dialog {
  padding: 10px;
  min-width: 250px;

  .md-dialog-container {
    width: 80%;
  }
}

#item-wrapper {
  padding: 10px;
  overflow-y: scroll;
}

.filter-field {
  width: 40% !important;
  max-width: 40% !important;
  margin-right: 16px !important;
}

.md-card {
  min-width: 250px;
  max-width: 350px;
  margin: 8px;
  display: inline-block;
  vertical-align: top;
}

.selected {
  background-color: rgba($primary, 0.2) !important;
}

.notSelectable {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
</style>
