<!--
  Admin Page for Challenges
-->

<template>
  <div style="height: 100%">
    <!--Filter Toolbar-->
    <md-toolbar
      id="filter-toolbar-challenges"
      class="md-primary"
      md-elevation="1"
      @keydown.enter="applyFilter"
    >
      <md-button
        class="md-icon-button"
        @click="menuClicked"
        v-responsive="['hidden-all', 'xs', 'sm', 'md']"
        id="adminmenuS"
      >
        <md-icon>menu</md-icon>
      </md-button>
      <!--Filter for Name-->
      <md-autocomplete
        id="autoName"
        class="filter-field"
        v-model="filter.name"
        :md-options="challengeNames()"
        md-layout="box"
        :md-fuzzy-search="false"
        data-lpignore
      >
        <label>{{ $t('admin.challenges.text') }}</label>

        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
          <md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
        </template>

        <template slot="md-autocomplete-empty" slot-scope="{ term }">
          {{ $t('admin.challenges.textAutocomplete', {term: term}) }}
        </template>
      </md-autocomplete>

      <!--Filter for Categories-->
      <md-autocomplete
        id="autoCat"
        class="filter-field"
        v-model="filter.category"
        :md-options="categoryNames"
        md-layout="box"
        :md-fuzzy-search="false"
      >
        <label>{{ $t('admin.challenges.categories') }}</label>

        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
          <md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
        </template>

        <template slot="md-autocomplete-empty" slot-scope="{ term }">
          {{ $t('admin.challenges.categoriesAutocomplete', {term: term}) }}
        </template>
      </md-autocomplete>

      <!--Filter for Tags-->
      <md-autocomplete
        id="autoTag"
        class="filter-field"
        v-model="filter.tags"
        :md-options="tagNames"
        md-layout="box"
        :md-fuzzy-search="false"
      >
        <label>{{ $t('admin.challenges.tags') }}</label>

        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
          <md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
        </template>

        <template slot="md-autocomplete-empty" slot-scope="{ term }">
          {{ $t('admin.challenges.tagsAutocomplete', {term: term}) }}
        </template>
      </md-autocomplete>

      <!--Filter Button-->
      <md-button
        class="md-raised"
        @click="applyFilter()"
        :disabled="
          this.filter.name == '' &&
          this.filter.category == '' &&
          this.filter.tags == ''
        "
        >{{ $t('global.filter') }}</md-button
      >
      <md-button class="md-raised" :disabled="!filtered" @click="clearFilter()"
        >{{ $t('global.clear') }}</md-button
      >

      <div class="sortByLabel">
        <label>{{ $t('admin.challenges.sortBy.label') }}</label>
      </div>

      <md-menu :md-align-trigger="true">
        <md-content class="sortLabelText" md-menu-trigger>
          <md-icon v-if="filter.sort == ''">keyboard_arrow_down</md-icon>
          {{ filter.sort }}
        </md-content>

        <md-menu-content>
          <md-menu-item @click="clickEventSort('')" :md-ripple="false"
            >{{ $t('admin.challenges.sortBy.sortNone') }}</md-menu-item
          >
          <md-menu-item @click="clickEventSort('Name')" :md-ripple="false"
            >{{ $t('admin.challenges.sortBy.sortName') }}</md-menu-item
          >
          <md-menu-item @click="clickEventSort('Category')" :md-ripple="false"
            >{{ $t('admin.challenges.sortBy.sortCategory') }}</md-menu-item
          >
        </md-menu-content>
      </md-menu>

      <md-button v-responsive="['hidden-all', 'xs', 'sm', 'md']" style="min-width: 50px; margin-right: 0" class="md-icon-button" @click="getAllChallenges()">
        <md-icon>refresh</md-icon>
      </md-button>

      <div v-responsive="['hidden-all', 'lg', 'xl']" class="md-toolbar-section-end">
        <md-button class="md-icon-button" @click="getAllChallenges()">
          <md-icon>refresh</md-icon>
        </md-button>
      </div>
    </md-toolbar>

    <md-table
      class="challengeTable"
      v-model="challenges"
      md-sort="id"
      md-sort-order="asc"
      md-fixed-header
    >
      <md-table-empty-state
        :md-label="$t('admin.challenges.empty.label')"
        :md-description="$t(!filtered? 'admin.challenges.empty.description2' : 'admin.challenges.empty.description')">
        <md-button v-if="filtered" class="md-primary md-raised" @click="clearFilter()">
          {{ $t('admin.challenges.empty.clearBtn') }}
        </md-button>

        <md-button v-if="!filtered" class="md-primary md-raised" @click="newChallenge()">
          {{ $t('admin.challenges.empty.createBtn') }}
        </md-button>
      </md-table-empty-state>

      <md-table-row
        class="challengeTable"
        slot="md-table-row"
        slot-scope="{ item }"
        md-auto-select
      >
        <md-table-cell :md-label="$t('admin.table.id')" md-sort-by="id" md-numeric>
          {{ item.id }}
        </md-table-cell>

        <md-table-cell :md-label="$t('admin.table.name')" md-sort-by="name" md-numeric>
          <md-highlight-text :md-fuzzy-search="false" :md-term="filter.name">
            {{ item.name }}
          </md-highlight-text>
        </md-table-cell>

        <md-table-cell
          :md-label="$t('admin.table.description')"
          md-sort-by="description"
          md-numeric
        >
          <md-highlight-text :md-fuzzy-search="false" :md-term="filter.name">
            {{ item.description }}
          </md-highlight-text>
        </md-table-cell>

        <md-table-cell :md-label="$t('admin.table.category')" md-sort-by="category" md-numeric>
          <md-highlight-text
            :md-fuzzy-search="false"
            :md-term="filter.category"
          >
            {{ item.category.name }}
          </md-highlight-text>
        </md-table-cell>

        <md-table-cell :md-label="$t('admin.table.tags')" md-sort-by="tags" md-numeric>
          <md-highlight-text :md-fuzzy-search="false" :md-term="filter.tags">
            {{ item.tags.map((t) => t.name).join(", ") }}
          </md-highlight-text>
        </md-table-cell>

        <md-table-cell :md-label="$t('global.edit')">
          <md-button
            class="md-icon-button md-accent"
            @click="editChallenge(item)"
          >
            <md-icon>edit</md-icon>
          </md-button>
        </md-table-cell>

        <md-table-cell :md-label="$t('global.delete')">
          <md-button
            class="md-icon-button md-accent"
            @click="openDeleteDialog(item)"
          >
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-toolbar id="browse-pageination" md-elevation="1">
      <div class="md-toolbar-section-end">
        <md-field>
          <md-select
            v-model="pageSize"
            name="pageSize"
            id="browse-pagination-pageSize"
            @md-selected="switchTablePage(currentPage)"
          >
            <md-option value="5">5</md-option>
            <md-option value="10">10</md-option>
            <md-option value="25">25</md-option>
            <md-option value="50">50</md-option>
          </md-select>
        </md-field>
        <span>{{ currentPage + 1 + " of " + totalPages }}</span>
        <md-button
          class="md-icon-button"
          :disabled="currentPage == 0"
          @click="switchTablePage(currentPage - 1)"
        >
          <md-icon>navigate_before</md-icon>
        </md-button>
        <md-button
          class="md-icon-button"
          :disabled="currentPage + 1 >= totalPages"
          @click="switchTablePage(currentPage + 1)"
        >
          <md-icon>navigate_next</md-icon>
        </md-button>
      </div>
    </md-toolbar>

    <!-- Challenge delete dialog -->
    <md-dialog :md-active.sync="showDialogDeleteChallenge">
      <md-dialog-title>
        {{ $t('global.deleteDialog.title', {type: "Challenge " + (deletedChallenge != undefined ? deletedChallenge.name : "")}) }}
      </md-dialog-title>

      <md-dialog-actions>
        <md-button class="md-primary md-accent" @click="deleteChallenge()"
          >{{ $t('global.delete') }}</md-button
        >
        <md-button
          class="md-primary"
          @click="
            showDialogDeleteChallenge = false;
            deletedChallenge = undefined;
          "
          >{{ $t('global.cancel') }}</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
// import configuration with API url; @ refers to the src directory
import config from "@/config";
// import library for HTTP requests
import axios from "axios";

export default {
  name: "AdminChallenges",
  data() {
    return {
      categories: [],
      categoryNames: [],
      tags: [],
      tagNames: [],

      challenges: [],

      filtered: false,
      filter: {
        name: "",
        category: "",
        tags: "",
        sort: "",
      },

      deletedChallenge: undefined,
      showDialogDeleteChallenge: false,

      totalPages: 0,
      currentPage: 0,
      pageSize: 10,
    };
  },
  props: [],
  methods: {

    /**
     * Switches the Page and retrieves new Results
     */
    switchTablePage: function (selectedPage) {
      console.log(selectedPage);
      this.currentPage = selectedPage;
      this.getAllChallenges();
    },
    /**
     * Applies the Filters set by the User and gets the filtered Results from the API
     */
    applyFilter: function () {
      console.log(this.filter);
      this.filtered = true;
      this.currentPage = 0;

      this.getAllChallenges();
    },
    /**
     * Clears the Filters and retrieves Results from the API
     */
    clearFilter: function () {
      this.challenges = [];
      this.filtered = false;
      this.currentPage = 0;

      this.getAllChallenges();
    },
    /**
     * Starts retrieving new Challenges if the User Sorted for a Property
     */
    clickEventSort: function (string) {
      this.filter.sort = string;
      this.getAllChallenges();
    },
    /**
     * Returns an Array of all Challenge Names in the current Results
     * Used to Auto Fill the Name Search Box
     */
    challengeNames: function () {
      var n = [];
      if (Array.isArray(this.challenges))
        this.challenges.forEach((ch) => {
          n.push(ch.name);
        });
      return n;
    },
    /**
     * Retrieves Challenges from the API according to Filters and Pagination
     */
    getAllChallenges: function () {
      if (!this.filtered) {
        axios
          .get(`${config.apiBaseUrl}/challenges`, {
            params: {
              page: this.currentPage,
              size: this.pageSize,
              s: this.filter.sort,
            },
          })
          .then((response) => {
            console.log(response.data);
            this.challenges = response.data.content;
            this.totalPages = response.data.totalPages;
          });
      } else {
        var catId = "";
        if (this.filter.category != "") catId = this.categories.find((c) => c.name === this.filter.category).id;

        var tagId = "";
        if (this.filter.tags != "") tagId = this.tags.find((t) => t.name === this.filter.tags).id;

        axios
          .get(`${config.apiBaseUrl}/challenges`, {
            params: {
              page: this.currentPage,
              size: this.pageSize,
              s: this.filter.sort,
              fn: this.filter.name,
              fc: catId,
              ft: tagId,
            },
          })
          .then((response) => {
            console.log(response.data);
            this.challenges = response.data.content;
            this.totalPages = response.data.totalPages;
          });
      }
    },
    /**
     * Opens the Challenge Editor for the given Challenge
     */
    editChallenge: function (challenge) {
      this.$router.push({
        path: "/editor/" + challenge.id,
      });
    },
    /**
     * Opens the Delete Confirmation Dialog for the selected Challenge
     */
    openDeleteDialog: function (challenge) {
      this.deletedChallenge = challenge;
      this.showDialogDeleteChallenge = true;
    },
    /**
     * Deletes the given Challenge
     */
    deleteChallenge: function () {
      axios
        .delete(`${config.apiBaseUrl}/challenges/` + this.deletedChallenge.id)
        .then(() => {
          this.deletedChallenge = undefined;
          this.showDialogDeleteChallenge = false;
          this.getAllChallenges();
        });
    },
    /**
     * Redirects the User to the Challenge Submission Page
     */
    newChallenge: function () {
      this.$router.push({
        path: "/new-challenge/",
      });
    },
    /**
     * Retrieves all Categories from the API
     * Used to filter for Categories
     */
    getCategories: function () {
      axios.get(`${config.apiBaseUrl}/categories`).then((response) => {
        console.log(response.data);
        this.categories = response.data;
        this.categoryNames = [];
        this.categories.forEach((cat) => {
          this.categoryNames.push(cat.name);
        });
      });
    },
    /**
     * Retrieves all Tags from the API
     * Used to filter for Tags
     */
    getTags: function () {
      axios.get(`${config.apiBaseUrl}/tags`).then((response) => {
        console.log(response.data);
        this.tags = response.data;
        this.tagNames = [];
        this.tags.forEach((tag) => {
          this.tagNames.push(tag.name);
        });
      });
    },
    /**
     * Gets called if the User presses the Menu button
     */
    menuClicked: function () {
      this.$emit("menuclick");
    },
  },
  mounted: function () {
    this.getAllChallenges();
    this.getCategories();
    this.getTags();
  },
};
</script>

<style lang="scss" scoped>
.filter-field {
  width: 20% !important;
  max-width: 20% !important;
  margin-right: 16px !important;
}

::v-deep .md-highlight-text-match {
  color: $md-theme-default-primary-on-background;
}

::v-deep .md-table {

  .md-table-content {
    height: fit-content !important;
    max-height: 100% !important;
  }
}


.challengeTable {
  height: calc(100% - 128px);
}

#browse-pageination {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 36px; 

  z-index: 9;

  box-shadow: 0 -2px 1px -1px rgba(0, 0, 0, 0.2),
    0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 -1px 3px 0 rgba(0, 0, 0, 0.12);

  button {
    margin-left: 16px;
  }

  .md-field {
    width: 100px;
    margin-right: 32px;
  }
}

.sortByLabel {
  margin: 12px;
  font-size: 16px;
}

.sortLabelText {
  font-size: 16px;
  background-color: lightgray;
  width: 70px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 680px) {
  .filter-field {
    width: 100% !important;
    max-width: 100% !important;
    margin-right: 0 !important;
    margin-top: 8px !important;
  }

  .md-button {
    margin-left: 0;
    min-width: 70px;
  }

  .sortByLabel {
    margin-left: 0;
  }

  #adminmenuS {
    margin-right: 0;
    margin-top: 8px;
  }

  #autoName {
    max-width: calc(100% - 70px) !important;
  }
}
</style>
