<!--
  Main Wrapper for the Page.
  Includes the Top Menu and the Footer at the Bottom
-->

<template>
  <div id="app">
    <div v-if="!isEmbedded" id="menugrid">
      <!-- ----- --Logo-- ----- -->
      <img
        v-responsive="['hidden-all', 'lg', 'xl']"
        alt="iCC logo"
        src="./assets/ic_consult_group_logo_rgb.png"
        id="logo"
      />

      <img
        v-responsive="['hidden-all', 'xs', 'sm', 'md']"
        alt="iCC logo"
        src="./assets/ic_consult_logo_rgb.png"
        id="small-logo"
      />

      <div id="menu">
        <!-- ----- --"Navbar"-- ----- -->
        <router-link id="home" class="menubutton" to="/" tag="div">{{ $t('mainmenu.home') }}</router-link>
        <router-link id="browse" class="menubutton" to="/browse" tag="div">{{ $t('mainmenu.browse') }}</router-link>
        <router-link
          v-if="hasRole('registered')"
          id="submit"
          class="menubutton"
          to="/new-challenge"
          tag="div">
          {{ $t('mainmenu.submit') }}
        </router-link>
        <div
          id="login"
          class="menubutton"
          @click="openLoginDialog"
          v-if="!loggedIn()">
          {{ $t('mainmenu.profile.login') }}
        </div>

        <md-menu
          md-size="medium"
          class="menubutton"
          md-align-trigger
          v-if="loggedIn()">

          <div id="profile" md-menu-trigger>{{ $t('mainmenu.profile.profile') }}</div>
          <md-menu-content>
            <md-menu-item @click="openProfile" :md-ripple="false">{{ $t('mainmenu.profile.viewProfile') }}</md-menu-item>

            <md-menu-item
              v-if="hasRole('admin')"
              @click="openAdminPanel"
              :md-ripple="false">

              {{ $t('mainmenu.profile.adminPanel') }}
            </md-menu-item>

            <md-menu-item @click="logout" :md-ripple="false">
              {{ $t('mainmenu.profile.logout') }}
            </md-menu-item>


          </md-menu-content>
        </md-menu>
      </div>
    </div>

    <!-- the different view templates will appear in this HTML element based on the route -->
    <div class="main" :class="isEmbedded? 'embedded' : ''">
      <router-view></router-view>
    </div>
    <!-- ----- --footer-- ----- -->
    <div v-if="!isEmbedded" class="footer">
      <router-link class="footerItem" style="cursor: pointer;" to="/privacy" tag="div">{{ $t('footer.privacy') }}</router-link>
      <a class="footerItem" href="https://ic-consult.com/" target="_blank">{{ $t('global.icConsult') }}</a>
      <md-menu
        md-size="medium"
        md-direction="top-start"
        md-align-trigger
        class="footerItem"
        style="z-index: 11"
      >
        <md-button md-menu-trigger>
          {{ $t('footer.language') }}
          <md-icon> keyboard_arrow_up </md-icon>
        </md-button>
        <md-menu-content style="z-index: 11">
          <md-menu-item>
            <md-field>
              <md-select v-model="$i18n.locale" name="language" id="language">
                <md-option value="en">English</md-option>
                <md-option value="de">German</md-option>
              </md-select>
            </md-field>
          </md-menu-item>
        </md-menu-content>
      </md-menu>
    </div>
    <!-- ----- --Login Screen-- ----- -->

    <div
      class="loginscreen"
      v-show="showLoginDialog && !isEmbedded"
      @click.self="showLoginDialog = false"
      @keydown.enter="clickLogin()"
    >
      <md-card class="loginwindow">
        <md-card-header>
          <div class="md-title">{{ $t('mainmenu.logindialog.title') }}</div>
        </md-card-header>

        <md-card-content>
          <md-field ref="unameinput">
            <label for="username">{{ $t('mainmenu.logindialog.username') }}</label>
            <md-input
              type="text"
              name="Username"
              id="username"
              v-model="username"
            />
            <span class="md-helper-text">Use "test"</span>
          </md-field>

          <md-field>
            <label for="password">{{ $t('mainmenu.logindialog.password') }}</label>
            <md-input
              type="password"
              name="Password"
              id="password"
              v-model="password"
            />
            <span class="md-helper-text">Use "admin"</span>
          </md-field>

          <div>
            {{ lastLoginError }}
          </div>
        </md-card-content>

        <md-card-actions>
          <md-button
            class="md-primary loginbutton"
            @click="showLoginDialog = false">{{ $t('global.cancel') }}</md-button>
          <md-button class="md-primary loginbutton" @click="clickLogin()">
            {{ $t('global.login') }}
          </md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import ApiService from "./services/api.service";
import AuthService from "./services/auth.service";

export default {
  data() {
    return {
      showLoginDialog: false,
      lastLoginError: "",
      username: "",
      password: "",
      isEmbedded: false,
    };
  },
  methods: {
    /**
     * Returns whether the User is logged in
     */
    loggedIn: function () {
      return AuthService.isLoggedIn();
    },

    /**
     * Checks whether the logged in User has a role
     */
    hasRole: function (role) {
      return ApiService.getUserRoles().indexOf(role) != -1;
    },

    /**
     * Opens the Login Dialog if the User clicks on Login
     */
    openLoginDialog: function () {
      this.showLoginDialog = true;
    },

    /**
     * Sends a Login Request to the API
     */
    clickLogin: function () {
      //TODO: Loading spinner or something
      ApiService.login({
        username: this.username,
        password: this.password,
      }).then((response) => {
        if (response === true) {
          this.showLoginDialog = false;
          this.username = "";
          this.password = "";
          //this.$router.go();
        } else {
          this.lastLoginError = response;
        }
      });
    },

    /**
     * Open the Admin Panel
     */
    openAdminPanel: function () {
      this.$router.push({
        path: "/admin/",
      });
    },

    /**
     * Open the Profile Page
     */
    openProfile: function () {
      this.$router.push({
        path: "/profile/",
      });
    },

    /**
     * Logout of the Page
     */
    logout: function () {
      ApiService.logout();
    },

    /**
     * Listens for Authentication Changes
     */
    authCallback: function () {
      this.$forceUpdate();
    },
  },

  mounted: function () {
    if (this.$router.currentRoute.path.indexOf("embed") != -1) {
      this.isEmbedded = true;
    }

    if (!this.isEmbedded) {
      AuthService.registerCallback(this.authCallback);
    }
  },

  destroyed: function () {
    AuthService.unregisterCallback(this.authCallback);
  },
};
</script>

<style lang="scss">
body {
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

.main {
  padding-bottom: 36px;
  overflow-x: hidden;
  height: calc(100% - 128px);
}

.main.embedded {
  height: 100%;
}

.footerItem {
  width: fit-content;
  margin: auto;
}

#menugrid {
  display: grid;
  grid-template-columns: minmax(auto, max-content) auto;
  grid-template-rows: auto auto;

  /*   width: calc(100% - 10px);
 */
  padding: 10px;

  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

  z-index: 200;
}

#menu {
  display: flex;
  align-items: center;

  grid-row: 2 / 3;
  grid-column: 2 / 3;
}

#logo {
  margin: 4px;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}

#small-logo {
  margin: 4px;
  grid-row: 1 / 2;
  grid-column: 2 / 3;

  width: 40%;

  margin-left: auto;
  margin-right: auto;
}

.menubutton {
  transition: 0.3s;
  font-size: 25px;
  /*   line-height: 108px;
 */
  width: 100%;
  height: fit-content;

  text-align: center;
}

@media only screen and (max-width: 680px) {
  .menubutton {
    font-size: 20px;
  }
}

.menubutton:hover {
  color: #84bd00;
  cursor: pointer;
}

.replace {
  grid-row: 1 / span 1;
  grid-column: cc / span 1;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $primary;
  text-align: center;
  display: flex;
  align-content: space-evenly;
  justify-content: space-evenly;

  z-index: 10;

  box-shadow: 0 -3px 1px -2px rgba(0, 0, 0, 0.2),
    0 -2px 2px 0 rgba(0, 0, 0, 0.14), 0 -1px 5px 0 rgba(0, 0, 0, 0.12);

  .footerItem {
    color: $md-theme-default-text-primary-on-primary !important;
    width: 200px;
  }
}

.loginscreen {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.loginwindow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 300px;
  z-index: 101;
}
.loginbutton {
  align-self: center;
}
</style>