<!--
  Challenge Take View
  This View show Items of a Challenge after another and the User can select Options form the shown Items to take a Challenge
-->

<template>
  <div>
    <md-dialog-confirm
      :md-title="$t('global.error.title')"
      :md-active.sync="showErrorDialog"
      :md-content="lastError"
      :md-confirm-text="$t('global.error.confirm')"
      :md-cancel-text="$t('global.error.otherConfirm')"
      @md-cancel="back"
      @md-confirm="back"/>


    <div id="taking-grid">
      <md-button v-if="!embed" class="md-icon-button" id="close-btn" @click="back"
        ><md-icon>close</md-icon></md-button
      >

      <div id="taking-chname">
        {{ challenge.name | maxlength(100)}}
      </div>

      <div id="taking-moreInfo" v-if="item != undefined && !finished">
        <div id="taking-moreInfo-title">
          {{$t('taking.helpfulInfo')}}
        </div>
        <div class="taking-moreInfo-item" v-for="info in item.moreInformation" :key="info">
          <a v-if="validURL(info)" target="_blank">{{info}}</a>
          <div v-else>{{info}}</div>
        </div>
      </div>

      <md-progress-bar
        v-if="!finished && !sending"
        id="taking-progress"
        md-mode="determinate"
        :md-value="currentItem * 100 / maxItems"
      ></md-progress-bar>

      <div v-if="!finished && !sending" id="taking-progress-text">
        {{$t('taking.questionOutOf', {current: currentItem + 1, max: maxItems})}}
      </div>

      <div v-if="!finished && !sending && timer != undefined" id="taking-time-text">
        <md-icon v-if="trainingMode">fitness_center</md-icon> <span v-if="trainingMode">(</span> <span :class="(timeLeft < 0? 'timeup' : '')">{{timeLeft | fancytimer}}</span> <span v-if="trainingMode">)</span>
      </div>

      <div
        v-if="!finished && !sending && item != undefined"
        id="taking-question"
      >
        <vue-simple-markdown id="taking-question" :source="item.question != undefined? item.question.replaceAll(/_+/g, '___') : ''"></vue-simple-markdown>
        <!-- {{ item.question }} -->
      </div>

      <div v-if="!finished && !sending && item != undefined && item.questionImageUrl != ''" id="taking-question-image">
        <img :src="item.questionImageUrl"/>
      </div>

      <div
        v-if="!finished && !sending && item != undefined"
        id="taking-options-wrapper"
      >
        <div id="taking-options" v-if="item.type == 'SINGLE_CHOICE' || item.type == 'MULTIPLE_CHOICE'">
          <div
            class="taking-options-option"
            v-bind:class="{ selected: isSelected(opt) }"
            v-for="opt in item.options"
            :key="opt.id"
            @click="selectOption(opt)"
          >
            <div v-if="opt.type == 'Text'">
              <vue-simple-markdown id="taking-options-option" :source="opt.data"></vue-simple-markdown>
              <!-- {{ opt.data }} -->
            </div>
            <img v-if="opt.type == 'Image'" :src="opt.data" />
          </div>
        </div>

        <div v-if="item.type == 'FILL_IN_THE_BLANK'">
          <div class="fillinblank_part" v-for="(opt, index) in item.question.split(/_+/g)" :key="opt.id">
            {{opt}}
            <md-field style="display: inline-block; width: auto;" v-if="index < item.question.split(/_+/g).length - 1">
              <label for="answer">{{ $t('taking.correctOptionInput') }}</label>
              <md-input  ref="optioninput" type="text" name="answer" id="answer" v-model="selectedOptions[item.id][index]"/>
            </md-field>
          </div>
        </div>

        <div v-if="item.type == 'CORRECT_ORDER'">
          <draggable id="taking-options" style="grid-template-columns: 50%;" v-model="item.options" group="people" draggable=".item" @start.self="drag=true" @end.self="drag=false">

            <div
              class="taking-options-option item"
              v-for="(opt, index) in item.options"
              :key="opt.id"
            >
              <div v-if="opt.type == 'Text'">
                <span style="font-size: 16px">{{index + 1}}. </span>
                <vue-simple-markdown style="display: inline-block;" id="taking-options-option" :source="opt.data"></vue-simple-markdown>
                <!-- {{ opt.data }} -->
              </div>
              <img v-if="opt.type == 'Image'" :src="opt.data" />
            </div>
          </draggable>
        </div>
      </div>

      <md-button
        v-if="!finished && !sending"
        class="md-raised"
        id="next-btn"
        @click="next"
        :disabled="selectedOptions[item.id] != undefined && selectedOptions[item.id].length < 1 && item.type != 'CORRECT_ORDER'"
        >{{ $t(currentItem + 1 >= maxItems ? "taking.finishBtn" : "taking.nextBtn") }}</md-button
      >

      <div v-if="sending" id="taking-sending">
        <md-progress-spinner
          :md-diameter="100"
          :md-stroke="10"
          md-mode="indeterminate"
        ></md-progress-spinner>
      </div>

      <div v-if="finished && !sending" id="taking-finished">
        <div v-if="!trainingMode" id="taking-passtext">
          {{ $t(result.passed? "taking.passed" : "taking.notPassed") }}
        </div>

        <div id="taking-correcttext">
          {{$t('taking.correctOutOf', {correct: result.amountCorrect, max: maxItems})}}
        </div>

        <div v-if="currentItem != maxItems" id="taking-itemsdone">
          {{$t('taking.answeredOutOf', {answered: currentItem, max: maxItems})}}
        </div>

        <div id="taking-showresultsbtn" v-if="trainingMode && finished && !embed">
          <md-button class="md-raised md-primary" @click="toggleResults()" :disabled="sending">
            {{$t(showResults? 'taking.hideResultsBtn' : 'taking.showResultsBtn')}}
          </md-button>
        </div>

        <div v-if="!embed" id="taking-backbtn">
          <md-button class="md-raised" @click="back()">{{$t('taking.backBtn')}}</md-button>
        </div>
      </div>

      <div id="taking-results" v-if="showResults || (finished && embed)">
        <ResultView :items="items" :result="result" />
      </div>
    </div>
  </div>
</template>

<script>
// import configuration with API url; @ refers to the src directory
import config from "@/config";
// import library for HTTP requests
import axios from "axios";
import draggable from 'vuedraggable'

import ResultView from "../components/ResultView.vue";
import {stringToType, ItemType, typeToLangKey, typeToIcon} from "../models/itemtype";

export default {
  name: "ChallengeTakeView",
  props: ["id", "train", "embed", "nouser"],
  components: {ResultView, draggable},
  data() {
    return {
      challenge: {
        name: "",
        description: "",
        tags: [],
        category: null,
        passLimit: 0,
      },
      item: {},
      items: [],
      trainingMode: false,
      currentItem: 0,
      maxItems: 0,
      selectedOptions: {},
      finished: false,
      sending: false,
      result: {},
      showResults: false,
      timer: undefined,
      timeLeft: 0,
      showErrorDialog: false,
      lastError: "",
    };
  },
  methods: {
    /**
     * Methods imported from ItemType
     */
    stringToType,
    /**
     * Checks whether a String is a valid URL
     */
    validURL: function(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
    },
    /**
     * Toggles the view of the Results at the end of a Challenge
     * Only available if the Challenge has been started in Training Mode
     */
    toggleResults: function () {
      if (!this.trainingMode) return;
      this.showResults = !this.showResults;
    },
    /**
     * Starts the Timer if the Challenge has a Time Limit specified
     */
    startTimer: function () {
      if (this.challenge.timeLimit == 0) return;
      //this.timeLeft = this.challenge.timeLimit;
      this.timer = setInterval(() => {
        this.timeLeft -= 1;
        if (this.timeLeft <= 0 && !this.trainingMode) this.timeEnded();
      }, 1000);
    },
    /**
     * Stops the Timer
     */
    stopTimer: function () {
      if (this.timer == undefined) return;
      clearInterval(this.timer);
      this.timer == undefined;
    },
    /**
     * Callback for when the Timer has reached 0 seconds.
     * Sends final Answers to the Server
     */
    timeEnded: function () {
      console.log("Timer ended!");

      this.next();
    },
    /**
     * Goes back one Page
     */
    back: function () {
      this.$router.go(-1);
      this.stopTimer();
    },
    /**
     * Saves the Options, which the User selected
     */
    selectOption: function (opt) {
      if (this.isSelected(opt)) {
        this.selectedOptions[this.item.id].splice(
          this.selectedOptions[this.item.id].indexOf(opt.id),
          1
        );
      } else {
        if (this.item.type == "SINGLE_CHOICE") {
          this.selectedOptions[this.item.id] = [];
        }

        this.selectedOptions[this.item.id].push(opt.id);
      }

      this.$forceUpdate();
      console.log(this.selectedOptions);
    },
    /**
     * Returns whether an Option has been selected by the User
     */
    isSelected: function (opt) {
      return (
        this.selectedOptions[this.item.id] != undefined &&
        this.selectedOptions[this.item.id].includes(opt.id)
      );
    },
    /**
     * Retrieves Challenge Information about the Challenge the User wants to start
     */
    getChallenge: function () {
      axios
        .get(`${config.apiBaseUrl}/challenges/${this.id}`)
        .then((response) => {
          console.log(response.data);
          this.challenge = response.data.challenge;
          this.challenge.category = response.data.challenge.category.name;
          if (this.embed || this.nouser) {
            this.startChallengeEmbedded();
         } else {
            this.startChallenge();
          }
        }).catch((err) => {
          console.log(err);
          if (err.response.status == 404) {
            this.lastError = "This Challenge does not exist";
          } else if (err.response.status == 403) {
            this.lastError = "You don't have access to this Challenge";
          }
          this.showErrorDialog = true;
        });
    },
    /**
     * Gets the First Item from the API and starts the Challenge
     * 
     */
    startChallenge: function () {
      axios.post(`${config.apiBaseUrl}/challenges/${this.id}/start`, {trainingMode: this.trainingMode})
        .then((response) => {
          console.log(response.data);

          if (response.data.finished) {
            this.result = response.data.result;
            this.items = response.data.items;
            this.maxItems = response.data.maxItems;
            this.finish();
            this.currentItem = response.data.currentItem;
          } else {
            this.item = response.data.nextItem;
            this.maxItems = response.data.maxItems;
            this.currentItem = response.data.currentItem;
            this.timeLeft = response.data.timeLeft;

            this.initializeNextItem();
            this.startTimer();
          }
        }).catch((err) => {
          console.log(err);
          if (err.response.status == 404) {
            this.lastError = "This Challenge does not exist";
          } else if (err.response.status == 403) {
            this.lastError = err.response.data.message;
          } else if (err.response.status == 417) {
            this.lastError = "This Challenge has no Items";
          }
          this.showErrorDialog = true;
        });
    },
    /**
     * Gets the First Item from the API and starts the Challenge
     * 
     */
    startChallengeEmbedded: function () {
      axios.post(`${config.apiBaseUrl}/challenges/${this.id}/startembedded`, {trainingMode: this.embed? false : this.trainingMode, embedded: this.embed})
        .then((response) => {
          console.log(response.data);


          this.items = response.data.allItems;

          this.maxItems = response.data.maxItems;
          this.currentItem = response.data.currentItem;
          this.timeLeft = response.data.timeLeft;

          this.getNextItemEmbedded();
          this.initializeNextItem();
          this.startTimer();
          
        }).catch((err) => {
          console.log(err);
          if (err.response.status == 404) {
            this.lastError = "This Challenge does not exist";
          } else if (err.response.status == 403) {
            this.lastError = err.response.data.message;
          } else if (err.response.status == 417) {
            this.lastError = "This Challenge has no Items";
          }
          this.showErrorDialog = true;
        });
    },
    /**
     * Prepares Variables for the Next item
     */
    initializeNextItem: function() {
      console.log(this.item.options);
      this.shuffle(this.item.options);
      console.log(this.item.options);

      if (this.item.type == "FILL_IN_THE_BLANK") {
        this.selectedOptions[this.item.id] = [];
        for (let i = 0; i < this.item.question.split(/_+/g).length - 1; i++) {
          this.selectedOptions[this.item.id][i] = "";
        } 
      } else {
        this.selectedOptions[this.item.id] = [];
      }

      console.log(this.selectedOptions);
    },
    /**
     * Returns the Next Item from client stored Challenge in Embedded View
     */
    getNextItemEmbedded: function() {
      this.item = this.items.sort((a, b) => (a.itemOrder > b.itemOrder) ? 1 : ((b.itemOrder > a.itemOrder) ? -1 : 0))[this.currentItem].item;
    },
    /**
     * Sends the selected Options for the Current Item and gets the next Item form the API
     * If a Challenge has been finished, this function gets the Information form the API.
     */
    next: function () {

      if (this.item.type == "CORRECT_ORDER") {
        this.item.options.forEach(opt => {
          this.selectedOptions[this.item.id].push(opt.id);
        });
      }

      if (this.embed || this.nouser) {
        this.nextEmbedded();
        return;
      }

      axios.post(`${config.apiBaseUrl}/challenges/${this.id}/submititem`, {itemID: this.item.id, selectedOptions: this.selectedOptions[this.item.id]})
        .then((response) => {
          console.log(response.data);

          if (response.data.finished) {
            this.result = response.data.result;
            this.items = response.data.items;
            this.finish();
            this.currentItem = response.data.currentItem;
          } else {
            this.item = response.data.nextItem;
            this.currentItem = response.data.currentItem;
            this.timeLeft = response.data.timeLeft;

            this.initializeNextItem();
          }
        }).catch((err) => {
          console.log(err);
          var status = err.response.status || err.status;
          var message = err.response.message || err.message;

          if (status == 404) {
            this.lastError = "This Challenge does not exist";
          } else if (status == 403) {
            this.lastError = message;
          } else if (status == 417) {
            this.lastError = "This Challenge has no Items";
          }
          this.showErrorDialog = true;
        });
    },
    /**
     * Same as next(), but for embedded usage, where the Challenge and its Items are stored on the Client
     */
    nextEmbedded: function() {
      this.currentItem++;
      if (this.currentItem >= this.maxItems) {
        var res = {};
        res.submittedOptions = this.selectedOptions;

        this.items.map(item => {
          console.log(item);
          if (item.item.type == "FILL_IN_THE_BLANK" || item.item.type == "CORRECT_ORDER") {
            item.item.options.sort(function(a, b){return a.optionOrder - b.optionOrder});
          }
          console.log(item);
          return item;
        });

        var correct = [];
        var corrAmount = 0;
        this.items.forEach(it => {
          var itemCorrect = true;

          console.log(it);
          console.log(this.selectedOptions[it.item.id]);

          switch(this.stringToType(it.item.type)) {
            case ItemType.SINGLE_CHOICE:
            case ItemType.MULTIPLE_CHOICE:
              correct[it.item.id] = [];
              it.item.options.forEach(opt => {
                if (opt.correctOption) correct[it.item.id].push(opt.id);

                console.log(opt);
                console.log(this.selectedOptions);
                console.log(this.selectedOptions[it.item.id].indexOf(opt.id) != -1);
                
                if (opt.correctOption) itemCorrect &= this.selectedOptions[it.item.id].includes(opt.id)
                else itemCorrect &= !this.selectedOptions[it.item.id].includes(opt.id)
              });
              break;

            case ItemType.FILL_IN_THE_BLANK:
              correct[it.item.id] = [];
              var i = 0;
              it.item.options.forEach(opt => {
                correct[it.item.id].push(opt.data);

                console.log(opt);
                console.log(it);
                console.log(i);

                itemCorrect &= this.selectedOptions[it.item.id][i] == opt.data;
                i++;
              });
              break;

            case ItemType.CORRECT_ORDER:
              correct[it.item.id] = [];
              var i = 0;
              it.item.options.forEach(opt => {
                correct[it.item.id].push(opt.id);

                itemCorrect &= this.selectedOptions[it.item.id][i] == opt.id;
                i++;
              });
              break;
          }
          console.log(itemCorrect);
          if (itemCorrect) corrAmount += 1;
        });
        res.amountCorrect = corrAmount;
        res.correctAnswers = correct;
        res.passed = corrAmount >= this.challenge.passLimit;

        var itemtmp = [];
        this.items.forEach(it => {
          itemtmp.push(it.item);
        });
        this.items = itemtmp;

        this.result = res;
        this.finish();
      } else {
        this.getNextItemEmbedded();
        this.initializeNextItem();
      }
    },
    /**
     * Stops Timer and updates the View with the Finished Texts
     */
    finish: function () {
      this.stopTimer();
      this.finished = true;
      this.$forceUpdate();
    },
    /** 
     * This function shuffles an Array
     * Used to shuffle the available Options for an Item
     */
    shuffle: function(array) {
      var currentIndex = array.length, temporaryValue, randomIndex;

      while (0 !== currentIndex) {

        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },
  },
  /**
   * Check whether this Challenge has been started in Training Mode
   */
  created: function () {
    this.trainingMode = this.$route.query.m == "train" || this.train;
    this.nouser = this.$route.query.nouser == "true";
    this.getChallenge();

    console.log(this.itemTypes);
  },
  /**
   * Stop the Timer if this View gets destroyed
   */
  destroyed: function() {
    this.stopTimer();
  }
};
</script>

<style lang="scss" scoped>
.main {
  padding: 0;
}

#taking-grid {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  /* title + progress | time | question + info | qimage | options | next */
  grid-template-rows: 100px auto auto auto auto 50px auto;

  padding: 10px;
  padding-top: 16px;
  padding-bottom: 64px;

  margin-bottom: 32px;
}

#close-btn {
  float: right;
  margin-left: auto;

  grid-column: 3 / 4;
  grid-row: 1 / 2;

  i {
    color: #f44336;
  }
}

.timeup {
  color: #f44336;
}

#taking-chname {
  font-size: 35px;
  line-height: 35px;

  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

#taking-moreInfo {
  grid-column: 1 / 2;
  grid-row: 3 / 5;

  font-size: 15px;

  #taking-moreInfo-title {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 16px;
    text-decoration: underline dotted rgba(0, 0, 0, 0.3);
  }
}

.taking-moreInfo-item {
  padding-bottom: 8px;

  a {
    cursor: pointer;
    /* text-decoration: none !important; */
  }

 /*  a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }

  a:hover::before {
    visibility: visible;
    transform: scaleX(1);
  } */
}

#taking-progress {
  margin: 16px;

  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

#taking-progress-text {
  font-size: 20px;
  line-height: 20px;

  margin: 16px;
  margin-top: 37px;

  text-align: center;

  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

#taking-time-text {
  font-size: 30px;
  line-height: 30px;

  margin: 16px;

  text-align: center;

  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

#taking-question {
  font-size: 35px;
  line-height: 35px;

  margin: 16px;

  text-align: center;

  grid-column: 2 / 3;
  grid-row: 3 / 4;
}

#taking-question-image {
  grid-column: 2 / 3;
  grid-row: 4 / 5;

  img {
    max-height: 300px;
    width: 100%;
    object-fit: contain;
  }
}

#taking-options-wrapper {
  font-size: 25px;
  line-height: 25px;

  margin: 16px;

  text-align: center;

  grid-column: 1 / 4;
  grid-row: 5 / 6;
}

#taking-options {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 8px;
  justify-content: center;
}

.taking-options-option {
  padding: 8px;

  background-color: white;
  border-radius: 4px;

  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

  transition: 0.3s;

  img {
    max-width: 250px;
    object-fit: contain;
  }
}

.fillinblank_part {
  display: inline;
}

.selected {
  background-color: rgba($primary, 0.4);
}

.taking-options-option:hover {
  background-color: rgba($primary, 0.2);
  cursor: pointer;
}

#next-btn {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  grid-column: 2 / 3;
  grid-row: 6 / 7;
}

#taking-sending {
  grid-column: 2 / 3;
  grid-row: 4 / 6;
  margin-left: auto;
  margin-right: auto;
}

#taking-finished {
  grid-column: 2 / 3;
  grid-row: 4 / 6;
}

#taking-passtext {
  font-size: 35px;
  line-height: 35px;

  margin-bottom: 32px;

  text-align: center;
}

#taking-correcttext {
  font-size: 25px;
  line-height: 25px;

  margin-bottom: 32px;

  text-align: center;
}

#taking-itemsdone {
  font-size: 25px;
  line-height: 25px;

  margin-bottom: 32px;

  text-align: center;
}

#taking-showresultsbtn {
  width: fit-content;

  margin-left: auto;
  margin-right: auto;
}

#taking-results {
  grid-column: 1 / 4;
  grid-row: 7 / 8;

  /* height: fit-content;
  width: fit-content; */
  margin-top: 32px;
  /* margin-left: auto;
  margin-right: auto; */
}

@media only screen and (max-width: 1000px) {
  #taking-chname {
    font-size: 25px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 680px) {
  #taking-chname {
    font-size: 15px;
    line-height: 15px;
  }

  #taking-progress-text {
    font-size: 15px;
    line-height: 15px;
  }

  #taking-time-text {
    font-size: 20px;
  }

  #taking-question {
    font-size: 25px;
  }
}

#taking-backbtn {
  width: fit-content;

  margin-left: auto;
  margin-right: auto;
}
</style>
