<!--
  Home Page of the Website
-->

<template>
  <div class="home">
    <h1 class="homeHeader">{{ $t('home.header') }}</h1>
    <h3>{{ $t('home.header2') }}</h3>
    <h3>{{ $t('home.header3') }}</h3>
    <br/>
    <h3>{{ $t('home.header4') }}</h3>
    <img
      class="img"
      alt="iCC logo"
      src="~@/assets/ic_consult_group_logo_4000x4000.png"
      id="logo"
    />
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {

    };
  },
  methods: {
  },
};
</script>

<style>
.homeHeader {
  text-align: center;
  line-height: 1em;
}
.home {
  margin-left: 10px;
  text-align: center;
  object-fit: scale-down;
}
.img {
  width: 37.5%;
  object-fit: contain;
}
</style>
