<!--
  Browse Component
  Page which contains Filters to search and find Challenges
  Main Page to list Challenges
-->

<template>
  <div>
    <!--Filter Toolbar-->
    <md-toolbar
      id="filter-toolbar"
      class="md-primary"
      md-elevation="1"
      @keydown.enter="applyFilter"
    >
      <!--Filter for Name-->
      <md-autocomplete
        id="autoName"
        class="filter-field"
        v-model="filter.name"
        :md-options="challengeNames()"
        md-layout="box"
        :md-fuzzy-search="false"
        data-lpignore
        ref="inputname"
        @md-opened="closeAutocompletes('inputname')"
      >
        <label>{{ $t('browse.search.text') }}</label>

        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
          <md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
        </template>

        <template slot="md-autocomplete-empty" slot-scope="{ term }">
          {{ $t('browse.search.textAutocomplete', {term: term}) }}
        </template>
      </md-autocomplete>

      <!--Filter for Categories-->
      <md-autocomplete
        id="autoCat"
        class="filter-field"
        v-model="filter.category"
        :md-options="categoryNames"
        md-layout="box"
        :md-fuzzy-search="false"
        ref="inputcat"
        @md-opened="closeAutocompletes('inputcat')"
      >
        <label>{{ $t('browse.search.categories') }}</label>

        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
          <md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
        </template>

        <template slot="md-autocomplete-empty" slot-scope="{ term }">
          {{ $t('browse.search.categoriesAutocomplete', {term: term}) }}
        </template>
      </md-autocomplete>

      <!--Filter for Tags-->
      <md-autocomplete
        id="autoTag"
        class="filter-field"
        v-model="filter.tags"
        :md-options="tagNames"
        md-layout="box"
        ref="inputtag"
        @md-opened="closeAutocompletes('inputtag')"
      >
        <label>{{ $t('browse.search.tags') }}</label>

        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
          <md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
        </template>

        <template slot="md-autocomplete-empty" slot-scope="{ term }">
          {{ $t('browse.search.tagsAutocomplete', {term: term}) }}
        </template>
      </md-autocomplete>

      <!--Filter Button-->
      <md-button
        class="md-raised"
        @click="applyFilter()"
        :disabled="
          this.filter.name == '' &&
          this.filter.category == '' &&
          this.filter.tags == ''
        ">
        {{ $t('global.filter') }}
      </md-button>
      <md-button class="md-raised" :disabled="!filtered" @click="clearFilter()">{{ $t('global.clear') }}</md-button>

      <div class="sortByLabel">
        <label>{{ $t('browse.search.sortBy.label') }}</label>
      </div>

      <md-menu :md-align-trigger="true">
        <md-content class="sortLabelText" md-menu-trigger>
          <md-icon v-if="filter.sort == ''">keyboard_arrow_down</md-icon>
          {{ filter.sort }}
        </md-content>

        <md-menu-content>
          <md-menu-item @click="clickEventSort('')" :md-ripple="false">{{ $t('browse.search.sortBy.sortNone') }}</md-menu-item>
          <md-menu-item @click="clickEventSort('Name')" :md-ripple="false">{{ $t('browse.search.sortBy.sortName') }}</md-menu-item>
          <md-menu-item @click="clickEventSort('Category')" :md-ripple="false">{{ $t('browse.search.sortBy.sortCategory') }}</md-menu-item>
        </md-menu-content>
      </md-menu>

      <md-button v-responsive="['hidden-all', 'xs', 'sm', 'md']" style="min-width: 50px; margin-right: 0" class="md-icon-button" @click="getAllChallenges()">
        <md-icon>refresh</md-icon>
      </md-button>

      <div v-responsive="['hidden-all', 'lg', 'xl']" class="md-toolbar-section-end">
        <md-button class="md-icon-button" @click="getAllChallenges()">
          <md-icon>refresh</md-icon>
        </md-button>
      </div>
    </md-toolbar>

    <div id="browse-content">
      <md-empty-state
        v-if="challenges != null && challenges.length == 0"
        md-icon="list_alt"
        :md-label="$t('browse.empty.label')"
        :md-description="$t('browse.empty.description')"
      >
        <md-button class="md-primary md-raised" @click="clearFilter()">{{ $t('browse.empty.button') }}</md-button>
      </md-empty-state>

      <!--Challenge Cards-->
      <md-card
        class="md-card"
        md-with-hover
        v-for="challenge in challenges"
        v-bind:key="challenge.id"
        v-on:click.native="openChallenge(challenge.id)"
        style=""
      >
        <md-card-header>
          <div class="md-title">
            <md-highlight-text
              :md-fuzzy-search="false"
              :md-term="filter.name"
              >{{ challenge.name }}</md-highlight-text
            >
          </div>
        </md-card-header>

        <md-card-content>
          <md-highlight-text :md-fuzzy-search="false" :md-term="filter.name">{{
            challenge.description
          }}</md-highlight-text>
        </md-card-content>

        <md-card-actions style="padding-left: 16px; padding-right: 16px">
          <div style="margin-right: auto; max-width: 35%"
            :class="filter.category == challenge.category.name ? 'highlight' : ''">
              {{ challenge.category.name }}
          </div>
          <div style="max-width: 65%; height: 32px; overflow: hidden; text-align: end;">
            <md-chip
              v-for="tag in challenge.tags"
              v-bind:key="tag.id"
              :class="filter.tags == tag.name ? 'md-primary' : ''"
              @click.stop="
                filter.tags = tag.name;
                applyFilter();
              "
              style="z-index: 9999">
                {{ tag.name | maxlength(10)}}
            </md-chip>
          </div>
        </md-card-actions>
      </md-card>

      <md-toolbar id="browse-pageination" md-elevation="1">
        <div class="md-toolbar-section-end">
          <md-field>
            <md-select
              v-model="pageSize"
              name="pageSize"
              id="browse-pagination-pageSize"
              @md-selected="switchPage(currentPage)"
            >
              <md-option value="5">5</md-option>
              <md-option value="10">10</md-option>
              <md-option value="25">25</md-option>
              <md-option value="50">50</md-option>
            </md-select>
          </md-field>
          <span>{{ currentPage + 1 + " of " + totalPages }}</span>
          <md-button
            class="md-icon-button"
            :disabled="currentPage == 0"
            @click="switchPage(currentPage - 1)"
          >
            <md-icon>navigate_before</md-icon>
          </md-button>
          <md-button
            class="md-icon-button"
            :disabled="currentPage + 1 >= totalPages"
            @click="switchPage(currentPage + 1)"
          >
            <md-icon>navigate_next</md-icon>
          </md-button>
        </div>
      </md-toolbar>
    </div>
  </div>
</template>
<script>
// import configuration with API url; @ refers to the src directory
import config from "@/config";
// import library for HTTP requests
import axios from "axios";
//import { filter } from 'vue/types/umd';
import AuthService from "../services/auth.service";

export default {
  name: "Browse",
  data() {
    return {
      categories: [],
      categoryNames: [],
      tags: [],
      tagNames: [],
      challenges: null,
      deleteMessage: "",
      filtered: false,
      filter: {
        name: "",
        category: "",
        tags: "",
        sort: "",
      },
      totalPages: 0,
      currentPage: 0,
      pageSize: 10,
    };
  },
  methods: {
    /**
     * Applies the Filters set by the User and gets the filtered Results from the API
     */
    applyFilter: function () {
      console.log(this.filter);
      this.filtered = true;
      this.currentPage = 0;

      this.getAllChallenges();
    },
    /**
     * Clears the Filters and retrieves Results from the API
     */
    clearFilter: function () {
      this.challenges = null;
      this.filtered = false;
      this.currentPage = 0;

      this.getAllChallenges();
    },
    /**
     * Closes the Autocompletion Inputs, when another one is selected
     */
    closeAutocompletes: function(elem) {
      console.log("kek: " + elem);
      console.log(this.$refs["inputname"].$el.getElementsByClassName("md-input")[0]);
      if (elem != "inputname") this.$refs["inputname"].$el.getElementsByClassName("md-input")[0].blur();
      if (elem != "inputcat") this.$refs["inputcat"].$el.getElementsByClassName("md-input")[0].blur();
      if (elem != "inputtag") this.$refs["inputtag"].$el.getElementsByClassName("md-input")[0].blur();
    },
    /**
     * Returns an Array of all Challenge Names in the current Results
     * Used to Auto Fill the Name Search Box
     */
    challengeNames: function () {
      var n = [];
      if (Array.isArray(this.challenges))
        this.challenges.forEach((ch) => {
          n.push(ch.name);
        });
      return n;
    },
    /**
     * Retrieves Challenges from the API according to Filters and Pagination
     */
    getAllChallenges: function () {
      if (!this.filtered) {
        axios
          .get(`${config.apiBaseUrl}/challenges`, {
            params: {
              page: this.currentPage,
              size: this.pageSize,
              s: this.filter.sort,
            },
          })
          .then((response) => {
            console.log(response.data);
            this.challenges = response.data.content;
            this.totalPages = response.data.totalPages;
          });
      } else {
        var catId = "";
        var tagId = "";

        if (this.filter.category != "")
          catId = this.categories.find(
            (c) => c.name === this.filter.category
          ).id;

        if (this.filter.tags != "")
          tagId = this.tags.find((t) => t.name === this.filter.tags).id;

        axios
          .get(`${config.apiBaseUrl}/challenges`, {
            params: {
              page: this.currentPage,
              size: this.pageSize,
              s: this.filter.sort,
              fn: this.filter.name,
              fc: catId,
              ft: tagId,
            },
          })
          .then((response) => {
            console.log(response.data);
            this.challenges = response.data.content;
            this.totalPages = response.data.totalPages;
          });
      }
    },
    /**
     * Switches the Page and retrieves new Results
     */
    switchPage: function (selectedPage) {
      console.log(selectedPage);
      this.currentPage = selectedPage;
      this.getAllChallenges();
    },
    /**
     * Retrieves all Categories from the API
     * Used to filter for Categories
     */
    getCategories: function () {
      axios.get(`${config.apiBaseUrl}/categories`).then((response) => {
        console.log(response.data);
        this.categories = response.data;
        this.categoryNames = [];
        this.categories.forEach((cat) => {
          this.categoryNames.push(cat.name);
        });
      });
    },
    /**
     * Retrieves all Tags from the API
     * Used to filter for Tags
     */
    getTags: function () {
      axios.get(`${config.apiBaseUrl}/tags`).then((response) => {
        console.log(response.data);
        this.tags = response.data;
        this.tagNames = [];
        this.tags.forEach((tag) => {
          this.tagNames.push(tag.name);
        });
      });
    },
    /**
     * Opens the DetailsView for a Challenge if the User clicked on one
     */
    openChallenge: function (id) {
      this.$router.push({
        path: "/challenge/" + id,
      });
    },
    /**
     * Starts retrieving new Challenges if the User Sorted for a Property
     */
    clickEventSort: function (string) {
      this.filter.sort = string;
      this.getAllChallenges();
    },
    /**
     * Listens for Authentication Changes
     */
    authCallback: function() {
      this.$forceUpdate();
      this.getAllChallenges();
    }
  },

  /**
   * Get all Information, when this Component gets loaded
   */
  created: function () {
    AuthService.registerCallback(this.authCallback);

    this.getAllChallenges();
    this.getCategories();
    this.getTags();
  },

  destroyed: function() {
    AuthService.unregisterCallback(this.authCallback); 
  },
};
</script>

<style lang="scss" scoped>
#filter-toolbar {
  display: flex;
}

.filter-field {
  width: 20% !important;
  max-width: 20% !important;
  margin-right: 16px !important;
}

#browse-content {
  padding: 10px;
  justify-items: stretch;
}

#browse-pageination {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 36px;

  z-index: 9;

  box-shadow: 0 -2px 1px -1px rgba(0, 0, 0, 0.2),
    0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 -1px 3px 0 rgba(0, 0, 0, 0.12);

  button {
    margin-left: 16px;
  }

  .md-field {
    width: 100px;
    margin-right: 32px;
  }
}

.md-card {
  min-width: 250px;
  max-width: 350px;
  margin: 8px;

  justify-self: stretch;
  align-self: stretch;
}

.md-card .md-highlight-text-match {
  color: $md-theme-default-primary-on-background;
}

.highlight {
  color: $md-theme-default-primary-on-background;
}

.spacing {
  margin: 16px;
}
.alignment {
  text-align: center;
}
.sortByLabel {
  margin: 12px;
  font-size: 16px;
}
.sortLabelText {
  font-size: 16px;
  background-color: lightgray;
  width: 70px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 680px) {
  .filter-field {
    width: 100% !important;
    max-width: 100% !important;
    margin-right: 0 !important;
    margin-top: 8px !important;
  }

  .md-button {
    margin-left: 0;
    min-width: 70px;
  }

  .sortByLabel {
    margin-left: 0;
  }
}
</style>
