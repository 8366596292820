<!--
  Dialog with Input Fields to create a new Category
-->

<template>
  <md-dialog id="category-dialog" :md-active.sync="showDialog">
    <md-dialog-title>{{ $t('newCategoryDialog.title') }}</md-dialog-title>

    <form novalidate class="md-layout" @submit.prevent="validate" style="padding: 16px">
      <md-field :class="getValidationClass('name')">
        <label for="name">{{ $t('newCategoryDialog.nameLabel') }}</label>
        <md-input type="text" name="name" id="name" v-model="form.name" :disabled="sending" />
        <span class="md-error" v-if="!$v.form.name.required">{{ $t('newCategoryDialog.nameRequired') }}</span>
      </md-field>

      <md-field>
        <label for="description">{{ $t('newCategoryDialog.descriptionLabel') }}</label>
        <md-input type="text" name="description" id="description" v-model="form.description" :disabled="sending" />
      </md-field>

      <md-progress-bar md-mode="indeterminate" v-if="sending" />

      <md-dialog-actions>
        <md-button class="md-primary" @click="closeForm">{{ $t('global.cancel') }}</md-button>
        <md-button type="submit" class="md-primary" :disabled="sending">{{ $t('global.create') }}</md-button>
      </md-dialog-actions>
    </form>
  </md-dialog>
</template>

<script>
// import configuration with API url; @ refers to the src directory
import config from "@/config";
// import library for HTTP requests
import axios from "axios";

import { validationMixin } from 'vuelidate';
import {
  required
} from 'vuelidate/lib/validators';

export default {
  name: "NewCategory",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: "",
        description: ""
      },
      sending: false
    };
  },
  props: ["showDialog"],
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  methods: {
    /**
     * Validation for Input Fields
     */
    getValidationClass (fieldName) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    /**
     * Clears the Form to be reused
     */
    clearForm () {
      this.$v.form.$reset()
      this.form.name = ""
      this.form.description = ""
    },
    /**
     * Validates the Inputs and then continues sending the Request to the API
     */
    validate () {
      this.$v.form.$touch()

      console.log(this.$v);

      if (!this.$v.form.$invalid) {
      console.log("submitting");
        this.createNewCategory()
      }
    },
    /**
     * Sends the new Category Request to the API and informs the Parent Component about it
     */
    createNewCategory: function() {
      this.sending = true;

      axios.post(`${config.apiBaseUrl}/categories`, this.form).then((response) => {
        console.log(response);
        this.sending = false;

        this.$emit("newCategory", response.data);

        this.clearForm();
      });
    },
    /**
     * Closes the Dialog
     */
    closeForm: function() {
      this.clearForm();
      this.$emit("newCategory", undefined);
    }
  },
};
</script>

<style>
#category-dialog {
  padding: 10px;
  min-width: 250px;
}
</style>
