<!--
  Profile Page for Users
  Shows Information about the Users
-->

<template>
  <div class="profile">
    <md-card class="profileCard">
      <md-card-header class="header">{{ $t("profile.title") }}</md-card-header>
      <md-card-content>
        <!-- 
          Table with user information
        -->
        <md-table>
          <!-- 
             Row for Username
           -->
          <md-table-row>
            <md-table-cell>{{ $t("global.username") }}</md-table-cell>
            <md-table-cell style="text-align: center">
              <md-field>
                <md-input
                  v-model="user.username"
                  :readonly="!editName"
                ></md-input>
              </md-field>
            </md-table-cell>
            <md-table-cell style="text-align: right">
              <md-button
                name="EditButton"
                class="editButton md-icon-button"
                @click="nameEditClosed()"
              >
                <md-icon v-if="!editName">edit</md-icon>
                <md-icon v-if="editName">save</md-icon>
              </md-button>
              <md-tooltip md-direction="right">{{ $t("profile.editUNameTooltip") }}</md-tooltip>
            </md-table-cell>
          </md-table-row>
          <!-- 
            Row for Password
           -->
          <md-table-row>
            <md-table-cell>{{ $t("global.password") }}</md-table-cell>
            <md-table-cell style="text-align: center">
              <div style="font-size: 16px; text-align: start">
                {{ $t("profile.changePWText") }}
              </div>
            </md-table-cell>
            <md-table-cell style="text-align: right"
              ><md-button
                class="editButton md-icon-button"
                @click="editPassword = true"
              >
                <md-icon>edit</md-icon>
              </md-button>
              <md-tooltip md-direction="right"
                >{{ $t("profile.editPWTooltip") }}</md-tooltip
              ></md-table-cell
            >
          </md-table-row>
          <!-- 
            Row for User Email
           -->
          <md-table-row>
            <md-table-cell>{{ $t("global.email") }}</md-table-cell>
            <md-table-cell style="text-align: center"
              ><md-field>
                <md-input v-model="user.email" readonly></md-input></md-field
            ></md-table-cell>
            <md-table-cell style="text-align: right"
              ><md-button v-if="false"> </md-button
            ></md-table-cell>
          </md-table-row>
          <!-- 
            Row for User Role
           -->
          <md-table-row>
            <md-table-cell>{{ $t("global.role") }}</md-table-cell>
            <md-table-cell style="text-align: center">
              <div style="font-size: 16px; text-align: start">
                {{ user.roles == undefined? "" : $t(roleToLangKey(stringToRole(user.roles[0]))) }}
              </div>
            </md-table-cell>
            <md-table-cell style="text-align: right"></md-table-cell>
          </md-table-row>
        </md-table>
        <!-- Button for result dialogs -->
        <div style="text-align: center">
          <md-button
            class="md-raised md-primary"
            @click="showChallengeDialog = true"
            >{{ $t("profile.viewResultsBtn") }}</md-button
          >
        </div>
      </md-card-content>
    </md-card>
    <!-- 
      Dialog for editing a password
     -->
    <div>
      <md-dialog :md-active.sync="editPassword" class="cpWindow">
        <md-dialog-title>{{ $t("profile.changePW") }}</md-dialog-title>
        <div class="cpWindow">
          <md-field>
            <label>{{ $t("profile.newPWLabel") }}</label>
            <md-input type="password"></md-input>
          </md-field>
          <md-field>
            <label>{{ $t("profile.confirmPWLabel") }}</label>
            <md-input type="password"></md-input>
          </md-field>
          <md-dialog-actions>
            <md-button class="md-primary" @click="editPassword = false">{{
              $t("global.cancel")
            }}</md-button>
            <md-button
              class="md-primary"
              @click="
                editPassword = false;
                saveUser();
              "
              >{{ $t("global.save") }}</md-button
            ></md-dialog-actions
          >
        </div>
      </md-dialog>
    </div>
    <!-- Dialogs for challenge results & details -->
    <div>
      <!-- challenge dialog -->
      <md-dialog :md-active.sync="showChallengeDialog" class="challengeDialog">
        <md-toolbar id="filter-toolbar" class="md-primary" md-elevation="1">
          <h3 class="md-title" style="flex: 1">
            {{ $t("profile.results.dialogTitle") }}
          </h3>
          <md-button
            class="md-icon-button md-raised"
            id="close-btn"
            :md-ripple="false"
            @click="showChallengeDialog = false"
            ><md-icon>close</md-icon>
          </md-button>
        </md-toolbar>

        <md-table
          class="challengeTable scrollable"
          v-model="challenges"
          md-sort="id"
          md-sort-order="asc"
          md-fixed-header
          @md-selected="openChallenge"
        >
          <md-table-empty-state
            :md-label="$t('profile.results.emptyLabel')"
            :md-description="$t('profile.results.emptyDescription')"
          >
          </md-table-empty-state>

          <md-table-row
            class="challengeTable"
            slot="md-table-row"
            slot-scope="{ item }"
            md-selectable="single"
          >
            <md-table-cell :md-label="$t('admin.table.id')" md-sort-by="id" md-numeric>
              {{ item.id }}
            </md-table-cell>

            <md-table-cell :md-label="$t('admin.table.name')" md-sort-by="name" md-numeric>
              <md-highlight-text
                :md-fuzzy-search="false"
                :md-term="filter.name"
              >
                {{ item.name }}
              </md-highlight-text>
            </md-table-cell>

            <md-table-cell
              :md-label="$t('admin.table.description')"
              md-sort-by="description"
              md-numeric
            >
              <md-highlight-text
                :md-fuzzy-search="false"
                :md-term="filter.name"
              >
                {{ item.description }}
              </md-highlight-text>
            </md-table-cell>

            <md-table-cell :md-label="$t('admin.table.category')" md-sort-by="category" md-numeric>
              <md-highlight-text
                :md-fuzzy-search="false"
                :md-term="filter.category"
              >
                {{ item.category.name }}
              </md-highlight-text>
            </md-table-cell>

            <md-table-cell :md-label="$t('admin.table.tags')" md-sort-by="tags" md-numeric>
              <md-highlight-text
                :md-fuzzy-search="false"
                :md-term="filter.tags"
              >
                {{ item.tags.map((t) => t.name).join(", ") }}
              </md-highlight-text>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <md-toolbar class="ciToolbar" id="browse-pageination" md-elevation="1">
          <div class="md-toolbar-section-end">
            <md-field>
              <md-select
                v-model="pageSize"
                name="pageSize"
                id="browse-pagination-pageSize"
                @md-selectedChallenge="switchTablePage(currentPage)"
              >
                <md-option value="5">5</md-option>
                <md-option value="10">10</md-option>
                <md-option value="25">25</md-option>
                <md-option value="50">50</md-option>
              </md-select>
            </md-field>
            <span style="width: 70px">{{
              currentPage + 1 + " of " + totalPages
            }}</span>
            <md-button
              class="md-icon-button"
              :disabled="currentPage == 0"
              @click="switchTablePage(currentPage - 1)"
            >
              <md-icon>navigate_before</md-icon>
            </md-button>
            <md-button
              class="md-icon-button"
              :disabled="currentPage + 1 >= totalPages"
              @click="switchTablePage(currentPage + 1)"
            >
              <md-icon>navigate_next</md-icon>
            </md-button>
          </div>
        </md-toolbar>
      </md-dialog>
      <!-- result dialog -->
      <md-dialog :md-active.sync="showResults">
        <md-toolbar id="filter-toolbar" class="md-primary" md-elevation="1">
          <h3 class="md-title" style="flex: 1">{{ $t('profile.results.resultsTitle') }}</h3>
          <md-button
            class="md-icon-button md-raised"
            id="close-btn"
            :md-ripple="false"
            @click="(showResults = false), (showChallengeDialog = true)"
            ><md-icon>close</md-icon>
          </md-button>
        </md-toolbar>
        <div>
          <md-list class="md-triple-line scrollable">
            <md-list-item v-for="result in results" v-bind:key="result.id">
              <md-icon v-if="result.passed && !result.trainingMode" class="md-primary">
                check_circle
              </md-icon>
              <md-icon v-if="!result.passed && !result.trainingMode" class="notpassicon">
                cancel
              </md-icon>
              <md-icon v-if="result.trainingMode">
                fitness_center
              </md-icon>

              <div class="md-list-item-text">
                <span>
                  {{ $t('details.cards.resultCorrectAmount', {correct: result.amountCorrect, max: result.amountItems}) }}
                </span>
                <span>{{ result.finishTime | formatDate }}</span>
                <span>{{ $t('details.cards.resultTimeTook') }} {{ result.timeTook | fancytimer }}</span>
              </div>

              <md-button v-if="result.trainingMode" class="md-icon-button md-list-action" @click="showDetailDialog(result)">
                <md-icon>visibility</md-icon>
              </md-button>
            </md-list-item>
          </md-list>
        </div>
      </md-dialog>
      <!-- detail dialog -->
      <md-dialog :md-active.sync="showDetails">
        <md-toolbar
          id="filter-toolbar"
          class="md-primary"
          md-elevation="1"
        >
          <h3 class="md-title" style="flex: 1">
            {{ $t('resultDialog.title') }} 
            <span style="color: rgba(0, 0, 0, 0.3); margin-left: 32px">{{selectedResult != undefined? selectedResult.finishTime : "" | formatDate}}</span>
            <span style="color: rgba(0, 0, 0, 0.3); margin-left: 32px">{{selectedResult != undefined? selectedResult.timeTook : "" | fancytimer}}</span>
          </h3>
          <md-button
            class="md-icon-button md-raised"
            id="close-btn"
            :md-ripple="false"
            @click="(showDetails = false), (showResults = true)"
            ><md-icon>close</md-icon>
          </md-button>
        </md-toolbar>

        <ResultView :result="selectedResult != undefined? selectedResult.id : 0" />
      </md-dialog>
    </div>
  </div>
</template>
<script>
// import configuration with API url; @ refers to the src directory
import config from "@/config";
// import library for HTTP requests
import axios from "axios";

import ResultView from "../components/ResultView.vue";

import { UserRole, stringToRole, roleToLangKey } from "../models/userrole";

export default {
  name: "Profile",
  components: { ResultView },
  data() {
    return {
      user: {},
      editName: false,
      editEMail: false,
      editPassword: false,
      showChallengeDialog: false,
      sending: false,
      lastError: "",
      challenges: [],

      filtered: false,
      filter: {
        name: "",
        category: "",
        tags: "",
        sort: "",
      },

      totalPages: 0,
      currentPage: 0,
      pageSize: 10,

      showDetails: false,
      showResults: false,
      selectedChallenge: undefined,
      selectedResult: undefined,
      challengeID: undefined,
      results: null,
    };
  },
  methods: {
    /**
     * Methods from User Role to convert String and Userroles
     */
    stringToRole,
    roleToLangKey,

    /**
     * Retrieves Information about the User
     */
    getUser: function () {
      axios.get(`${config.apiBaseUrl}/me`).then((response) => {
        console.log(response.data);
        this.user = response.data;
      });
    },

    /**
     * Gets called when the Edit/Save Button of the Username get clicked by the User
     */
    nameEditClosed: function () {
      if (this.editName) this.saveUser();
      this.editName = !this.editName;
    },

    /**
     * Saves new User Information
     */
    saveUser: function () {
      this.sending = true;
      console.log(this.user);

      axios
        .put(`${config.apiBaseUrl}/me/edit`, this.user)
        .then((response) => {
          console.log(response);
          this.sending = false;
        })
        .catch((err) => {
          this.sending = false;

          if (err.response.status == 403) {
            this.lastError = "You don't have rights to edit Users";
          } else {
            this.lastError = "Unknown Error";
          }
        });
    },

    /**
     * Retrieves Challenges, which the User has taken already
     */
    getAllChallenges: function () {
      axios
        .get(`${config.apiBaseUrl}/challenges/results`, {
          params: {
            page: this.currentPage,
            size: this.pageSize,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.challenges = response.data.content;
          this.totalPages = response.data.totalPages;
        });
    },

    /**
     * Opens the Result Dialog with Details about a Result
     */
    openChallenge(item) {
      this.selectedChallenge = item;
      this.getResults();
      this.showResults = true;
      this.showChallengeDialog = false;
    },

    /**
     * Retrieves Results of the User of a Challenge
     */
    getResults: function () {
      axios
        .get(
          `${config.apiBaseUrl}/results/challenge/${this.selectedChallenge.id}`
        )
        .then((response) => {
          console.log(response.data);
          this.results = response.data;
        })
        .catch((err) => {
          console.log(err);
          this.results = [];
        });
    },

    /**
     * Opens the detailed Dialog for a single Result
     */
    showDetailDialog: function (result) {
      this.selectedResult = result;
      this.showResults = false;
      this.showDetails = true;
    },

    /**
     * Switches the Page and retrieves new Challenges
     */

    switchTablePage: function (selectedPage) {
      console.log(selectedPage);
      this.currentPage = selectedPage;
      this.getAllChallenges();
    },
  },
  created: function () {
    this.getUser();
  },
  mounted: function () {
    this.getAllChallenges();
  },
};
</script>

<style lang="scss" scoped>
.profile {
  margin-left: 10px;
  margin-top: 10px;
}

.profileCard {
  min-width: 82%;
  min-height: stretch;
  position: relative;
  margin: 10%;
}

.profileTable {
  text-align: right;
  vertical-align: middle;
}

.header {
  font-size: 20px;
}
.cpWindow {
  min-width: stretch;
  min-height: stretch;
  position: relative;
  margin: 5%;
}
.ciToolbar {
  min-width: fit-content;
}
.scrollable {
  max-height: 600px;
  overflow-y: scroll;
}
.challengeTable {
  margin-bottom: 64px;
}

.logoutbutton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#browse-pageination {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 9;

  box-shadow: 0 -2px 1px -1px rgba(0, 0, 0, 0.2),
    0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 -1px 3px 0 rgba(0, 0, 0, 0.12);

  button {
    margin-left: 16px;
  }

  .md-field {
    width: 100px;
    margin-right: 32px;
  }
}
</style>