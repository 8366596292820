<!--
  Privacy Page of the Website
-->

<template>
  <div class="privacy">
    <h1 class="privacyHeader">{{ $t('privacy.header') }}</h1>
    <h3>{{ $t('privacy.header2') }}</h3>
    <h3>{{ $t('privacy.header3') }}</h3>
  </div>
</template>

<script>
export default {
  name: "Privacy",
  components: {},
  data() {
    return {

    };
  },
  methods: {
  },
};
</script>

<style>
.privacyHeader {
  text-align: center;
  line-height: 1em;
}
.privacy {
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 680px) {
  .privacy {
    width: 95%;
  }
}
</style>
