<!--
  This is the Editor for Challenges
  On this View the User can edit everything about a Challenge including Items and Options
-->

<template>
  <div>
    <div>
      <NewCategory :showDialog="showCategoryDialog" @newCategory="newCategoryCreated" />
      <ImportItem :showDialog="showImportDialog" :itemsExisting="items" @itemImported="itemImported" />
      <EditItem ref="editDialog" @doneEditing="doneEditing" />

      <md-toolbar class="md-primary" md-elevation="1">
        <h3 id="ch-title" class="md-title" style="flex: 1">{{ $t('editor.title', {name: this.challenge.name}) }}</h3>
        <md-button class="md-icon-button md-raised" @click="cancelEdit">
          <md-icon>close</md-icon>
        </md-button>
        <md-button class="md-icon-button md-raised" @click="saveChallenge">
          <md-icon>save</md-icon>
        </md-button>
      </md-toolbar>

      <div id="editorcontent">
        <!-- <form novalidate class="md-layout"> -->
          <div id="editor-form-left">
            <md-field id="editor-form-name" :class="getValidationClass('name')">
              <label for="title">{{ $t('editor.nameLabel') }}</label>
              <md-input
                type="text"
                name="title"
                id="title"
                v-model="challenge.name"
                :disabled="sending"/>
              <span class="md-error" v-if="!$v.form.name.required">{{ $t('editor.nameRequired') }}</span>
            </md-field>

            <md-field id="editor-form-description">
              <label for="description">{{ $t('editor.descriptionLabel') }}</label>
              <md-input
                type="text"
                name="description"
                id="description"
                v-model="challenge.description"
                :disabled="sending"/>
            </md-field>

            <md-autocomplete id="editor-form-category" :class="getValidationClass('category')" v-model="challenge.category" :md-options="categoryNames">
              <label>{{ $t('editor.categoryLabel') }}</label>

              <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                <md-highlight-text :md-term="term">{{item}}</md-highlight-text>
              </template>

              <template slot="md-autocomplete-empty" slot-scope="{ term }">
                {{ $t('editor.categoriesAutocomplete', {term: term}) }}
                <a v-if="hasRole('mod')" @click="showCategoryDialog = true">{{ $t('editor.newCategory') }}</a>
              </template>
              <span class="md-error" v-if="!$v.form.category.required">{{ $t('editor.categoryRequired') }}</span>
            </md-autocomplete>

            <md-chips id="editor-form-tags" v-model="challenge.tags" :md-placeholder="$t('editor.tagPlaceholder')"></md-chips>
          </div>

          <div id="editor-form-center">
            <md-field id="editor-form-visiblefor">
              <label for="visibleFor">{{ $t('editor.visibleForLabel') }}</label>
              <md-select v-model="challenge.visibleFor" name="visibleFor" id="visibleFor">
                <md-option v-if="hasRole('guest')" value="GUEST" key="guest">{{ $t('global.roles.guest') }}</md-option>
                <md-option v-if="hasRole('registered')" value="REGISTERED" key="registered">{{ $t('global.roles.registered') }}</md-option>
                <md-option v-if="hasRole('employee')" value="EMPLOYEE" key="employee">{{ $t('global.roles.employee') }}</md-option>
                <md-option v-if="hasRole('mod')" value="MODERATOR" key="moderator">{{ $t('global.roles.moderator') }}</md-option>
                <md-option v-if="hasRole('admin')" value="ADMINISTRATOR" key="administrator">{{ $t('global.roles.administrator') }}</md-option>
              </md-select>
            </md-field>

            <md-field id="editor-form-passingscore">
              <label>{{ $t('editor.passingScoreLabel') }}</label>
              <md-input v-model="challenge.passLimit" min="0" :max="items.length" type="number" @change="challenge.passLimit = Math.min(items.length, Math.max(challenge.passLimit, 0))"></md-input>
              <span class="md-helper-text">{{ $t('editor.passingScoreHelp') }}</span>
            </md-field>

            <md-field id="editor-form-timelimit">
              <label>{{ $t('editor.timeLimitLabel') }}</label>
              <md-input v-model="challenge.timeLimit" type="number"></md-input>
              <span class="md-suffix">{{ $t('editor.timeLimitSuffix') }}</span>
              <span class="md-helper-text">{{ $t('editor.timeLimitHelp') }}</span>
            </md-field>

            <md-field id="editor-form-repeattimeout">
              <label>{{ $t('editor.repeatTimeoutLabel') }}</label>
              <md-input v-model="challenge.repeatTimeout" type="number"></md-input>
              <span class="md-suffix">{{ $t('editor.repeatTimeoutSuffix') }}</span>
              <span class="md-helper-text">{{ $t('editor.repeatTimeoutHelp') }}</span>
            </md-field>
          </div>

          <div id="editor-form-right">
            <md-switch v-model="challenge.trainingModeAllowed">{{ $t('editor.trainingModeLabel') }}</md-switch>

            <md-switch v-model="challenge.randomItemOrder">{{ $t('editor.randomItemOrderLabel') }}</md-switch>

            <md-switch v-model="challenge.pausingAllowed">{{ $t('editor.pausingLabel') }}</md-switch>

            <div>
              <md-switch :disabled="!hasRole('mod')" v-model="challenge.enabled">{{ $t('editor.enabledLabel') }}</md-switch>
              <md-tooltip v-if="!hasRole('mod')" md-direction="bottom">{{ $t('editor.enabledTooltip') }}</md-tooltip>
            </div>

            <div>
              <md-switch v-model="challenge.embedding">{{ $t('editor.embeddingLabel') }}</md-switch>
              <md-tooltip md-direction="bottom">{{ $t('editor.embeddingTooltip') }}</md-tooltip>
            </div>
            
            <md-field v-if="challenge.embedding" id="editor-form-embeddinglink">
              <label>{{ $t('editor.embeddingLinkLabel') }}</label>
              <md-input :value="getServerHost()" readonly type="text"></md-input>
              <span class="md-helper-text">{{ $t('editor.embeddingLinkHelper') }}</span>
            </md-field>
          </div>
        <!-- </form> -->

        <md-divider id="editor-form-divider"></md-divider>

        <div id="itemselector-dndinfo">
          {{ $t('editor.rearrangeInfo') }}
        </div>

        <md-empty-state
          v-if="items.length == 0"
          id="itemselector-empty"
          md-icon="format_list_bulleted"
          :md-label="$t('editor.empty.title')"
          md-description="">
          <md-button class="md-primary md-raised" @click="addNewItem()">{{ $t('editor.empty.createBtn') }}</md-button>
          <md-button class="md-primary md-raised" @click="showImportDialog = true">{{ $t('editor.empty.importBtn') }}</md-button>
        </md-empty-state>

        <div id="itemselector" v-if="items.length != 0" class="noselect">
            <draggable class="itemselector-wrapper" v-model="items" group="people" draggable=".item" handle=".handle" @start.self="drag=true" @end.self="drag=false">

              <div v-for="(link, index) in items" :key="link.item.id" class="item" style="display: flex">
                <md-icon class="md-size-3x item-arrow">east</md-icon>
                <md-card md-with-hover class="item-card" :class="getItemStateClass(link.item.id)">
                  <md-card-header>
                    <div class="md-title">
                      <vue-simple-markdown style="font-size: inherit;" :source="link != undefined? link.item.question : '' | maxlength(20)"></vue-simple-markdown>
                      <!-- {{link != undefined? link.item.question : "" | maxlength(20)}} -->
                      <md-icon class="handle">menu</md-icon>
                      <md-tooltip v-if="link.item.question != ''" md-direction="bottom"><vue-simple-markdown :source="link.item.question"></vue-simple-markdown></md-tooltip>
                    </div>
                    <div class="md-subhead">
                      <md-icon>{{link != undefined && link.item != undefined? typeToIcon(stringToType(link.item.type)) : ""}}</md-icon> {{$t(typeToLangKey(stringToType(link.item.type)))}}
                    </div>
                  </md-card-header>

                  <md-card-content>
                    <vue-simple-markdown style="font-size: inherit;" :source="link.item.options.map(o => (o != undefined && o.type == 'Image')? 'Image' : (o != undefined? o.data : o)).join(', ') | maxlength(30)"></vue-simple-markdown>
<!--                     {{link.item.options.map(o => (o.type == "Image")? "Image" : o.data).join(", ") | maxlength(30)}}
 -->                  </md-card-content>

                  <md-card-actions>
                    <md-checkbox v-model="link.visibleInTrainingMode">{{ $t('editor.visibleInTrainingLabel') }}</md-checkbox>

                    <div v-if="canEditItem(index)">
                      <md-button class="md-icon-button md-primary" @click="editItem(index)" :disabled="!canEditItem(index)">
                        <md-icon>edit</md-icon>
                      </md-button>
                      <md-tooltip v-if="!canEditItem(index)" md-direction="bottom">{{ $t('editor.noEditTooltip') }}</md-tooltip>
                    </div>
                    <md-button class="md-icon-button md-primary" @click="deleteItem(link.item.id)">
                      <md-icon>{{itemsToDelete.findIndex(i => i === link.item.id) == -1? "delete" : "restore_from_trash"}}</md-icon>
                    </md-button>
                  </md-card-actions>
                </md-card>
              </div>

              <div slot="footer" style="display: flex">
                <md-icon class="md-size-3x item-arrow">east</md-icon>
                <md-card md-with-hover class="item-card">
                  <md-card-header>
                    <div class="md-title">
                      {{ $t('editor.addItemsTitle') }}
                    </div>
                  </md-card-header>

                  <md-card-content style="padding: 0;">
                    <md-button class="md-icon-button newitembtn" @click="addNewItem()">
                      <md-icon class="md-size-3x">add</md-icon>
                    </md-button>
                    <md-button class="md-icon-button newitembtn" @click="showImportDialog = true">
                      <md-icon class="md-size-3x">link</md-icon>
                    </md-button>
                  </md-card-content>
                </md-card>
              </div>
            </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import configuration with API url; @ refers to the src directory
import config from "@/config";
// import library for HTTP requests
import axios from "axios";

import draggable from 'vuedraggable'

import NewCategory from '@/dialogs/NewCategory.vue'
import ImportItem from '@/dialogs/ImportItem.vue'
import EditItem from '@/dialogs/EditItem.vue'

import { UserRole } from '../models/userrole'
import {stringToType, typeToLangKey, typeToIcon} from "../models/itemtype";

import ApiService from "../services/api.service";
import AuthService from "../services/auth.service";

import { validationMixin } from 'vuelidate';
import {
  required
} from 'vuelidate/lib/validators';

export default {
  name: "Editor",
  props: ["id"],
  components: {NewCategory, ImportItem, EditItem, draggable},
  mixins: [validationMixin],
  data() {
    return {
      categories: [],
      categoryNames: [],
      challenge: {
        name: "",
        description: "",
        tags: [],
        category: null,
        visibleFor: UserRole.REGISTERED,
        passLimit: 0,
        timeLimit: 0,
        repeatTimeout: 0,
        trainingModeAllowed: false,
        randomItemOrder: false,
        pausingAllowed: false,
        enabled: false,
      },
      items: [],
      itemsToAdd: [],
      itemsToDelete: [],
      needToDone: 0,
      sending: false,
      showCategoryDialog: false,
      showImportDialog: false,
      showEditDialog: false,
      optionTypes: new Map([
        ["Text", "Text"],
        ["Image", "Image"]
      ]),
      optionToAdd: "",
      infoToAdd: "",
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      category: {
        required
      }
    },
  },
  methods: {
    /**
     * Methods imported from ItemType
     */
    stringToType, 
    typeToLangKey, 
    typeToIcon,
    /**
     * Returns whether the User is logged in
     */
    loggedIn: function() {
      return AuthService.isLoggedIn();
    },

    /**
     * Returns the USername of the logged in User
     */
    username: function() {
      return AuthService.getUsername();
    },

    /**
     * Returns whether the logged in user has a role
     */
    hasRole: function (role) {
      return ApiService.getUserRoles().indexOf(role) != -1;
    },

    /**
     * Returns the URL of this Page used for the Embedding Link
     */
    getServerHost: function() {
      console.log(window.location.protocol  + "//" + window.location.host + "/#/embed/" + this.challenge.id);
      return window.location.protocol  + "//" + window.location.host + "/#/embed/" + this.challenge.id;
    },

    /**
     * Returns the State of an Item of this Challenge
     * Used to indicate deleted or newly added Items of this Challenge
     */
    getItemStateClass(itemid) {
      if (this.itemsToDelete.findIndex(v => v === itemid) != -1) return "deleted";
      else if (this.itemsToAdd.findIndex(v => v === itemid) != -1) return "new";
      else return "";
    },

    /**
     * Validation for Input Fields
     */
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },

    /**
     * Validates Inputs before sending them to the Server
     */
    validateChallenge() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        console.log("submitting");
        this.saveChallenge();
      }
    },

    /**
     * Cancels Editing and returns to the previous Site
     */
    cancelEdit() {
      this.$router.go(-1);
    },

    /**
     * Saves Changes to the Challenge including Items and Options
     */
    saveChallenge: function () {
      this.sending = true;

      var tmp = Object.assign({}, this.challenge);
      var tags = [];
      this.challenge.tags.forEach((tag) => {
        tags.push({ name: tag });
      });
      tmp.tags = tags;
      tmp.category = {
        id: this.categories.find((cat) => cat.name === tmp.category).id,
      };

      console.log(tmp);

      axios.put(`${config.apiBaseUrl}/challenges/${tmp.id}`, tmp).then((response) => {
        console.log(response);
      });

      //Renumbering the OrderIndex of the items
      var index = 0;
      this.items.forEach(i => {
        i.itemOrder = index;
        i.itemID = i.item.id;
        index += 1;
      });

      // Sending Items to Server with separation of new / deleted / updated Items
      this.needToDone = 0;

      this.items.forEach(i => {
        var itmp = Object.assign({}, i);
        console.log(itmp);

        if (this.itemsToAdd.findIndex(v => v == i.item.id) != -1) {
          if (this.itemsToDelete.findIndex(v => v == i.item.id) == -1) {
            console.log("Add Item " + i.item.question + "with id " + i.item.id);
            
            if (i.item.id < 0) {
              axios.post(`${config.apiBaseUrl}/challenges/${tmp.id}/items/new`, itmp).then((response) => {
                console.log(response);
                this.needToDone -= 1;
                this.checkForDone();
              });
            } else {
              axios.post(`${config.apiBaseUrl}/challenges/${tmp.id}/items/add`, itmp).then((response) => {
                console.log(response);
                this.needToDone -= 1;
                this.checkForDone();
              });
            }
            this.needToDone += 1;
          }
        } else if (this.itemsToDelete.findIndex(v => v == i.item.id) != -1) {
          console.log("Remove Item " + i.item.question);
          axios.delete(`${config.apiBaseUrl}/challenges/${tmp.id}/items/${i.item.id}`).then((response) => {
            console.log(response);
            this.needToDone -= 1;
            this.checkForDone();
          });
          this.needToDone += 1;
        } else {
          console.log("Update Item " + i.item.question);

          axios.put(`${config.apiBaseUrl}/challenges/${tmp.id}/items/${i.item.id}`, itmp).then((response) => {
            console.log(response);
            this.needToDone -= 1;
            this.checkForDone();
          });
          this.needToDone += 1;
        }
      });

      if (this.items.length == 0) {
        this.checkForDone();
      }
    },

    /**
     * Check whether all save Requests have finished and if so close the Editor
     */
    checkForDone: function() {
      if (this.sending == false || this.needToDone != 0) return;

      this.sending = false;
      this.cancelEdit();
    },

    /**
     * Callback to listen if a new Category ha been created with the New Category Dialog
     */
    newCategoryCreated: function(value) {
      this.showCategoryDialog = false;

      if (value == undefined) return;

      this.categories.push(value);
      this.categoryNames.push(value.name);
      console.log(value);
      this.challenge.category = value.name;
    },

    /**
     * Retrieves all Categories from the API
     */
    getCategories: function () {
      axios.get(`${config.apiBaseUrl}/categories`).then((response) => {
        console.log(response.data);
        this.categories = response.data;
        this.categoryNames = [];
        this.categories.forEach((cat) => {
          this.categoryNames.push(cat.name);
        });
      });
    },

    /**
     * Retrieves Challenge Information from the API
     */
    getChallenge: function () {
      axios.get(`${config.apiBaseUrl}/challenges/${this.id}`).then((response) => {
        console.log(response.data);
        this.challenge = response.data.challenge;
        this.challenge.category = response.data.challenge.category.name;

        var tags = [];
        response.data.challenge.tags.forEach(tag => {
          tags.push(tag.name);
        });
        this.challenge.tags = tags;
        this.getChallengeItems();
      });
    },

    /**
     * Retrieves all Items from this Challenge from the API
     */
    getChallengeItems: function () {
      axios.get(`${config.apiBaseUrl}/challenges/${this.id}/items`).then((response) => {
        console.log(response.data);
      
        var i = Object.assign([], response.data);

        i.sort(function(a, b){return a.itemOrder - b.itemOrder});
        i.map(item => {
          console.log(item.item.type);
          if (item.item.type == "CORRECT_ORDER") item.item.options.sort(function(a, b){return a.optionOrder - b.optionOrder});
          return item;
        });

        this.items = i;

        this.challenge.passLimit = Math.min(this.items.length, Math.max(this.challenge.passLimit, 0))
      });
    },

    /**
     * Returns true if the User can Edit the given Item
     */
    canEditItem: function(item) {
      return this.items[item].item.creatorName == this.username() || this.hasRole("mod");
    },

    /**
     * Opens the Item Editor for the given item, if the User can edit it
     */
    editItem: function (item){
      if (this.items[item] == undefined) return;
      if (this.items[item].item.creatorName == this.username() || this.hasRole("mod")) this.$refs.editDialog.openDialog(Object.assign({}, this.items[item].item));
    },

    /**
     * Gets called if a new Item got imported
     */
    itemImported: function(importItems) {
      this.showImportDialog = false;
      console.log(importItems);

      importItems.forEach(item => {
        if (this.items.findIndex(i => i.item.id === item.id) == -1) {

          this.items.push({itemOrder: this.items.length, item: item});
          this.itemsToAdd.push(item.id);

          if (this.challenge.passLimit == this.items.length - 1) this.challenge.passLimit = this.items.length;
        }
      });
    },
    
    /**
     * Adds a new Item to the Challenge
     */
    addNewItem: function () {
      console.log("Adding new Item");

      var newId = -100;
      do {
        newId = Math.floor(Math.random() * -1000000);
      } while (this.itemsToAdd.findIndex(v => v.id === newId) != -1);

      this.items.push({itemOrder: this.items.length, item: {id: newId, type: "New", question: "", questionImageUrl: "", tags: [], options: [], moreInformation: []}});
      this.itemsToAdd.push(newId);

      this.$refs.editDialog.openDialog(Object.assign({}, this.items.find(l => l.item.id == newId).item));

      if (this.challenge.passLimit == this.items.length - 1) this.challenge.passLimit = this.items.length;
    },

    /**
     * Deletes an Item form this Challenge
     */
    deleteItem: function (itemid) {
      console.log("Deleting Item " + itemid);
      var i = this.items.findIndex(v => v.item.id === itemid);
      if (i == -1) return;

      if (this.itemsToDelete.includes(itemid)) {
        this.itemsToDelete.splice(this.itemsToDelete.findIndex(i => i === itemid), 1);
      } else {
        this.itemsToDelete.push(itemid);
      }

      console.log(this.items);
      console.log(this.itemsToAdd);
    },

    /**
     * Gets called when the Item Editor closes
     */
    doneEditing: function(item) {
      console.log(item);
      if (item == undefined) {
        axios.get(`${config.apiBaseUrl}/challenges/${this.id}/items`).then((response) => {
          console.log(response.data);
        
          var i = Object.assign([], response.data);

          i.sort(function(a, b){return a.itemOrder - b.itemOrder});
          i.map(item => {
            console.log(item.item.type);
            if (item.item.type == "CORRECT_ORDER") item.item.options.sort(function(a, b){return a.optionOrder - b.optionOrder});
            return item;
          });

          i.forEach(i => {
            this.items.splice(this.items.findIndex(item => item.id === i.id), 1, i);
          });
        });
      } else {
        var link = this.items.find(l => l.item.id == item.id);
        link.item = item;
      }
    },

    /**
     * Listener for Authentication Changes
     */
    authCallback: function() {
      this.$forceUpdate();
    },
  },
  
  destroyed: function() {
    AuthService.unregisterCallback(this.authCallback); 
  },
  // executed after the component has been started
  mounted: function () {
    AuthService.registerCallback(this.authCallback);
    
    this.getCategories();
    this.getChallenge();
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding: 0;
}

#ch-title {
  font-weight: 100;
}

#editorcontent {
  width: 100%;
  padding: 10px;

  display: grid;
  grid-template-columns: 33% 34% 33%;
  grid-template-rows: auto auto auto 19px auto auto;
}

#editor-form-left {
  grid-column: 1 / 2;
  grid-row: 1 / 2;

  padding-right: 4px;
}

#editor-form-center {
  grid-column: 2 / 3;
  grid-row: 1 / 2;

  padding-left: 4px;
  padding-right: 4px;
}

#editor-form-right {
  grid-column: 3 / 4;
  grid-row: 1 / 2;

  padding-left: 4px;
  padding-right: 4px;

  .md-switch {
    width: 100%;
  }
}

.handle {
  float: right;
  position: absolute;
  top: 8px;
  right: 8px;
}

@media only screen and (max-width: 680px) {
  #editor-form-left {
    grid-column: 1 / 5;
    grid-row: 1 / 2;
  }

  #editor-form-center {
    grid-column: 1 / 5;
    grid-row: 2 / 3;
  }

  #editor-form-right {
    grid-column: 1 / 5;
    grid-row: 3 / 4;
  }
}

#editor-form-divider {
  grid-column: 1 / 5;
  grid-row: 4 / 5;
}

#itemselector-dndinfo {
  grid-column: 1 / 5;
  grid-row: 5 / 6;


}

#itemselector-empty {
  grid-column: 1 / 5;
  grid-row: 6 / 7;

  max-width: 100%;
}

#itemselector {
  grid-column: 1 / 5;
  grid-row: 6 / 7;
}

.itemselector-wrapper {
  width: 100%;
  display: grid;
/*   box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  border-radius: 4px; */
  grid-template-columns: repeat(auto-fit, 338px);
  margin-bottom: 32px;
}

.newitembtn {
  width: 116px !important;
  height: 116px !important;
}

.new {
  background-color: rgba($primary, 0.2) !important;
}

.deleted {
  background-color: rgba(244, 67, 54, 0.2) !important;
}
</style>
