<!--
  This is the main admin page. 
  Here the admin can manage users, challenges and other things.
-->


<template>
  <div id="admin">
    <div id="adminmenuL" v-responsive="['hidden-all', 'lg', 'xl']">
      <div id="menutitle">{{ $t('admin.menutitle') }}</div>

      <md-list>
        <md-list-item :class="page == 'challenges' ? 'selected' : ''">
          <md-icon>assignment</md-icon>
          <span class="adminmenubtn" @click="switchPage('challenges')">{{ $t('admin.menu.challenges') }}</span>
        </md-list-item>

        <md-list-item :class="page == 'items' ? 'selected' : ''">
          <md-icon>list</md-icon>
          <span class="adminmenubtn" @click="switchPage('items')">{{ $t('admin.menu.items') }}</span>
        </md-list-item>

        <md-list-item :class="page == 'categories' ? 'selected' : ''">
          <md-icon>label</md-icon>
          <span class="adminmenubtn" @click="switchPage('categories')">{{ $t('admin.menu.categories') }}</span>
        </md-list-item>

        <md-list-item :class="page == 'users' ? 'selected' : ''">
          <md-icon>supervised_user_circle</md-icon>
          <span class="adminmenubtn" @click="switchPage('users')">{{ $t('admin.menu.users') }}</span>
        </md-list-item>
      </md-list>
    </div>
    <div v-responsive="['hidden-all', 'xs', 'sm', 'md']" id="adminmenuS">
      <md-drawer class="md-drawer" :md-active.sync="menuVisible" md-swipeable>
        <md-toolbar class="md-transparent" md-elevation="0">
          <span class="md-title">{{ $t('admin.menutitle') }}</span>
        </md-toolbar>

        <md-list>
          <md-list-item :class="page == 'challenges' ? 'selected' : ''">
            <md-icon>assignment</md-icon>
            <span class="md-list-item-text" @click="switchPage('challenges')">{{ $t('admin.menu.challenges') }}</span>
          </md-list-item>

          <md-list-item :class="page == 'items' ? 'selected' : ''">
            <md-icon>list</md-icon>
            <span class="md-list-item-text" @click="switchPage('items')">{{ $t('admin.menu.items') }}</span>
          </md-list-item>

          <md-list-item :class="page == 'categories' ? 'selected' : ''">
            <md-icon>label</md-icon>
            <span class="md-list-item-text" @click="switchPage('categories')">{{ $t('admin.menu.categories') }}</span>
          </md-list-item>

          <md-list-item :class="page == 'users' ? 'selected' : ''">
            <md-icon>supervised_user_circle</md-icon>
            <span class="md-list-item-text" @click="switchPage('users')">{{ $t('admin.menu.users') }}</span>
          </md-list-item>
        </md-list>
      </md-drawer>
    </div>
    <div id="admin-pages">
      <!-- home page tab of admin page -->
      <div id="page-home" class="page" v-if="page == 'home'">
        <p class="homeText">
          {{ $t('admin.home') }}
        </p>
      </div>

      <!-- challenge tab of the admin page -->
      <div id="page-challenges" class="page" v-if="page == 'challenges'">
        <AdminChallenges @menuclick="menuVisible = !menuVisible"/>
      </div>

      <!-- Items Page -->
      <div id="page-items" class="page" v-if="page == 'items'">
        <AdminItems @menuclick="menuVisible = !menuVisible"/>
      </div>

      <!-- Categories and Tags Page  -->
      <div id="page-categories" class="page" v-if="page == 'categories'">
        <AdminCategories @menuclick="menuVisible = !menuVisible"/>
      </div>

      <!-- users tab of admin page -->
      <div id="page-users" class="page" v-if="page == 'users'">
        <AdminUsers @menuclick="menuVisible = !menuVisible"/>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "../services/auth.service";

import AdminUsers from "../components/AdminUsers.vue";
import AdminChallenges from "../components/AdminChallenges.vue";
import AdminItems from "../components/AdminItems.vue";
import AdminCategories from "../components/AdminCategories.vue";

export default {
  name: "Reveal",
  components: { AdminUsers, AdminChallenges, AdminItems, AdminCategories },
  data: () => ({
    menuVisible: false,
    page: "challenges",
  }),
  mounted: function () {
    if (!AuthService.isLoggedIn()) {
      this.$router.push({
        path: "/home/",
      });
    }
  },
  methods: {
    /**
     * Switch Page of Content on Admin Panel
     */
    switchPage: function (page) {
      this.page = page;
      this.menuVisible = !this.menuVisible;
    },
    /**
     * Listener for Authentication Changes
     */
    authCallback: function () {
      this.$forceUpdate();
      if (!AuthService.isLoggedIn()) {
        this.$router.push({
          path: "/home/",
        });
      }
    },
  },

  destroyed: function () {
    AuthService.unregisterCallback(this.authCallback);
  },
  // executed after the component has been started
  created: function () {
    AuthService.registerCallback(this.authCallback);
  },
};
</script>
<style lang="scss" scoped>
/* .md-app {
  border: 1px solid rgba(#000, 0.12);
  height: 800px;
}
*/
.main {
  padding: 0;
}
.md-drawer {
  width: 230px;
  max-width: calc(100vw - 125px);
}

.md-highlight-text-match {
  color: $md-theme-default-primary-on-background;
}

#admin {
  display: flex;
  height: 100%;
}

#adminmenuL {
  width: 350px;

  border-right: 1px solid rgba($primary, 0.9);
  /*   background-color: rgba(0, 0, 0, 0.2);
 */ /*   box-shadow: 3px 0 1px -2px rgba(0, 0, 0, 0.2), 
              2px 0 2px 0 rgba(0, 0, 0, 0.14),
              1px 0 5px 0 rgba(0, 0, 0, 0.12); */

  .md-list-item {
    background-color: transparent !important;
    transition: 0.3s;
  }

  .md-list-item.selected {
    background-color: rgba($primary, 0.2) !important;
  }

  .md-list-item-content {
    justify-content: left !important;
  }
}

#menutitle {
  height: 65px;
  font-size: 30px;
  line-height: 65px;

  justify-content: center;

  text-align: center;

  background-color: $primary;

  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.page {
  height: 100%;
}

#admin-pages {
  width: 100%;
}

.home {
  font-size: 20px;
}

.homeText {
  font-size: 20px;
}

.users {
  font-size: 10px;
  height: 100%;
  width: 100%;
}

.challenges {
  font-size: 10px;
}

.md-table {
  /* width: 100%; */
}

.profile {
  font-size: 10px;
}

.adminmenubtn {
  justify-content: left;
  font-size: 23px;
}
.adminmenubtn:hover {
  color: #84bd00;
  cursor: pointer;
}
.md-list-item-text {
  justify-content: left;
  font-size: 23px;
}
.md-list-item-text:hover {
  color: #84bd00;
  cursor: pointer;
}

</style>