<!--
  Component that displays details about a Result
-->

<template>
  <div>
    <div id="logoheader">
      <div id="logowrapper">
        <img
          alt="iCC logo"
          src="../assets/ic_consult_logo_rgb.png"
          id="small-logo"
        />
      </div>
    </div>

    <div v-if="!showTakeView && !showError" id="detailscontent">
      <div id="details-chname">
        <div style="float: left; margin-right: 64px">
          {{ challenge.name }}
        </div>
        <div id="details-chcat" style="color: rgba(0, 0, 0, 0.3)">
          {{ challenge.category }}
        </div>
      </div>
      <div id="details-chtags">
        <md-chip v-for="tag in challenge.tags" :key="tag.id">{{
          tag.name
        }}</md-chip>
      </div>

      <div id="details-chdesc">
        {{ challenge.description }}
      </div>

      <div id="details-info">

        <md-card id="card-start" style="width: 100%">
          <md-card-content>
            <div style="width: fit-content; margin-left: auto; margin-right: auto;">
              <md-button class="md-raised md-primary" :disabled="itemAmount <= 0" @click="startChallenge">{{ $t('details.cards.startBtnText') }}</md-button>
            </div>
          </md-card-content>
        </md-card>

        <md-card style="width: 100%">
          <md-card-header>
            <div class="md-title">{{ $t('details.cards.infoTitle') }}</div>
          </md-card-header>

          <md-card-content>
            <md-list>
              <md-list-item v-if="!challenge.enabled">
                <md-icon v-if="challenge.enabled" class="md-primary"
                  >check_circle</md-icon
                >
                <md-icon v-if="!challenge.enabled" class=""
                  >radio_button_unchecked</md-icon
                >

                <span class="md-list-item-text">
                  {{ $t('details.cards.notEnabled') }}
                </span>
              </md-list-item>

              <md-list-item>
                <md-icon v-if="challenge.trainingModeAllowed" class="md-primary"
                  >check_circle</md-icon
                >
                <md-icon v-if="!challenge.trainingModeAllowed" class=""
                  >radio_button_unchecked</md-icon
                >

                <span class="md-list-item-text">
                  {{ $t(challenge.trainingModeAllowed? 'details.cards.trainingMode' : 'details.cards.noTrainingMode') }}
                </span>
              </md-list-item>

              <md-list-item>
                <md-icon v-if="challenge.pausingAllowed" class="md-primary"
                  >check_circle</md-icon
                >
                <md-icon v-if="!challenge.pausingAllowed" class=""
                  >radio_button_unchecked</md-icon
                >

                <span class="md-list-item-text">
                  {{ $t(challenge.pausingAllowed? 'details.cards.pausing' : 'details.cards.noPausing') }}
                </span>
              </md-list-item>

              <md-list-item>
                <md-icon>list</md-icon>

                <span class="md-list-item-text">{{ $t('details.cards.noOfItems', {amount: itemAmount}) }}
                  </span>
              </md-list-item>

              <md-list-item>
                <md-icon v-if="challenge.timeLimit != 0" class="md-primary">timer</md-icon>
                <md-icon v-if="challenge.timeLimit == 0" class="">timer_off</md-icon>

                <span v-if="challenge.timeLimit == 0" class="md-list-item-text">{{ $t('details.cards.timeLimitInf') }}</span>
                <span v-if="challenge.timeLimit > 0" class="md-list-item-text">{{ $t('details.cards.timeLimit') }} {{ challenge.timeLimit | fancytimer }}</span>
              </md-list-item>

              <md-list-item>
                <md-icon v-if="challenge.repeatTimeout > -1">repeat</md-icon>
                <md-icon v-if="challenge.repeatTimeout == -1">repeat_one</md-icon>

                <span
                  v-if="challenge.repeatTimeout > -1"
                  class="md-list-item-text">
                  {{ $t('details.cards.repeatTimeout') }}
                  {{ challenge.repeatTimeout | fancytimer }}
                </span>
                <span
                  v-if="challenge.repeatTimeout == -1"
                  class="md-list-item-text">
                  {{ $t('details.cards.repeatTimeoutOnce') }}
                </span>
              </md-list-item>
            </md-list>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <div v-if="showError" id="errorcontent">
      {{lastError}}
    </div>
    
    <ChallengeTakeView v-if="showTakeView" :id="id" :train="true" :embed="true"/>
  </div>
</template>

<script>
// import configuration with API url; @ refers to the src directory
import config from "@/config";
// import library for HTTP requests
import axios from "axios";

import ChallengeTakeView from './ChallengeTakeView.vue'

export default {
  name: "EmbeddedView",
  components: {ChallengeTakeView},
  data() {
    return {
      showTakeView: false,
      lastError: "",
      showError: false,
      challenge: {},
      itemAmount: 0,

    };
  },
  props: ["id"],
  methods: {
   /**
     * Retrieves Challenge Information from the API
     */
    getChallenge: function () {
      axios
        .get(`${config.apiBaseUrl}/challenges/${this.id}`)
        .then((response) => {
          console.log(response.data);
          this.challenge = response.data.challenge;
          this.challenge.category = response.data.challenge.category.name;
          this.itemAmount = response.data.itemAmount;

          if (!this.challenge.embedding) {
            this.lastError = "This Challenge is not eligible for embedding";
            this.showError = true;
          }
        })
        .catch((err) => {
          if (err.response.status == 403) {
            this.lastError = "You don't have access to this Challenge";
          } else if (err.response.status == 404) {
            this.lastError = "This Challenges does not exist";
          } else {
            this.lastError = "Unknown Error";
          }

          this.showError = true;
        });
    },
    
    /**
     * Starts the Challenge and opens the ChallengeTakeView
     */
    startChallenge: function() {
      this.showTakeView = true;
    },
  },

  mounted: function() {
    this.getChallenge();
  },
};
</script>

<style lang="scss" scoped>
#logoheader {
  height: 80px;
  width: 100%;

  padding: 10px;

  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

  #logowrapper {
    width: fit-content;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    object-fit: contain;
    height: 100%;
    width: fit-content;
  }
}

#errorcontent {
  width: 100%;

  padding-top: 64px;

  font-size: 30px;
  text-align: center;
}

#detailscontent {
  display: grid;
  grid-template-columns: auto 366px;
  grid-template-rows: auto auto auto;

  padding: 10px;
  width: calc(100% - 20px);
}

#details-chname {
  grid-column: 1 / 2;
  grid-row: 1 / 2;

  font-size: 35px;
  line-height: 35px;

  margin-bottom: 16px;
}

#details-chtags {
  grid-column: 1 / 2;
  grid-row: 2 / 3;

  margin-bottom: 32px;
}

#details-chdesc {
  grid-column: 1 / 2;
  grid-row: 3 / 4;

  font-size: 20px;
  line-height: 20px;
}

#details-info {
  .md-card {
    min-width: 150px;
    max-width: 350px;
  }

  grid-column: 2 / 3;
  grid-row: 1 / 5;
}

@media only screen and (max-width: 800px) {
  #card-start {
    margin-top: 64px;
  }

  #details-chname {
    font-size: 25px;
    line-height: 25px;
  }

  #details-chdesc {
    font-size: 20px;
    line-height: 20px;
  }
  
  #details-info {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }
}
</style>
