<!--
  Dialog with Items to select one for Import
-->

<template>
  <div>
    <md-toolbar
      id="filter-toolbar"
      class="md-primary"
      md-elevation="1"
      @keydown.enter="applyFilter"
    >
      <md-button
        class="md-icon-button"
        @click="menuClicked"
        v-responsive="['hidden-all', 'xs', 'sm', 'md']"
        id="adminmenuS">
        <md-icon>menu</md-icon>
      </md-button>


      <!--Filter for Name-->
      <md-autocomplete
        id="autoName"
        class="filter-field"
        style="max-width: 500px"
        v-model="usersFilter"
        :md-options="userNames()"
        md-layout="box"
        :md-fuzzy-search="false"
        :md-open-on-focus="false"
        data-lpignore
        @input="filterUsers"
      >
        <label>{{ $t('admin.users.search') }}</label>

        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
          <md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
        </template>

        <template slot="md-autocomplete-empty" slot-scope="{ term }">
          {{ $t('admin.users.searchAutocomplete', {term: term}) }}
        </template>
      </md-autocomplete>
      <div class="md-toolbar-section-end">
        <md-button class="admintoolbutton" @click="showDialogNewUser = true"
          >{{ $t('admin.users.newUserBtn') }}</md-button
        >
      </div>
    </md-toolbar>

    <!-- table for showing users -->
    <md-table
      class="usersTable"
      v-model="usersSearched"
      md-sort="id"
      md-sort-order="asc"
      md-fixed-header
    >
      <md-table-empty-state
        :md-label="$t('admin.users.empty.label')"
        :md-description="$t(!usersFilter == ''? 'admin.users.empty.description' : 'admin.users.empty.description2')">
        <md-button v-if="!usersFilter == ''" class="md-primary md-raised" @click="clearFilter()">
          {{ $t('admin.users.empty.clearBtn') }}
        </md-button>

        <md-button v-if="usersFilter == ''" class="md-primary md-raised" @click="showDialogNewUser = true">
          {{ $t('admin.users.empty.createBtn') }}
        </md-button>
      </md-table-empty-state>

      <md-table-row
        class="usersTable"
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="single"
      >
        <md-table-cell :md-label="$t('admin.table.id')" md-sort-by="id" md-numeric>{{
          item.id
        }}</md-table-cell>

        <md-table-cell :md-label="$t('admin.table.role')" md-sort-by="role">
          <md-menu md-size="auto" md-align-trigger>
            <md-button md-menu-trigger>{{ item.role }}</md-button>

            <md-menu-content>
              <md-menu-item>ADMINISTRATOR</md-menu-item>
              <md-menu-item>EMPLOYEE</md-menu-item>
              <md-menu-item>VISITOR</md-menu-item>
            </md-menu-content>
          </md-menu>
        </md-table-cell>

        <md-table-cell :md-label="$t('admin.table.username')" md-sort-by="username">
          <md-highlight-text :md-term="usersFilter">{{
            item.username
          }}</md-highlight-text>
        </md-table-cell>
        <md-table-cell :md-label="$t('admin.table.email')" md-sort-by="email">
          <md-highlight-text :md-term="usersFilter">{{
            item.email
          }}</md-highlight-text>
        </md-table-cell>

        <md-table-cell :md-label="$t('global.edit')">
          <md-button
            class="md-icon-button md-accent"
            @click="openEditDialog(item)"
          >
            <md-icon>edit</md-icon>
          </md-button>
        </md-table-cell>

        <md-table-cell :md-label="$t('global.delete')">
          <md-button
            class="md-icon-button md-accent"
            @click="openDeleteDialog(item)"
          >
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <!-- Delete Category Dialog -->
    <md-dialog :md-active.sync="showDialogDeleteUser">
      <md-dialog-title>
        {{ $t('global.deleteDialog.title', {type: "User: " + (deletedUser != undefined ? deletedUser.username : "")}) }}
      </md-dialog-title>

      <md-dialog-actions>
        <md-button class="md-primary md-accent" @click="deleteUser()">{{ $t('global.delete') }}</md-button>
        <md-button
          class="md-primary"
          @click="
            showDialogDeleteUser = false;
            deletedUser = undefined;
          ">
          {{ $t('global.cancel') }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <!-- new user dialog -->
    <md-dialog :md-active.sync="showDialogNewUser">
      <md-dialog-title>
        {{ $t(editUser != undefined ? 'admin.users.editUser.editTitle' : 'admin.users.editUser.newTitle', {name: (editUser != undefined ? editUser.username : "")}) }}
      </md-dialog-title>

      <form novalidate class="md-layout" style="padding: 24px">
        <md-field :class="getValidationClass('username')">
          <label for="username">{{ $t('admin.users.editUser.usernameLabel') }}</label>
          <md-input
            type="text"
            name="username"
            id="username"
            v-model="form.username"
            :disabled="sending"
          />
          <span class="md-error" v-if="!$v.form.username.required"
            >{{ $t('admin.users.editUser.usernameRequired') }}</span
          >
          <span class="md-error" v-if="!$v.form.username.isUnique"
            >{{ $t('admin.users.editUser.usernameTaken') }}</span
          >
        </md-field>

        <md-field :class="getValidationClass('password')">
          <label for="password">{{ $t('admin.users.editUser.passwordLabel') }}</label>
          <md-input
            type="password"
            name="password"
            id="password"
            v-model="form.password"
            :disabled="sending"
          />
          <span class="md-error" v-if="!$v.form.password.required"
            >{{ $t('admin.users.editUser.passwordRequired') }}</span
          >
        </md-field>

        <md-field :class="getValidationClass('email')">
          <label for="email">{{ $t('admin.users.editUser.emailLabel') }}</label>
          <md-input
            type="text"
            name="email"
            id="email"
            v-model="form.email"
            :disabled="sending"
          />
          <span class="md-error" v-if="!$v.form.email.required"
            >{{ $t('admin.users.editUser.emailRequired') }}</span
          >
          <span class="md-error" v-if="!$v.form.email.email"
            >{{ $t('admin.users.editUser.emailInvalid') }}</span
          >
          <span class="md-error" v-if="!$v.form.email.isUnique"
            >{{ $t('admin.users.editUser.emailTaken') }}</span
          >
        </md-field>

        <md-field :class="getValidationClass('role')">
          <label for="role">{{ $t('admin.users.editUser.roleLabel') }}</label>
          <md-select v-model="form.role" name="role" id="role">
            <md-option v-if="hasRole('guest')" value="GUEST" key="guest" >{{ $t('global.roles.guest') }}</md-option >
            <md-option v-if="hasRole('registered')" value="REGISTERED" key="registered" >{{ $t('global.roles.registered') }}</md-option >
            <md-option v-if="hasRole('employee')" value="EMPLOYEE" key="employee" >{{ $t('global.roles.employee') }}</md-option >
            <md-option v-if="hasRole('mod')" value="MODERATOR" key="moderator" >{{ $t('global.roles.moderator') }}</md-option >
            <md-option v-if="hasRole('admin')" value="ADMINISTRATOR" key="administrator" >{{ $t('global.roles.administrator') }}</md-option >
          </md-select>
          <span class="md-error" v-if="!$v.form.role.required" >{{ $t('admin.users.editUser.roleRequired') }}</span >
        </md-field>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />
        <span class="md-error" v-if="lastError != ''">{{ lastError }}</span>
      </form>

      <md-dialog-actions>
        <md-button
          class="md-primary"
          v-on:click="showDialogNewUser = false; clearForm();"
          :disabled="sending"
          >{{ $t('global.cancel') }}</md-button
        >
        <md-button
          class="md-primary"
          v-on:click="validateUser()"
          :disabled="sending"
          >{{ $t(editUser != undefined ? "global.save" : "global.create") }}</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
// import configuration with API url; @ refers to the src directory
import config from "@/config";
// import library for HTTP requests
import axios from "axios";

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import ApiService from "../services/api.service";

export default {
  name: "AdminUsers",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        username: "",
        email: "",
        password: "",
        role: "REGISTERED",
      },
      users: [],
      usersSearched: [],
      usersFilter: "",
      showDialogDeleteUser: false,
      showDialogNewUser: false,
      //newUserId: -1,
      sending: false,
      lastError: "",
      deletedUser: undefined,
      editUser: undefined,
      oldData: {
        username: "",
        email: "",
      },
    };
  },
  props: [],
  validations() {
    //if (this.editUser == undefined) {
    return {
      form: {
        username: {
          required,
          isUnique(value) {
            if (value == "") return true;
            if (this.editUser != undefined && this.editUser.username == value)
              return true;

            return new Promise((resolve) => {
              axios
                .get(`${config.apiBaseUrl}/free/username/` + value)
                .then((response) => {
                  console.log(response.data);
                  resolve(response.data.free);
                });
            });
          },
        },
        email: {
          required,
          email,
          isUnique(value) {
            if (value == "") return true;
            if (this.editUser != undefined && this.editUser.email == value)
              return true;

            return new Promise((resolve) => {
              axios
                .get(`${config.apiBaseUrl}/free/email/` + value)
                .then((response) => {
                  console.log(response.data);
                  resolve(response.data.free);
                });
            });
          },
        },
        password: {
          required(value) {
            if (this.editUser != undefined) return true;
            else return value != "";
          },
        },
        role: {
          required,
        },
      },
    };
  },
  methods: {
    /**
     * Returns whether the logged in user has a role
     */
    hasRole: function (role) {
      return ApiService.getUserRoles().indexOf(role) != -1;
    },

    /**
     * Returns a class corresponding to the validness of the given Input
     */
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },

    /**
     * Retrieves all Users from the API
     */
    getAllUsers: function () {
      axios.get(`${config.apiBaseUrl}/users`).then((response) => {
        console.log(response.data);
        this.users = response.data;
        this.filterUsers();
      });
    },

    /**
     * Filters Users with the Text Filter Input
     */
    filterUsers: function () {
      if (this.usersFilter == "") {
        this.usersSearched = this.users;
      } else {
        this.usersSearched = this.users.filter(
          (u) =>
            u.username.indexOf(this.usersFilter) != -1 ||
            u.email.indexOf(this.usersFilter) != -1
        );
      }
    },

    /**
     * Returns an Array of all User Names
     * Used to Auto Fill the Name Search Box
     */
    userNames: function () {
      var n = [];
      if (Array.isArray(this.users))
        this.users.forEach((ch) => {
          n.push(ch.username);
          n.push(ch.email);
        });
      return n;
    },

    /**
     * Validates Inputs before sending them to the Server
     */
    validateUser() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        this.saveUser();
      }
    },

    /**
     * Saves the Changes made to a User on the Edit Dialog
     */
    saveUser: function () {
      this.sending = true;
      console.log(this.form);

      if (this.editUser == undefined) {
        axios
          .post(`${config.apiBaseUrl}/users`, this.form)
          .then((response) => {
            console.log(response);
            this.sending = false;
            this.showDialogNewUser = false;
            this.clearForm();
            this.getAllUsers();
          })
          .catch((err) => {
            this.sending = false;

            if (err.response.status == 403) {
              this.lastError = "You don't have rights to create new Users";
            } else {
              this.lastError = "Unknown Error";
            }
          });
      } else {
        axios
          .put(`${config.apiBaseUrl}/users/` + this.editUser.id, this.form)
          .then((response) => {
            console.log(response);
            this.sending = false;
            this.showDialogNewUser = false;
            this.editUser = undefined;
            this.clearForm();
            this.getAllUsers();
          })
          .catch((err) => {
            this.sending = false;

            if (err.response.status == 403) {
              this.lastError = "You don't have rights to edit Users";
            } else {
              this.lastError = "Unknown Error";
            }
          });
      }
    },

    /**
     * Clears the User Edit / Create Dialog Form
     */
    clearForm: function () {
      this.form.username = "";
      this.form.password = "";
      this.form.email = "";
      this.form.role = "GUEST";
    },

    /**
     * Opens Deletion Confirmation of a User
     */
    openDeleteDialog: function (user) {
      this.deletedUser = user;
      this.showDialogDeleteUser = true;
    },

    /**
     * Deletes a User from the API
     */
    deleteUser: function () {
      axios
        .delete(`${config.apiBaseUrl}/users/` + this.deletedUser.id)
        .then(() => {
          this.deletedUser = undefined;
          this.showDialogDeleteUser = false;
          this.getAllUsers();
        });
    },

    /**
     * Opens the Edit Dialog of a User
     */
    openEditDialog: function (user) {
      this.editUser = user;
      this.form.username = user.username;
      this.form.password = "";
      this.form.email = user.email;
      this.form.role = user.role;

      this.showDialogNewUser = true;
    },

    /**
     * Called when a User presses the Menu Icon in the top left corner
     */
    menuClicked: function () {
      this.$emit("menuclick");
    },
  },
  mounted: function () {
    this.getAllUsers();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .md-table {

  .md-table-content {
    height: fit-content !important;
    max-height: 100% !important;
  }
}

::v-deep .md-highlight-text-match {
  color: $md-theme-default-primary-on-background;
}

#title-users {
  font-size: 25px;
}
</style>
