// main JS file for instantiating the application

import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
/*import 'vue-material/dist/theme/default.css'*/
import responsive from 'vue-responsive';

import VueSimpleMarkdown from 'vue-simple-markdown'
import 'vue-simple-markdown/dist/vue-simple-markdown.css'

import ApiService from './services/api.service';
import AuthService from './services/auth.service';

import i18n from './i18n';

AuthService.readData();
if (AuthService.getToken()) {
  ApiService.loadToken();
}

Vue.use(VueSimpleMarkdown)
Vue.use(VueMaterial);
Vue.use(responsive);

Vue.config.productionTip = false;

import moment from 'moment';

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm');
  }
});

Vue.filter('maxlength', function(value, maxlength) {
  if (value.length > maxlength) {
    return value.substring(0, maxlength - 2) + "...";
  } else {
    return value;
  }
});

Vue.filter('fancytimer', function(value) {
  var isNegative = value < 0;
  if (isNegative) value = -value;

  var hours = Math.floor(value / 3600);
  value -= hours * 3600;

  var mins = Math.floor(value / 60);
  value -= mins * 60;

  return (isNegative? "-" : "") + (hours < 10? "0" + hours : hours) + ":" + (mins < 10? "0" + mins : mins) + ":" + (value < 10? "0" + value : value);
});

new Vue({
  i18n,
  router,
  render: (h) => h(App)
}).$mount("#app");

