/**
 * Enum containing tha avilable User Roles a User can have
 */
const UserRole = {
    GUEST: 0,
    REGISTERED: 1,
    EMPLOYEE: 2,
    MODERATOR: 3,
    ADMINISTRATOR: 4
}

/**
 * Converts the String to an User Role
 * If the String does not correspond with a Role, the default Role is Guest
 * @param {String} string 
 */
function stringToRole(string) {
    if (string === "admin") {
        return UserRole.ADMINISTRATOR;
    } else if (string === "mod") {
        return UserRole.MODERATOR;
    } else if (string === "employee") {
        return UserRole.EMPLOYEE;
    } else if (string === "registered") {
        return UserRole.REGISTERED;
    } else {
        return UserRole.GUEST;
    }  
}

/**
 * Converts a User Role to a translation Key for the specific Role
 * @param {UserRole} role 
 */
function roleToLangKey(role) {
    switch (role) {
        case UserRole.ADMINISTRATOR:
            return "global.roles.administrator"
        case UserRole.MODERATOR:
            return "global.roles.moderator"
        case UserRole.EMPLOYEE:
            return "global.roles.employee"
        case UserRole.REGISTERED:
            return "global.roles.registered"
    
        default:
            return "global.roles.guest"
    }
}

export {UserRole, stringToRole, roleToLangKey}
//export default UserRole