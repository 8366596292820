/**
 * Enum containing tha avilable Item Types
 */
const ItemType = {
    SINGLE_CHOICE: 0,
    MULTIPLE_CHOICE: 1,
    FILL_IN_THE_BLANK: 2,
    CORRECT_ORDER: 3,
}

/**
 * Array containing all Item Types
 */
const alltypes = ["SINGLE_CHOICE", "MULTIPLE_CHOICE", "FILL_IN_THE_BLANK", "CORRECT_ORDER"];


function typeToString(type) {
    return ItemType[type];
}

/**
 * Converts the String to an Item Type
 * @param {String} string 
 */
function stringToType(string) {
    if (string === "SINGLE_CHOICE") {
        return ItemType.SINGLE_CHOICE;
    } else if (string === "MULTIPLE_CHOICE") {
        return ItemType.MULTIPLE_CHOICE;
    } else if (string === "FILL_IN_THE_BLANK") {
        return ItemType.FILL_IN_THE_BLANK;
    } else if (string === "CORRECT_ORDER") {
        return ItemType.CORRECT_ORDER;
    }
    
    else {
        return undefined;
    }  
}

/**
 * Converts an Item Type to a translation Key for the specific Item Type
 * @param {ItemType} type 
 */
function typeToLangKey(type) {
    switch (type) {
        case ItemType.SINGLE_CHOICE:
            return "global.itemTypes.singleChoice"
        case ItemType.MULTIPLE_CHOICE:
            return "global.itemTypes.multipleChoice"
        case ItemType.FILL_IN_THE_BLANK:
            return "global.itemTypes.fillInTheBlank"
        case ItemType.CORRECT_ORDER:
            return "global.itemTypes.correctOrder"

        default:
            return "global.itemTypes.new"
    }
}

/**
 * Converts an Item Type to an Icon name
 * @param {ItemType} type 
 */
function typeToIcon(type) {
    switch (type) {
        case ItemType.SINGLE_CHOICE:
            return "radio_button_checked"
        case ItemType.MULTIPLE_CHOICE:
            return "check_box"
        case ItemType.FILL_IN_THE_BLANK:
            return "border_color"
        case ItemType.CORRECT_ORDER:
            return "format_list_numbered"
    
        default:
            return "remove"
    }
}

export {ItemType, alltypes, typeToString, stringToType, typeToLangKey, typeToIcon}
