<!--
  This View contains all Information about a Challenge and displays it to the User
  The User can start a Challenge from here
-->

<template>
  <div>
    <md-dialog-confirm
      :md-title="$t('global.error.title')"
      :md-active.sync="showErrorDialog"
      :md-content="lastError"
      :md-confirm-text="$t('global.error.confirm')"
      :md-cancel-text="$t('global.error.otherConfirm')"
      @md-cancel="back"
      @md-confirm="back"/>

    <md-snackbar
      style="z-index: 999999"
      md-position="center"
      :md-duration="5000"
      :md-active.sync="showToast"
      md-persistent
    >
      <span>{{ toastmessage }}</span>
      <md-button class="md-primary" @click="showToast = false">{{
        toastbtn
      }}</md-button>
    </md-snackbar>

    <md-toolbar class="md-primary" md-elevation="1">
      <h3 id="ch-title" class="md-title" style="flex: 1">
        {{ this.challenge.name }}
      </h3>
      <!-- Buttons for Challenges -->
      <!-- :disabled="!challenge.trainingModeAllowed || itemAmount == 0 || !challenge.enabled" -->
      <md-button
        :disabled="!canstart.canStartTraining && !canstart.canResumeTraining"
        class="md-icon-button md-raised"
        :md-ripple="false"
        id="play-btn"
        @click="startTraining"
        ><md-icon>fitness_center</md-icon>
        <md-tooltip md-direction="bottom">{{ $t('details.trainingTooltip') }}</md-tooltip>
      </md-button>

      <!-- :disabled="itemAmount == 0 || !challenge.enabled" -->
      <md-button
        :disabled="!canstart.canStartNormal && !canstart.canResumeNormal"
        class="md-icon-button md-raised"
        :md-ripple="false"
        id="play-btn"
        @click="startChallenge"
        ><md-icon>play_arrow</md-icon>
        <md-tooltip md-direction="bottom">{{ $t('details.normalTooltip') }}</md-tooltip>
      </md-button>

      <md-button
        v-if="hasRole('mod') || username() == this.challenge.creatorName"
        class="md-icon-button md-raised"
        id="edit-btn"
        :md-ripple="false"
        @click="editChallenge"
        ><md-icon>edit</md-icon>
        <md-tooltip md-direction="bottom">{{ $t('details.editTooltip') }}</md-tooltip>
      </md-button>

      <md-button
        v-if="hasRole('mod')"
        class="md-icon-button md-raised"
        id="delete-btn"
        :md-ripple="false"
        @click="showDialogDeleteChallenge = true"
        ><md-icon>delete</md-icon>
        <md-tooltip md-direction="bottom">{{ $t('details.deleteTooltip') }}</md-tooltip>
      </md-button>

      <!-- Delete Confirmation Dialog -->
      <md-dialog :md-active.sync="showDialogDeleteChallenge">
        <md-dialog-title> {{ $t('global.deleteDialog.title', {type: "Challenge"}) }}</md-dialog-title>

        <md-dialog-actions>
          <md-button
            class="md-primary md-accent"
            @click="showDialogDeleteChallenge = false; deleteChallenge();">
            {{ $t('global.deleteDialog.confirm') }}
          </md-button>
          <md-button class="md-primary" @click="showDialogDeleteChallenge = false"> {{ $t('global.deleteDialog.cancel') }}</md-button>
        </md-dialog-actions>
      </md-dialog>

      <!-- Result Dialog -->
      <md-dialog :md-active.sync="showResultDialog">
        <md-toolbar
          id="filter-toolbar"
          class="md-primary"
          md-elevation="1"
        >
          <h3 class="md-title" style="flex: 1">
            {{ $t('resultDialog.title') }} 
            <span style="color: rgba(0, 0, 0, 0.3); margin-left: 32px">{{showResult != undefined? showResult.finishTime : "" | formatDate}}</span>
            <span style="color: rgba(0, 0, 0, 0.3); margin-left: 32px">{{showResult != undefined? showResult.timeTook : "" | fancytimer}}</span>
          </h3>
          <md-button
            class="md-icon-button md-raised"
            id="close-btn"
            :md-ripple="false"
            @click="showResultDialog = false"
            ><md-icon>close</md-icon>
          </md-button>
        </md-toolbar>

        <ResultView :result="showResult != undefined? showResult.id : 0" />
      </md-dialog>

      <md-button
        class="md-icon-button md-raised"
        id="close-btn"
        :md-ripple="false"
        @click="back"
        ><md-icon>close</md-icon>
        <md-tooltip md-direction="bottom">{{ $t('global.cancel') }}</md-tooltip>
      </md-button>
    </md-toolbar>
    <!--end-->

    <div id="detailscontent">
      <div id="details-chname">
        <div style="float: left; margin-right: 64px">
          {{ challenge.name }}
        </div>
        <div id="details-chcat" style="color: rgba(0, 0, 0, 0.3)">
          {{ challenge.category }}
        </div>
      </div>
      <div id="details-chtags">
        <md-chip v-for="tag in challenge.tags" :key="tag.id">{{
          tag.name
        }}</md-chip>
      </div>

      <div id="details-chdesc">
        {{ challenge.description }}
      </div>

      <div id="details-results" v-if="results != null">
        <md-card style="width: 100%">
          <md-card-header>
            <div class="md-title">{{ $t('details.cards.infoTitle') }}</div>
          </md-card-header>

          <md-card-content>
            <md-list>
              <md-list-item v-if="!challenge.enabled">
                <md-icon v-if="challenge.enabled" class="md-primary"
                  >check_circle</md-icon
                >
                <md-icon v-if="!challenge.enabled" class=""
                  >radio_button_unchecked</md-icon
                >

                <span class="md-list-item-text">
                  {{ $t('details.cards.notEnabled') }}
                </span>
              </md-list-item>

              <md-list-item>
                <md-icon v-if="challenge.trainingModeAllowed" class="md-primary"
                  >check_circle</md-icon
                >
                <md-icon v-if="!challenge.trainingModeAllowed" class=""
                  >radio_button_unchecked</md-icon
                >

                <span class="md-list-item-text">
                  {{ $t(challenge.trainingModeAllowed? 'details.cards.trainingMode' : 'details.cards.noTrainingMode') }}
                </span>
              </md-list-item>

              <md-list-item>
                <md-icon v-if="challenge.pausingAllowed" class="md-primary"
                  >check_circle</md-icon
                >
                <md-icon v-if="!challenge.pausingAllowed" class=""
                  >radio_button_unchecked</md-icon
                >

                <span class="md-list-item-text">
                  {{ $t(challenge.pausingAllowed? 'details.cards.pausing' : 'details.cards.noPausing') }}
                </span>
              </md-list-item>

              <md-list-item>
                <md-icon>list</md-icon>

                <span class="md-list-item-text">{{ $t('details.cards.noOfItems', {amount: itemAmount}) }}
                  </span>
              </md-list-item>

              <md-list-item>
                <md-icon v-if="challenge.timeLimit != 0" class="md-primary">timer</md-icon>
                <md-icon v-if="challenge.timeLimit == 0" class="">timer_off</md-icon>

                <span v-if="challenge.timeLimit == 0" class="md-list-item-text">{{ $t('details.cards.timeLimitInf') }}</span>
                <span v-if="challenge.timeLimit > 0" class="md-list-item-text">{{ $t('details.cards.timeLimit') }} {{ challenge.timeLimit | fancytimer }}</span>
              </md-list-item>

              <md-list-item>
                <md-icon v-if="challenge.repeatTimeout > -1">repeat</md-icon>
                <md-icon v-if="challenge.repeatTimeout == -1">repeat_one</md-icon>

                <span
                  v-if="challenge.repeatTimeout > -1"
                  class="md-list-item-text">
                  {{ $t('details.cards.repeatTimeout') }}
                  {{ challenge.repeatTimeout | fancytimer }}
                </span>
                <span
                  v-if="challenge.repeatTimeout == -1"
                  class="md-list-item-text">
                  {{ $t('details.cards.repeatTimeoutOnce') }}
                </span>
              </md-list-item>
            </md-list>
          </md-card-content>
        </md-card>

        <md-card
          v-if="results.length > 0 && challenge.repeatTimeout == -1"
          style="width: 100%"
        >
          <md-card-header>
            <div class="md-title">{{ $t('details.cards.timeoutTitle') }}</div>
          </md-card-header>

          <md-card-content>
            <md-list class="md-single-line">
              <md-list-item>
                <md-icon class="notpassicon">hourglass_empty</md-icon>

                <div class="md-list-item-text">
                  <span>{{ $t('details.cards.timeoutOnceDescription') }}</span>
                </div>
              </md-list-item>
            </md-list>
          </md-card-content>
        </md-card>

        <md-card v-if="canstart.timeout > 0" style="width: 100%">
          <md-card-header>
            <div class="md-title">{{ $t('details.cards.timeoutTitle') }}</div>
          </md-card-header>

          <md-card-content>
            <md-list class="md-double-line">
              <md-list-item>
                <md-icon class="md-primary">hourglass_empty</md-icon>

                <div class="md-list-item-text">
                  <span>{{ canstart.timeout | fancytimer }}</span>
                  <span>{{ $t('details.cards.timeoutDescription') }}</span>
                </div>
              </md-list-item>
            </md-list>
          </md-card-content>
        </md-card>

        <md-card v-if="lastState.id != -1" style="width: 100%">
          <md-card-header>
            <div class="md-title">{{ $t('details.cards.pausedTitle') }}</div>
          </md-card-header>

          <md-card-content>
            <md-list class="md-triple-line">
              <md-list-item>
                <md-icon class="md-primary">pause_circle</md-icon>

                <div class="md-list-item-text">
                  <span>
                    {{ $t('details.cards.pausedAnsweredAmount', {answered: Object.keys(lastState.submittedOptions).length, max: itemAmount}) }}
                  </span>
                  <span>{{ $t('details.cards.pausedStarted') }} {{ lastState.startTime | formatDate }}</span>
                  <span
                    v-if="
                      (challenge.timeLimit != 0 && lastState.timeRemaining > 0) || lastState.trainingMode
                    "
                    >{{ $t('details.cards.pausedRemaining') }}
                    <span v-if="lastState.trainingMode">(</span> <span :class="(lastState.timeRemaining < 0? 'timeup' : '')">{{lastState.timeRemaining | fancytimer}}</span> <span v-if="lastState.trainingMode">)</span>
                  </span>
                  <span
                    v-if="
                      challenge.timeLimit != 0 && lastState.timeRemaining <= 0 && !lastState.trainingMode
                    "
                    >{{ $t('details.cards.pausedTimeUp') }}</span
                  >
                </div>

                <md-icon v-if="lastState.trainingMode" class="md-primary"
                  >fitness_center</md-icon
                >
                <md-icon v-if="!lastState.trainingMode" class="md-primary"
                  >play_arrow</md-icon
                >
                <!-- assignment_late -->
              </md-list-item>
            </md-list>
          </md-card-content>
        </md-card>

        <md-card v-if="loggedIn()" style="width: 100%">
          <md-card-header>
            <div class="md-title">{{ $t('details.cards.resultsTitle') }}</div>
          </md-card-header>

          <md-card-content>
            <md-empty-state
              v-if="results != null && results.length == 0"
              md-icon="list_alt"
              :md-label="$t('details.cards.resultsEmpty.label')"
              :md-description="$t('details.cards.resultsEmpty.description')"
            >
            </md-empty-state>

            <md-list class="md-double-line">
              <md-list-item v-for="result in results" v-bind:key="result.id">
                <md-icon v-if="result.passed && !result.trainingMode" class="md-primary">
                  check_circle
                </md-icon>
                <md-icon v-if="!result.passed && !result.trainingMode" class="notpassicon">
                  cancel
                </md-icon>
                <md-icon v-if="result.trainingMode">
                  fitness_center
                </md-icon>

                <div class="md-list-item-text">
                  <span>
                    {{ $t('details.cards.resultCorrectAmount', {correct: result.amountCorrect, max: result.amountItems}) }}
                  </span>
                  <span>{{ result.finishTime | formatDate }}</span>
                  <span>{{ $t('details.cards.resultTimeTook') }} {{ result.timeTook | fancytimer }}</span>
                </div>

                <md-button v-if="result.trainingMode" class="md-icon-button md-list-action" @click="openResultDialog(result)">
                  <md-icon>visibility</md-icon>
                </md-button>
              </md-list-item>
            </md-list>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
// import configuration with API url; @ refers to the src directory
import config from "@/config";
// import library for HTTP requests
import axios from "axios";

import ApiService from "../services/api.service";
import AuthService from "../services/auth.service";

import ResultView from "../components/ResultView.vue";

export default {
  name: "DetailsView",
  props: ["id"],
  components: {ResultView},
  data() {
    return {
      challenge: {
        /* name: "",
        description: "",
        tags: [],
        category: null,
        passLimit: 0, */
      },
      lastError: "",
      showErrorDialog: false,
      toastmessage: "",
      toastbtn: "",
      showToast: false,
      itemAmount: 0,
      results: [],
      showResult: undefined,
      showResultDialog: false,
      canstart: {
        canResumeNormal: false,
        canStartNormal: false,
        canStartTraining: false,
        canResumeTraining: false,
        timeout: 0,
      },
      lastState: {
        id: -1,
        trainingMode: false,
        startTime: "",
        submittedOptions: {},
        timeRemaining: -1,
      },
      timer: undefined,
      showDialogDeleteChallenge: false,
    };
  },
  methods: {
    /**
     * Starts the Timeout Timer, if there is a Timeout for this User
     */
    startTimerTimeout: function () {
      if (this.canstart.timeout <= 0) return;
      this.timer = setInterval(() => {
        this.canstart.timeout -= 1;
        if (this.canstart.timeout <= 0) {
          this.stopTimerTimeout();
          this.getStartEligibility();
        }
      }, 1000);
    },

    /**
     * Stops the Timeout Timer
     */
    stopTimerTimeout: function () {
      if (this.timer == undefined) return;
      clearInterval(this.timer);
      this.timer == undefined;
    },

    /**
     * Starts the Time Remaining Timer if a User has a paused Session with a Time Limit
     * It is used to show the correct amount of time left to finish the started session
     */
    startTimerRemaining: function () {
      if (this.lastState.timeRemaining <= 0 && !this.lastState.trainingMode) return;
      this.timer = setInterval(() => {
        this.lastState.timeRemaining -= 1;
        if (this.lastState.timeRemaining <= 0 && !this.lastState.trainingMode) {
          this.stopTimerRemaining();
          this.getLastState();
        }
      }, 1000);
    },

    /**
     * Stops the Time Remaining Timer
     */
    stopTimerRemaining: function () {
      if (this.timer == undefined) return;
      clearInterval(this.timer);
      this.timer == undefined;
    },

    /**
     * Returns if the User is logged in
     */
    loggedIn: function () {
      return AuthService.isLoggedIn();
    },

    /**
     * Returns whether the logged in User has a role
     */
    hasRole: function (role) {
      return ApiService.getUserRoles().indexOf(role) != -1;
    },

    /**
     * Returns the Username of the logged in User
     */
    username: function () {
      return AuthService.getUsername();
    },

    /**
     * Goes back one page
     */
    back: function () {
      this.$router.go(-1);
    },

    /**
     * Opens the Challenge Editor for this Challenge
     */
    editChallenge: function () {
      this.$router.push({
        path: "/editor/" + this.challenge.id,
      });
    },

    /**
     * Sends a Request to the API to delete this Challenge and if succeeded goes back one Page
     */
    deleteChallenge: function () {
      axios
        .delete(`${config.apiBaseUrl}/challenges/${this.challenge.id}`)
        .then(() => {
          this.back();
          this.showToastMessage(
            `Successfully deleted Challenge with ID ${this.challenge.id}!`,
            "success"
          );
        });
    },

    /**
     * Starts the Challenge in Normal mode
     */
    startChallenge: function () {
      this.$router.push({
        path: "/challenge/" + this.challenge.id + "/take",
        query: {
          m: "normal",
          nouser: !this.loggedIn()
        },
      });
    },

    /**
     * Starts the Challenge in Training Mode
     */
    startTraining: function () {
      this.$router.push({
        path: "/challenge/" + this.challenge.id + "/take",
        query: {
          m: "train",
          nouser: !this.loggedIn()
        },
      });
    },

    /**
     * Retrieves Challenge Information from the API
     */
    getChallenge: function () {
      axios
        .get(`${config.apiBaseUrl}/challenges/${this.id}`)
        .then((response) => {
          console.log(response.data);
          this.challenge = response.data.challenge;
          this.challenge.category = response.data.challenge.category.name;
          this.itemAmount = response.data.itemAmount;

          if (this.loggedIn()) this.getResults();
          this.getStartEligibility();
          if (this.loggedIn()) this.getLastState();
          /*var tags = [];
        response.data.tags.forEach(tag => {
          tags.push(tag.name);
        });
        this.challenge.tags = tags;*/
        })
        .catch((err) => {
          if (err.response.status == 403) {
            this.lastError = "You don't have access to this Challenge";
          } else if (err.response.status == 404) {
            this.lastError = "This Challenges does not exist";
          } else {
            this.lastError = "Unknown Error";
          }

          this.showErrorDialog = true;
        });
    },

    /**
     * Retrieves a List of earlier Results for this Challenge from the API.
     * Limited to 5 Results
     */
    getResults: function () {
      axios
        .get(`${config.apiBaseUrl}/results/challenge/${this.id}?limit=5`)
        .then((response) => {
          console.log(response.data);
          this.results = response.data;
        })
        .catch((err) => {
          console.log(err);
          this.results = [];
        });
    },

    /**
     * Opens the Result Dialog with Details about a Result
     */
    openResultDialog: function(result) {
      this.showResult = result;
      this.showResultDialog = true;
    },

    /**
     * Retrieves Information from the API regarding the eligibility of the User to start or resume this Challenge
     */
    getStartEligibility: function () {
      axios
        .get(`${config.apiBaseUrl}/challenges/${this.id}/canstart`)
        .then((response) => {
          console.log(response.data);
          this.canstart = response.data;
          this.startTimerTimeout();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * Retrieves a running session form the API and if there is one starts the Time Remaining Timer
     */
    getLastState: function () {
      axios
        .get(`${config.apiBaseUrl}/challenges/${this.id}/state`)
        .then((response) => {
          console.log(response.data);
          if (response.data == "") {
            this.lastState.id = -1;
          } else {
            this.lastState = response.data;
          }

          this.startTimerRemaining();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * Shows a Snackbar at the Bottom of the Screen to provide the USer with Information
     */
    showToastMessage: function (msg, btn) {
      this.toastmessage = msg;
      this.toastbtn = btn;
      this.showToast = true;
    },

    /**
     * Listener for Authentication Changes
     */
    authCallback: function () {
      this.$forceUpdate();
    },
  },
  mounted: function () {
    this.getChallenge();
  },
  created: function () {
    AuthService.registerCallback(this.authCallback);
  },

  destroyed: function () {
    AuthService.unregisterCallback(this.authCallback);
    this.stopTimerTimeout();
    this.stopTimerRemaining();
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding: 0;
}

#close-btn i {
}

#edit-btn i {
}

.timeup {
  color: #f44336;
}

#delete-btn i {
  color: $warn;
}

.notpassicon {
  color: $warn !important;
}

#play-btn i {
  color: $primary;
}

#ch-title {
  font-weight: 300;
}

#detailscontent {
  display: grid;
  grid-template-columns: auto 366px;
  grid-template-rows: auto auto auto;

  padding: 10px;
  width: calc(100% - 20px);
}

#details-chname {
  grid-column: 1 / 2;
  grid-row: 1 / 2;

  font-size: 35px;
  line-height: 35px;

  margin-bottom: 16px;
}

#details-chtags {
  grid-column: 1 / 2;
  grid-row: 2 / 3;

  margin-bottom: 32px;
}

#details-chdesc {
  grid-column: 1 / 2;
  grid-row: 3 / 4;

  font-size: 20px;
  line-height: 20px;
}

#details-results {
  .md-card {
    min-width: 150px;
    max-width: 350px;
  }

  grid-column: 2 / 3;
  grid-row: 1 / 5;
}

@media only screen and (max-width: 680px) {
  #details-chname {
    /* width: 90%; */

    font-size: 25px;
    line-height: 25px;
  }

  #details-chtags {
   /*  width: 90%; */
  }

  #details-chdesc {
   /*  width: 90%; */

    font-size: 20px;
    line-height: 20px;
  }
  
  #details-results {
/*     .md-card {
      max-width: calc(100% - 16px);
    } */
    /* width: 90%; */
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }
}

</style>
