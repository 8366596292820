import AuthService from './auth.service'
import config from "@/config";
import axios from 'axios'
//import UserRole from '../models/userrole';

/**
 * API Service containing functions to login and get the User Information
 */
const ApiService = {
    /**
     * Authenticates the User with the API and retrieves a JWT to authenticate other Requests
     * Informs the AuthService on a successful Login
     * @param {username, password} loginDetails
     */
    login: async function({username, password}) {
        try {
            var response = await axios.post(`${config.apiBaseUrl}/auth/login`, {username: username, password: password});
            
            console.log(response);

            AuthService.setUsername(response.data.username);
            AuthService.setToken(response.data.token);

            this.loadToken();

            return true;
        } catch (error) {
            console.log(error.response);
            if (error.response != undefined && error.response.status == 403) {
                return "Wrong Credentials";
            } else if (error.response != undefined) {
                return "Unknown Error: " + error.response.data.message;
            } else {
                return "Unknown Error";
            }
        }
    },

    /**
     * Sets the available Token as default for other Requests
     */
    loadToken: function() {
        axios.defaults.headers.common["Authorization"] = `Bearer ${AuthService.getToken()}`

        axios.interceptors.response.use((response) => {
            if(response.status === 401) {
                 this.logout();
                 console.log("Login expired");
            }
            return response;
        }, (error) => {
            if (error.response && error.response.data) {
                //this.logout();
                return Promise.reject(error.response.data);
            }
            return Promise.reject(error.message);
        });

        this.getUserInfo();
    },

    /**
     * Retrieves User Information from the API including roles
     */
    getUserInfo: function() {
        axios.get(`${config.apiBaseUrl}/me`).then((response) => {
            console.log(response);
            this.roles = response.data.roles;
            console.log(this.roles);
            AuthService.updateCB();
        }).catch((err) => {
            console.log(err.response);
            this.logout();
        });
    },

    /**
     * Returns the Roles of the logged in User
     */
    getUserRoles: function() {
        return this.roles == undefined? [] : this.roles;
    },

    /**
     * Deletes Login Information from the Client
     */
    logout() {
        this.roles = [];
        axios.defaults.headers.common = {};

        AuthService.delUsername();
        AuthService.delToken();
    },
}

export default ApiService