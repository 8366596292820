<!--
  Dialog with Items to select one for Import
-->

<template>
  <md-dialog id="itemedit-dialog" :md-active.sync="showDialog">
    <md-toolbar
      id="filter-toolbar"
      class="md-primary"
      md-elevation="1"
    >

      <h3 id="ch-title" class="md-title" style="flex: 1"><vue-simple-markdown style="font-size: inherit;" :source="$t('editItemDialog.title', {name: item.question})"></vue-simple-markdown></h3>
        <md-button class="md-icon-button md-raised" @click="cancelDialog">
          <md-icon>close</md-icon>
        </md-button>
        <md-button class="md-icon-button md-raised" @click="saveDialog">
          <md-icon>save</md-icon>
        </md-button>
      
    </md-toolbar>

    <div id="iteminfo-wrapper">
      <div id="iteminfo-inputs" class="iteminfo-block">
        <form novalidate class="md-layout">
          <md-field>
            <label for="itemType">{{ $t('editItemDialog.typeLabel') }}</label>
            <md-select v-model="item.type" name="itemType" id="itemType">
              <md-option :value="type" v-for="type in alltypes" :key="type">{{$t(typeToLangKey(stringToType(type)))}}</md-option>
            </md-select>
          </md-field>

          <md-field>
            <label for="question">{{ $t('editItemDialog.questionLabel') }}</label>
            <md-input type="text" name="question" id="question" v-model="item.question" :disabled="sending" @input="questionChanges()" />
            <span class="md-error" v-if="!$v.form.question.required">{{ $t('editItemDialog.questionRequired') }}</span>
          </md-field>

          <md-field>
            <label for="itemImg">{{ $t('editItemDialog.imageUrlLabel') }}</label>
            <md-input type="text" name="itemImg" id="itemImg" data-lpignore="true" v-model="item.questionImageUrl" :disabled="sending || itemImageFile != ''" />
          </md-field>

          <md-field md-clearable>
            <label>{{ $t('editItemDialog.imageFileLabel') }}</label>
            <md-file v-model="itemImageFile" accept="image/*" @md-change="itemImageSelected"/>
          </md-field>

          <md-chips id="itemTags" v-model="item.tags" :md-placeholder="$t('editItemDialog.tagPlaceholder')"></md-chips>
        </form>
      </div>

      <div v-responsive="['hidden-all', 'lg', 'xl']" class="iteminfo-divider vert" style="grid-column: 4 / 5; grid-row: 1 / 4;"></div>
      <div v-responsive="['hidden-all', 'xs', 'sm', 'md']" class="iteminfo-divider hori" style="grid-column: 1 / 6; grid-row: 2 / 3;"></div>

      <div id="iteminfo-info" class="iteminfo-block">
        <div class="md-title">{{ $t('editItemDialog.helpfulInformationLabel') }}</div>

        <div>
          <md-field>
            <label for="helpInfo">{{ $t('editItemDialog.addInfoLabel') }}</label>
            <md-input ref="infoinput" type="text" name="helpInfo" id="helpInfo" v-model="infoToAdd" :disabled="sending" @keydown.enter="addNewInfo()" />
            <md-button class="md-icon-button" :disabled="infoToAdd == ''" @click="addNewInfo()">
              <md-icon>add</md-icon>
            </md-button>
          </md-field>

          <md-list>
            <md-list-item v-for="(inf, index) in item.moreInformation" :key="index + inf">
              <span class="md-list-item-text">{{inf}}</span>
              <md-button class="md-icon-button" @click="deleteInfo(index)">
                <md-icon>delete</md-icon>
              </md-button>
            </md-list-item>
          </md-list>
        </div>                
      </div>

      <div v-responsive="['hidden-all', 'lg', 'xl']" class="iteminfo-divider hori" style="grid-column: 1 / 5; grid-row: 2 / 3;"></div>
      <div v-responsive="['hidden-all', 'xs', 'sm', 'md']" class="iteminfo-divider hori" style="grid-column: 1 / 6; grid-row: 4 / 5;"></div>

      <div id="iteminfo-options" class="iteminfo-block">
        <div v-if="item.type == 'SINGLE_CHOICE' || item.type == 'MULTIPLE_CHOICE'">
          <div class="md-title">{{ $t('editItemDialog.optionsTitle') }}</div>

          <div>
            <md-field>
              <label for="answer">{{ $t('editItemDialog.newOptionLabel') }}</label>
              <md-input ref="optioninput" type="text" name="answer" id="answer" v-model="optionToAdd" :disabled="sending" @keydown.enter="addNewOption()"/>
              <md-button class="md-icon-button" :disabled="optionToAdd == ''" @click="addNewOption()">
                <md-icon>add</md-icon>
              </md-button>
            </md-field>

            <md-list style="height: 100%" class="scrollable">
              <md-list-item v-for="(opt, index) in item.options" :key="opt.id" @click.prevent="">
                <md-checkbox v-if="item.type == 'MULTIPLE_CHOICE'" v-model="opt.correctOption"></md-checkbox>
                <md-radio v-if="item.type == 'SINGLE_CHOICE'" v-model="opt.correctOption" :value="true" @change="clearCorrectOptions(opt)"></md-radio>
                <span v-if="opt.type == 'Text'" class="md-list-item-text"><vue-simple-markdown style="font-size: inherit;" :source="opt.data"></vue-simple-markdown></span>
                <img v-if="opt.type == 'Image'" :src="opt.data" style="max-height: 100px;max-width: 33%;"/>
                <md-button class="md-icon-button" @click="selectedOption = index">
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button class="md-icon-button" @click="deleteOption(index)">
                  <md-icon>delete</md-icon>
                </md-button>
              </md-list-item>
            </md-list>
          </div>                
        </div>

        <div v-if="item.type == 'FILL_IN_THE_BLANK'">
          <div class="md-title">{{ $t('editItemDialog.optionsTitleFillBlank') }}</div>

          <div>
            <div class="fillinblank_part" v-for="(opt, index) in item.question.split(/_+/g)" :key="opt.id">
              {{opt}}
              <md-field style="display: inline-block; width: auto;" v-if="index < item.question.split(/_+/g).length - 1">
                <label for="answer">{{ $t('editItemDialog.correctOptionInput') }}</label>
                <md-input  ref="optioninput" type="text" name="answer" id="answer" v-model="item.options[index].data" :disabled="sending"/>
              </md-field>
            </div>
          </div>                
        </div>

        <div v-if="item.type == 'CORRECT_ORDER'">
          <div class="md-title">{{ $t('editItemDialog.optionsTitle') }}</div>

          <div>
            <md-field>
              <label for="answer">{{ $t('editItemDialog.newOptionLabel') }}</label>
              <md-input ref="optioninput" type="text" name="answer" id="answer" v-model="optionToAdd" :disabled="sending" @keydown.enter="addNewOption()"/>
              <md-button class="md-icon-button" :disabled="optionToAdd == ''" @click="addNewOption()">
                <md-icon>add</md-icon>
              </md-button>
            </md-field>

            <md-list style="height: 100%" class="scrollable noselect">
              <draggable class="itemselector-wrapper" v-model="item.options" group="people" draggable=".item" handle=".handle" @start.self="drag=true" @end.self="drag=false">

                <md-list-item v-for="(opt, index) in item.options" class="item" :key="opt.id">
                  <md-icon class="handle">menu</md-icon>
                  <span v-if="opt.type == 'Text'" class="md-list-item-text"><vue-simple-markdown style="font-size: inherit;" :source="opt.data"></vue-simple-markdown></span>
                  <img v-if="opt.type == 'Image'" :src="opt.data" style="max-height: 100px;max-width: 33%;"/>
                  <md-button class="md-icon-button" @click="selectedOption = index">
                    <md-icon>edit</md-icon>
                  </md-button>
                  <md-button class="md-icon-button" @click="deleteOption(index)">
                    <md-icon>delete</md-icon>
                  </md-button>
                </md-list-item>
              </draggable>
            </md-list>
          </div>   
        </div>
      </div>

      <div v-responsive="['hidden-all', 'lg', 'xl']" class="iteminfo-divider vert" style="grid-column: 2 / 3; grid-row: 2 / 4;"></div>
      <div v-responsive="['hidden-all', 'xs', 'sm', 'md']" class="iteminfo-divider hori" style="grid-column: 1 / 6; grid-row: 6 / 7;"></div>

      <div v-if="item.options != undefined && item != undefined && item.options[selectedOption] != undefined" id="iteminfo-optioninfo" class="iteminfo-block">
        <div class="md-title" style="margin-bottom: 12px;"><vue-simple-markdown style="font-size: inherit;" :source="'Option ' + item.options[selectedOption].data | maxlength(30)"></vue-simple-markdown></div>

        <form>
          <md-field>
            <label for="optionType">{{ $t('editItemDialog.optionTypeLabel') }}</label>
            <md-select v-model="item.options[selectedOption].type" name="optionType" id="optionType" :disabled="sending">
              <md-option :value="optt[0]" v-for="optt in optionTypes" :key="optt[0]">{{optt[1]}}</md-option>
            </md-select>
          </md-field>

          <md-field>
            <label for="optionData">{{ $t('editItemDialog.optionDataLabel') }}</label>
            <md-input
              type="text"
              name="optionData"
              id="optionData"
              v-model="item.options[selectedOption].data"
              :disabled="sending"/>
            <span class="md-helper-text">{{ $t('editItemDialog.optionDataHelper') }}</span>
          </md-field>

          <md-field md-clearable v-if="item.options[selectedOption].type == 'Image'">
            <label>{{ $t('editItemDialog.optionImageFileLabel') }}</label>
            <md-file v-model="item.options[selectedOption].imageFile" accept="image/*" @md-change="optionImageSelected($event, selectedOption)"/>
          </md-field>

          <img v-if="item.options[selectedOption].type == 'Image'" :src="item.options[selectedOption].data" style="max-height: 300px;"/>
        </form>
      </div>
    </div>
  </md-dialog>
</template>

<script>
// import configuration with API url; @ refers to the src directory
import config from "@/config";
// import library for HTTP requests
import axios from "axios";
import draggable from 'vuedraggable'

import {alltypes, stringToType, typeToLangKey, typeToIcon} from "../models/itemtype";


import { validationMixin } from 'vuelidate';
import {
  required
} from 'vuelidate/lib/validators';

export default {
  name: "EditItem",
  mixins: [validationMixin],
  components: {draggable},
  data() {
    return {
      showDialog: false,
      sending: false,
      tags: [],
      tagNames: [],

      item: {},
      itemImageFile: "",
      alltypes: alltypes,
      optionTypes: new Map([
        ["Text", "Text"],
        ["Image", "Image"]
      ]),
      selectedOption: -1,
      optionToAdd: "",
      infoToAdd: "",
    };
  },
  props: [],
  validations: {
    form: {
      type: {
        required,
      },
      question: {
        required
      }
    },
  },
  methods: {
    /**
     * Methods imported from ItemType
     */
    stringToType, 
    typeToLangKey, 
    typeToIcon,
    questionChanges: function() {
      if (this.item.type == "FILL_IN_THE_BLANK") {
        for (let i = 0; i < this.item.question.split(/_+/g).length; i++) {
          if (this.item.options[i] == undefined) {
            this.item.options[i] = {type: "Text"};
          }
        }
      }
    },
    /**
     * Adds a new Option to the selected Item
     */
    addNewOption: function () {
      console.log("Adding new Option");

      this.item.options.push({type: "Text", data: this.optionToAdd, optionOrder: this.item.options.length, correctOption: false});

      this.optionToAdd = "";
      this.selectedOption = this.item.options.length - 1;
      this.$refs.optioninput.$el.focus();
    },

    /**
     * Deletes an Option fromt he selected Item
     */
    deleteOption: function (index) {
      console.log("Removing Option");

      this.item.options.splice(index, 1);
    },
    /**
     * Adds new "More Information" to the Item
     */
    addNewInfo: function () {
      console.log("Adding new Info");

      this.item.moreInformation.push(this.infoToAdd);
      this.infoToAdd = "";

      this.$refs.infoinput.$el.focus();
    },

    /**
     * Deletes a Line of "More Information" from the selected Item
     */
    deleteInfo: function (index) {
      console.log("Removing Info");

      this.item.moreInformation.splice(index, 1);
    },
    
    /**
     * Clears the selection of correct Options form an Item
     */
    clearCorrectOptions: function (opt) {
      this.item.options.forEach(o => {
        if (o != opt) {
          o.correctOption = false;
        }
      });
    },
    /**
     * Retrieves all Items from API
     */
    getItem: function() {
      axios.get(`${config.apiBaseUrl}/items/` + this.itemid).then((response) => {
        console.log(response.data);
        this.item = response.data;
      });
    },
    /**
     * Retrieves all Tags from the API
     * Used to filter for Tags
     */
    getTags: function () {
      axios.get(`${config.apiBaseUrl}/tags`).then((response) => {
        console.log(response.data);
        this.tags = response.data;
        this.tagNames = [];
        this.tags.forEach((tag) => {
          this.tagNames.push(tag.name);
        });
      });
    },
    /**
     * Gets called if an Image for an Item has been selected
     * Converts Image File into Base64
     */
    itemImageSelected: function(event) {
      console.log(event);
      console.log(this.itemImageFile.target.files[0]);
      var reader = new FileReader();
      var item = this.item;
      reader.onload = function (e) {
        item.questionImageUrl = e.target.result;
        console.log(item.questionImageUrl);
      };
      reader.readAsDataURL(this.itemImageFile.target.files[0]);
    },
    /**
     * Gets called if an Image for an Option has been selected
     * Converts Image file into Base64
     */
    optionImageSelected: function(event, optionIndex) {
      var reader = new FileReader();
      var option = this.item.options[optionIndex];
      reader.onload = function (e) {
        option.data = e.target.result;
      };
      reader.readAsDataURL(event[0]);
    },
    /**
     * Closes the Dialog without saving the Item
     */
    cancelDialog: function() {
      this.closeDialog(undefined);
    },
    /**
     * Closes the Dialog
     */
    closeDialog: function(item) {
      this.$emit("doneEditing", item);
      this.item = {};
      this.itemid = undefined;
      this.showDialog = false;
    },
    /**
     * Either returns a new Item or Saves the edited one
     */
    saveDialog: function() {
      if (this.item.id == undefined) return;

      var tmp = Object.assign({}, this.item);
      var tags = [];
      this.item.tags.forEach((tag) => {
        tags.push({ name: tag });
      });
      tmp.tags = tags;

      if (this.item.type == "FILL_IN_THE_BLANK") {
        tmp.options = [];
        for (let i = 0; i < this.item.options.length; i++) {
          if (i < this.item.question.split(/_+/g).length - 1) {
            var tmpopt = Object.assign({}, this.item.options[i]);
            tmpopt.optionOrder = i;
            tmp.options.push(tmpopt);
            console.log(i + ": " + tmpopt.data);
          }
        }
      }

      if (this.item.type == "CORRECT_ORDER") {
        //Renumbering the OrderIndex of the Options
        var index = 0;
        tmp.options.forEach(o => {
          o.optionOrder = index;
          index += 1;
        });
      }

      console.log(tmp);

      if (this.item.id < 0) {
        this.closeDialog(Object.assign({}, tmp));
      } else {
        axios.put(`${config.apiBaseUrl}/items/${this.item.id}`, tmp).then((response) => {
          console.log(response);
          this.closeDialog(undefined);
        });
      }
    },
    /**
     * Opens the Edit Dialog
     */
    openDialog: function(item) {
      this.item = item;
      this.itemImageFile = "";

      var tags = [];
      this.item.tags.forEach(tag => {
        tags.push(tag.name);
      });
      this.item.tags = tags;

      if (this.item.type == "FILL_IN_THE_BLANK" || this.item.type == "CORRECT_ORDER") {
        this.item.options.sort(function(a, b){return a.optionOrder - b.optionOrder});
      }

      console.log(this.item);
      this.showDialog = true;
    }
  },
  mounted: function() {
    //this.getItem();
  }
};
</script>

<style lang="scss" scoped>
#itemedit-dialog {
  padding: 10px;
  min-width: 250px;

  .md-dialog-container {
    width: 80%;
  }
}

#iteminfo-wrapper {
  display: grid;

  grid-template-columns: 33% 1px 34% 1px 33%;
  grid-template-rows: auto 1px auto auto auto auto auto auto;

  overflow-y: auto;
  overflow-x: hidden;
}

.iteminfo-block {
  padding: 10px;
}

.iteminfo-divider {
  background-color: rgba($primary, 0.8);
}

.iteminfo-divider.vert {
  margin-top: 8px;
  margin-bottom: 8px;
}

.iteminfo-divider.hori {
  height: 1px;

  margin-left: 8px;
  margin-right: 8px;
}

#iteminfo-inputs {
  grid-column: 1 / 4;
  grid-row: 1 / 2;
}

#iteminfo-info {
  grid-column: 5 / 6;
  grid-row: 1 / 3;
}

#iteminfo-options {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}

#iteminfo-optioninfo {
  grid-column: 3 / 4;
  grid-row: 3 / 4;
}

.fillinblank_part {
  display: contents;
}

.fillinblank_part:last-child {
  .md-field {
    display: none;
  }
}

.md-card {
  min-width: 250px;
  max-width: 350px;
  margin: 8px;
  display: inline-block;
  vertical-align: top;
}
.scrollable{
/*   max-height: 400px;
  overflow-y: scroll; */
}

@media only screen and (max-width: 750px) {
  #iteminfo-wrapper {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .iteminfo-divider.vert {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 8px;
    margin-right: 8px;
  }

  #iteminfo-inputs {
    grid-column: 1 / 6;
    grid-row: 1 / 2;
  }

  #iteminfo-info {
    grid-column: 1 / 6;
    grid-row: 3 / 4;
  }

  #iteminfo-options {
    grid-column: 1 / 6;
    grid-row: 5 / 6;
  }

  #iteminfo-optioninfo {
    grid-column: 1 / 6;
    grid-row: 7 / 8;
  }
}
</style>
