<!--
  Component that displays details about a Result
-->

<template>
  <div id="resultswrapper">
    <div v-for="item in iItems" :key="item.id">
      <md-card v-if="notInResult.includes(item.id)">
        <md-card-header>
          <div class="md-title"><vue-simple-markdown :source="item.question.replaceAll(/_+/g, '___')"></vue-simple-markdown></div>

        </md-card-header>

        <md-card-content>
          <span class="md-list-item-text">{{ $t('resultDialog.notInResult')}}</span>              
        </md-card-content>
      </md-card>

      <md-card v-if="!notInResult.includes(item.id) && (item.type == 'SINGLE_CHOICE' || item.type == 'MULTIPLE_CHOICE')">
        <md-card-header>
          <div class="md-title"><vue-simple-markdown :source="item.question"></vue-simple-markdown></div>

        </md-card-header>

        <md-card-content>
          <md-list>
            <md-list-item v-for="opt in item.options" :key="opt.id" :class="isSelectedResult(item.id, opt.id) == isCorrectOption(item, opt)? 'correct' : 'wrong'"> 
              <md-checkbox disabled :value="!isSelectedResult(item.id, opt.id)"></md-checkbox>
              <span class="md-list-item-text" v-if="opt.type == 'Text'"><vue-simple-markdown :source="opt.data"></vue-simple-markdown></span>
              <span class="md-list-item-text" v-if="opt.type == 'Image'"><img :src="opt.data" style="max-height: 100px; width: auto;"/></span>
            </md-list-item>
          </md-list>               
        </md-card-content>
      </md-card>

      <md-card v-if="!notInResult.includes(item.id) && item.type == 'FILL_IN_THE_BLANK'">
        <md-card-header>
          <div class="md-title"><vue-simple-markdown :source="item.question != undefined? item.question.replaceAll(/_+/g, '___') : ''"></vue-simple-markdown></div>

        </md-card-header>

        <md-card-content>
          <div style="display: inline;" v-for="(opt, index) in item.question.split(/_+/g)" :key="opt.id">
            <vue-simple-markdown style="display: inline-block; width: fit-content;" :source="opt"></vue-simple-markdown>
            <span v-if="index < item.question.split(/_+/g).length - 1" :class="iResult.submittedOptions[item.id][index] == item.options[index].data? 'correct' : 'wrong'">{{iResult.submittedOptions[item.id][index]}}</span>
          </div>          
        </md-card-content>
      </md-card>

      <md-card v-if="!notInResult.includes(item.id) && item.type == 'CORRECT_ORDER'">
        <md-card-header>
          <div class="md-title"><vue-simple-markdown :source="item.question"></vue-simple-markdown></div>

        </md-card-header>

        <md-card-content>
          <md-list>
            <md-list-item v-for="(opt, index) in iResult.submittedOptions[item.id]" :key="opt" :class="opt == item.options[index].id? 'correct' : 'wrong'"> 
              <span style="margin-right: 8px">{{index + 1}}. </span>
              <span class="md-list-item-text" v-if="item.options.find(o => o.id === opt).type == 'Text'"><vue-simple-markdown :source="item.options.find(o => o.id === opt).data"></vue-simple-markdown></span>
              <span class="md-list-item-text" v-if="item.options.find(o => o.id === opt).type == 'Image'"><img :src="item.options.find(o => o.id === opt).data" style="max-height: 100px; width: auto;"/></span>
            </md-list-item>
          </md-list>               
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
// import configuration with API url; @ refers to the src directory
import config from "@/config";
// import library for HTTP requests
import axios from "axios";

import ApiService from "../services/api.service";

export default {
  name: "ResultView",
  data() {
    return {
      iResult: {},
      iItems: [],
      notInResult: [],

      isSaved: false,

    };
  },
  props: ["items", "result"],
  methods: {
    /**
     * Returns whether the logged in user has a role
     */
    hasRole: function (role) {
      return ApiService.getUserRoles().indexOf(role) != -1;
    },
    /**
     * Returns whether an Option has been selected by the User
     */
    isSelectedResult: function (item, opt) {
/*       if (this.isSaved) {
        return (
          this.iResult.submittedOptions[item] != undefined &&
          this.iResult.submittedOptions[item].includes(opt)
        );      
      } else { */
        return (
          this.iResult.submittedOptions[item] != undefined &&
          this.iResult.submittedOptions[item].includes(opt)
        );      
      /* } */
    },

    /**
     * Returns whether an Option is a correct one
     */
    isCorrectOption: function (item, opt) {
      if (this.isSaved) {
        //return this.iItems.find(i => i.id == item.id).options.find(o => o.id === opt.id).correctOption;
        return opt.correctOption;
      } else {
        return this.iResult.correctAnswers[item.id].includes(opt.id);
      }
    },
    /**
     * Prepares Result and Items for use in the Result View
     */
    prepareResult: function() {
      console.log("prepare");
      console.log(this.iItems);
      this.iItems.map(item => {
        console.log(item);
        if (this.iResult.submittedOptions[item.id] == undefined) {
          this.notInResult.push(item.id);
          return item;
        }

        if (item.type == "FILL_IN_THE_BLANK" || item.type == "CORRECT_ORDER") {
          item.options.sort(function(a, b){return a.optionOrder - b.optionOrder});
        }
        return item;
      });
    }
  },

  /**
   * Either retrieve Result form API or through props
   */
  mounted: function() {
    if (typeof(this.result) === "number") {
      axios.get(`${config.apiBaseUrl}/results/${this.result}`)
        .then((response) => {
          console.log(response.data);
          this.iResult = response.data.result;
          this.iItems = response.data.items;
          this.isSaved = true;
          this.prepareResult();
        });
    } else {
      this.iResult = this.result;
      this.iItems = this.items;
      this.prepareResult();
    }
  }
};
</script>

<style lang="scss" scoped>
#resultswrapper {
  height: fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-wrap: wrap;

  overflow-y: auto;
}

.correct {
  background-color: rgba($primary, 0.2);
  border: 1px solid $primary;
  border-radius: 4px;

  min-height: 50px;
  margin-bottom: 6px;
}

.wrong {
  background-color: rgba($warn, 0.2);
  border: 1px solid $warn;
  border-radius: 4px;

  min-height: 50px;
  margin-bottom: 6px;
}

@media only screen and (max-width: 1000px) {
  #resultswrapper {
    flex-direction: column;
  }

  ::v-deep .md-card {
    width: 100%;
  }
}
</style>
