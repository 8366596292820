import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Privacy from "../views/Privacy.vue";
import Admin from "../views/Admin.vue";
import Browse from "../views/Browse.vue";
import NewChallenge from "../views/NewChallenge.vue";
import Editor from "../views/Editor.vue";
import DetailsView from "../views/DetailsView.vue";
import ChallengeTakeView from "../views/ChallengeTakeView.vue";
import Profile from "../views/Profile.vue";
import EmbeddedView from "../views/EmbeddedView.vue";

Vue.use(VueRouter);

/**
 * Routes for the different Component
 */
const routes = [
  {
    path: "/",
    alias: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin
  },
  {
    path: "/browse",
    name: "Browse",
    component: Browse
  },
  {
    path: "/new-challenge",
    name: "New Challenge",
    component: NewChallenge
  },
  {
    path: "/editor/:id",
    alias: "/editor/",
    name: "Editor",
    component: Editor,
    props: true
  },
  {
    path: "/challenge/:id",
    alias: "/challenge/",
    name: "Detailed View",
    component: DetailsView,
    props: true
  },

  {
    path: "/challenge/:id/take",
    name: "Challenge Taking View",
    component: ChallengeTakeView,
    props: true
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/embed/:id?",
    name: "Embed Challenge",
    component: EmbeddedView,
    props: true
  },
];

const router = new VueRouter({
  routes
});

export default router;
